import { Token } from '../constants/token/token'
import { TokenAmount } from 'constants/token'
import { SUPPORT_PLATFORM } from 'models/platform'
import { ChainId } from 'constants/chain'
import { useEffect, useState } from 'react'
import { getOtherNetworkLibrary } from 'connectors/MultiNetworkConnector'
import { getContract } from 'utils'
import { ERC20_ABI } from 'constants/abis/erc20'
import { useBlockNumber } from 'state/application/hooks'

export function useDestTokenSupply(
  bridge: SUPPORT_PLATFORM | undefined,
  token: Token | undefined,
  destUnderlying: string | undefined,
  chainId: ChainId | undefined
): {
  loading: boolean
  result: TokenAmount | undefined
} {
  const [result, setResult] = useState<TokenAmount | undefined>()
  const blockNumber = useBlockNumber()

  useEffect(() => {
    if (bridge !== SUPPORT_PLATFORM.ANYSWAP || !chainId) {
      setResult(undefined)
      return
    }
    const library = getOtherNetworkLibrary(chainId)
    if (!library || !token) return
    const contract = getContract(token.address, ERC20_ABI, library)
    if (!destUnderlying) {
      contract.totalSupply().then((res: any) => setResult(new TokenAmount(token, res.toString())))
    } else {
      contract.balanceOf(destUnderlying).then((res: any) => setResult(new TokenAmount(token, res.toString())))
    }
  }, [bridge, chainId, token, destUnderlying, blockNumber])

  return {
    loading: false,
    result
  }
}
