import { createReducer } from '@reduxjs/toolkit'
import {
  addRangoTransaction,
  clearRangoAllTransactions,
  SerializableRangoTransactions,
  updateSwapStepData,
  updateTransactionsClose,
  updateTransactionsStatusAndStep
} from './actions'

export interface RangoTransactionState {
  [account: string]: {
    [requestId: string]: SerializableRangoTransactions
  }
}

export const initialState: RangoTransactionState = {}

export default createReducer(initialState, builder =>
  builder
    .addCase(addRangoTransaction, (transactions, { payload: rangoTransactions }) => {
      const txs = transactions[rangoTransactions.account] ?? {}
      txs[rangoTransactions.requestId] = rangoTransactions
      transactions[rangoTransactions.account] = txs
    })
    .addCase(clearRangoAllTransactions, transactions => {
      for (const tran of Object.keys(transactions)) {
        delete transactions[tran]
      }
    })
    .addCase(
      updateSwapStepData,
      (
        transactions,
        { payload: { curStep, requestId, account, lastHash, isApproveTX, stepStatus, stepOutputAmount } }
      ) => {
        const list = transactions[account] ?? {}
        if (Object.values(list).length === 0) return
        if (!transactions[account][requestId]) return
        if (lastHash) transactions[account][requestId].swaps[curStep - 1].lastHash = lastHash
        if (isApproveTX !== undefined) transactions[account][requestId].swaps[curStep - 1].isApproveTX = isApproveTX
        if (stepStatus) transactions[account][requestId].swaps[curStep - 1].stepStatus = stepStatus
        if (stepOutputAmount) transactions[account][requestId].swaps[curStep - 1].stepOutputAmount = stepOutputAmount
      }
    )
    .addCase(
      updateTransactionsStatusAndStep,
      (transactions, { payload: { requestId, account, transactionsStatus, newStep } }) => {
        const list = transactions[account] ?? {}
        if (Object.values(list).length === 0) return
        if (!transactions[account][requestId]) return
        if (transactionsStatus) transactions[account][requestId].transactionsStatus = transactionsStatus
        if (newStep) transactions[account][requestId].currentStep = newStep
      }
    )
    .addCase(updateTransactionsClose, (transactions, { payload: { requestId, account } }) => {
      const list = transactions[account] ?? {}
      if (Object.values(list).length === 0) return
      if (!transactions[account][requestId]) return
      transactions[account][requestId].isClose = true
    })
)
