import { Box } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

interface Props {
  children: React.ReactNode
  status?: 'success' | 'pending'
  style?: React.CSSProperties
}

export default function JourneyPopOver(props: Props) {
  const { children, status, style } = props

  return (
    <Box
      sx={{
        ...style,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: theme => theme.palette.background.default,
        borderRadius: '8px',
        padding: '8px 14px',
        position: 'relative',
        width: '100%',
        '&:before': {
          content: "''",
          borderStyle: 'solid',
          borderWidth: '4px 6px 4px 0',
          borderColor: 'transparent #FFFFFF transparent transparent',
          position: 'absolute',
          left: -6,
          top: 14
        }
      }}
    >
      {children}
      {status === 'success' && (
        <CheckCircleIcon sx={{ color: '#3AC261', position: 'absolute', right: 16, top: 'calc(50% - 12px)' }} />
      )}
    </Box>
  )
}
