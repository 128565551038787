import { useEffect, useState } from 'react'
import { getDashboardInfo } from 'utils/fetch/swap'

export function useDashboardInfo() {
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<
    | {
        totalVolume: string
        supportedTokens: string
      }
    | undefined
  >()

  useEffect(() => {
    ;(async () => {
      try {
        const res = ((await getDashboardInfo()) as unknown) as any
        setData({
          totalVolume: res.transactionNumber || '0',
          supportedTokens: res.tokenNumber || '592'
        })
      } catch (error) {
        console.error('fetch getDashboardInfo', error)
      }
      setLoading(false)
    })()
  }, [])

  return {
    loading,
    data
  }
}
