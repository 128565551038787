import { useCallback, useEffect } from 'react'
import { useSpring } from 'react-spring/web'
import { styled } from '@mui/material'
import { animated } from 'react-spring'
import { useRemovePopup } from 'state/application/hooks'
import TransactionPopup, { TransactionCrossChainPopup } from './TransactionPopup'
import { CloseIcon } from 'theme/components'
import { useChainSwapResult } from 'hooks/useChainSwapResult'

export const Popup = styled('div')(({ theme }) => ({
  display: 'inlineBlock',
  width: '100%',
  background: '#fff',
  position: 'relative',
  borderRadius: '8px',
  padding: '20px',
  boxShadow: '0 0 5px #aaa',
  paddingRight: '35px',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    minWidth: '290px',
    '&:not(:last-of-type)': {
      marginRight: '20px'
    }
  }
}))

const Fader = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: '0px',
  left: '0px',
  width: '100%',
  height: '2px',
  backgroundColor: theme.bgColor.bg5
}))

const AnimatedFader = animated(Fader)

export default function PopupItem({
  removeAfterMs,
  content,
  popKey
}: {
  removeAfterMs: number | null
  content: any
  popKey: string
}) {
  const removePopup = useRemovePopup()
  const removeThisPopup = useCallback(() => removePopup(popKey), [popKey, removePopup])
  useEffect(() => {
    if (removeAfterMs === null) return undefined

    const timeout = setTimeout(() => {
      removeThisPopup()
    }, removeAfterMs)

    return () => {
      clearTimeout(timeout)
    }
  }, [removeAfterMs, removeThisPopup])

  let popupContent
  if ('txn' in content) {
    const {
      txn: { hash, success, summary }
    } = content
    popupContent = <TransactionPopup hash={hash} success={success} summary={summary} />
  }

  const faderStyle = useSpring({
    from: { width: '100%' },
    to: { width: '0%' },
    config: { duration: removeAfterMs ?? undefined }
  })

  return (
    <Popup>
      <CloseIcon onClick={removeThisPopup} />
      {popupContent}
      {removeAfterMs !== null ? <AnimatedFader style={faderStyle} /> : null}
    </Popup>
  )
}

export function PopupCrossChainItem({
  removeAfterMs,
  content,
  popKey
}: {
  removeAfterMs: number | null
  content: any
  popKey: string
}) {
  const removePopup = useRemovePopup()
  const removeThisPopup = useCallback(() => removePopup(popKey), [popKey, removePopup])
  useEffect(() => {
    if (removeAfterMs === null) return undefined

    const timeout = setTimeout(() => {
      removeThisPopup()
    }, removeAfterMs)

    return () => {
      clearTimeout(timeout)
    }
  }, [removeAfterMs, removeThisPopup])

  // bridge: SUPPORT_PLATFORM;
  //   fromChain: ChainId;
  //   toChain: ChainId;
  //   value: string;
  //   fromTXHash: string;
  //   toAddress: string;
  //   pairID?: string | undefined;
  const {
    txn: { hash, summary, crossChain }
  } = content

  const { bridge, toAddress, fromTXHash, toChain, pairID, fromChain, cbridgeTransferId } = crossChain

  const { loading: swapResultLoading, result: swapResult } = useChainSwapResult(
    bridge,
    toAddress,
    fromTXHash,
    toChain,
    pairID,
    fromChain,
    cbridgeTransferId
  )

  const popupContent = (
    <TransactionCrossChainPopup
      swapResultLoading={swapResultLoading}
      hash={hash}
      bridge={bridge}
      swapResult={swapResult}
      summary={summary}
    />
  )

  const faderStyle = useSpring({
    from: { width: '100%' },
    to: { width: '0%' },
    config: { duration: removeAfterMs ?? undefined }
  })

  return (
    <Popup>
      <CloseIcon onClick={removeThisPopup} />
      {popupContent}
      {removeAfterMs !== null ? <AnimatedFader style={faderStyle} /> : null}
    </Popup>
  )
}
