import { useEffect, useState } from 'react'
import { ChainId } from 'constants/chain'
import { useActiveWeb3React } from 'hooks'
import { DEBANK_MAPPING_CHAINID, fetchAccountTotalValue } from 'utils/fetch/debank'

export const SUPPORTED_ACCOUNT_TOKEN_VALUE = Object.keys(DEBANK_MAPPING_CHAINID).map(item => Number(item) as ChainId)

export function useAccountTotalValue(chainId: ChainId) {
  const { account } = useActiveWeb3React()
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState()

  useEffect(() => {
    account && setResult(undefined)
  }, [account])

  useEffect(() => {
    if (!account || !chainId) {
      setResult(undefined)
      return
    }

    ;(async () => {
      setLoading(true)

      try {
        const res: any = await fetchAccountTotalValue(account, chainId)

        setResult(res.usd_value)
      } catch (error) {
        console.error(' fetchAccountTotalValue~ error', error)
      }
      setLoading(false)
    })()
  }, [account, chainId])

  return { loading, result }
}

export function useAccountAllTotalValue() {
  const { account } = useActiveWeb3React()
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState<number | undefined>()
  const [chainValue, setChainValue] = useState<
    | {
        [x: number]: number
      }
    | undefined
  >()

  useEffect(() => {
    account && setTotal(undefined)
  }, [account])

  useEffect(() => {
    if (!account) {
      setTotal(undefined)
      return
    }

    ;(async () => {
      setLoading(true)

      const reqs = SUPPORTED_ACCOUNT_TOKEN_VALUE.map(chainId => fetchAccountTotalValue(account, chainId))

      try {
        const res: any[] = await Promise.all(reqs)

        const chains: { [s in number]: number } = {}
        for (const key in SUPPORTED_ACCOUNT_TOKEN_VALUE) {
          if (Object.prototype.hasOwnProperty.call(SUPPORTED_ACCOUNT_TOKEN_VALUE, key)) {
            const chainId = SUPPORTED_ACCOUNT_TOKEN_VALUE[key]
            chains[chainId] = Number(res[key].usd_value || 0)
          }
        }
        setChainValue(chains)
        const total = Object.values(chains).reduce((pre, cur) => Number(pre) + Number(cur))
        setTotal(total)
      } catch (error) {
        console.error(' fetchAccountAllTotalValue~ error', error)
      }
      setLoading(false)
    })()
  }, [account])

  return {
    loading,
    result: {
      chainValue,
      total
    }
  }
}
