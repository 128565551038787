import { useState, useRef } from 'react'
import { MenuItem, Box, Typography, Select, styled, useTheme } from '@mui/material'
import InputLabel from 'components/Input/InputLabel'
import { useCallback } from 'react'
import { Chain } from 'models/chain'
import Image from 'components/Image'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import SelectedIcon from 'assets/componentsIcon/selected_icon.svg'
// import useBreakpoint from 'hooks/useBreakpoint'
import { RoundButton } from 'components/Button/Button'
import { Blockchain } from '../../../utils/fetch/swap'

interface Props {
  label?: string
  disabled?: boolean
  chainList: Chain[]
  selectedChain: Chain | null
  onChange?: (e: any) => void
  width?: string
  active?: boolean
  placeholder?: string
  addNetworkBtn?: boolean
}

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '102px',
  position: 'relative',
  '& .MuiSelect-icon': {
    color: theme.palette.primary.main,
    top: '65px',
    right: '22px'
  },
  fieldset: {
    display: 'none'
  },
  '& .MuiSelect-select': {
    padding: '16px 24px 20px !important'
  },
  borderRadius: 16
}))

const StyledImgBox = styled(Box)({
  '& img': {
    width: '28px',
    height: '28px'
  },
  '& span': {
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    background: '#F7F7F8'
  }
})

export default function ChainNetworkSelect({
  label,
  disabled,
  chainList,
  onChange,
  selectedChain,
  width,
  addNetworkBtn
}: Props) {
  const theme = useTheme()
  const node = useRef<any>()
  const [open, setOpen] = useState(false)
  // const isDownMd = useBreakpoint('md')

  useOnClickOutside(node, () => {
    setOpen(false)
  })

  const handleChange = useCallback(
    e => {
      const chain = chainList.find(chain => chain.name === e.target.value) ?? null
      onChange && onChange(chain)
    },
    [chainList, onChange]
  )

  const onOpen = useCallback(e => {
    if (e.target.tagName === 'BUTTON') {
      return setOpen(false)
    }

    setOpen(true)
  }, [])

  return (
    <Box sx={{ width }} ref={node}>
      {label && <InputLabel>{label}</InputLabel>}
      <StyledSelect
        sx={{ width: '100%' }}
        open={open}
        onOpen={onOpen}
        displayEmpty
        // defaultValue={selectedChain?.name}
        value={selectedChain?.name ?? ''}
        disabled={disabled}
        MenuProps={{
          PaperProps: {
            sx: {
              marginTop: '5px',
              background: theme.palette.background.default,
              '& .MuiList-root': { height: '360px' }
            }
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        }}
        IconComponent={ExpandMoreIcon}
        onChange={handleChange}
        renderValue={() => {
          return (
            <MenuItem
              sx={{
                padding: 0,
                width: '100%',
                display: 'block',
                '&.Mui-selected': {
                  background: '#FFFFFF'
                },
                '&.Mui-selected:hover': {
                  background: '#FFFFFF'
                },
                '&:hover': {
                  background: 'transparent'
                }
              }}
              value={selectedChain?.name ?? ''}
              key={selectedChain?.name ?? ''}
              selected={!!selectedChain}
            >
              <Box display="flex" justifyContent="space-between" width="100%">
                <StyledImgBox sx={{ mb: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  {selectedChain ? (
                    <Image src={selectedChain.logo as string} alt={`${selectedChain.name} logo`} />
                  ) : (
                    <span></span>
                  )}
                </StyledImgBox>
                {addNetworkBtn && (
                  <RoundButton
                    onClick={() => {}}
                    width="100px"
                    height="28px"
                    backgroundColor="rgba(22, 22, 22, 0.1)"
                    fontSize="12px"
                    fontWeight={400}
                  >
                    Add Network
                  </RoundButton>
                )}
              </Box>

              <Typography
                variant="body1"
                sx={{
                  fontSize: '16px',
                  fontWeight: selectedChain ? 500 : 400,
                  color: selectedChain ? 'inherit' : 'rgba(22, 22, 22, 0.5)'
                }}
              >
                {selectedChain ? selectedChain.name : 'Select Network'}
              </Typography>
            </MenuItem>
          )
        }}
      >
        {chainList.map(option => (
          <MenuItem
            sx={{
              display: 'flex',
              alignItems: 'center',
              minHeight: 56,
              minWidth: 300,
              borderBottom: '1px solid rgba(66, 63, 71, 0.1)',
              '&.Mui-selected::after': {
                content: selectedChain?.name === option.name ? `url(${SelectedIcon})` : '""',
                position: 'absolute',
                right: 28
              },
              '& .MuiTypography-root': {
                fontWeight: 400
              }
            }}
            value={option.name}
            key={option.name}
            selected={selectedChain?.name === option.name}
          >
            <StyledImgBox
              sx={{ height: 40, mr: 12, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Image src={option.logo as string} alt={`${option.name} logo`} />
            </StyledImgBox>
            <Typography variant="body1" sx={{ fontSize: '16px', fontWeight: 500 }}>
              {option.name}
            </Typography>
          </MenuItem>
        ))}
      </StyledSelect>
    </Box>
  )
}

interface ChainProps {
  label?: string
  disabled?: boolean
  chainList: Blockchain[]
  selectedChain: Blockchain | null
  onChange?: (e: any) => void
  width?: string
  active?: boolean
  placeholder?: string
  addNetworkBtn?: boolean
}

export function BlockChainSelect({
  label,
  disabled,
  chainList,
  onChange,
  selectedChain,
  width,
  addNetworkBtn
}: ChainProps) {
  const theme = useTheme()
  const node = useRef<any>()
  const [open, setOpen] = useState(false)
  // const isDownMd = useBreakpoint('md')

  useOnClickOutside(node, () => {
    setOpen(false)
  })

  const handleChange = useCallback(
    e => {
      const chain = chainList.find(chain => chain.name === e.target.value) ?? null
      onChange && onChange(chain)
    },
    [chainList, onChange]
  )

  const onOpen = useCallback(e => {
    if (e.target.tagName === 'BUTTON') {
      return setOpen(false)
    }

    setOpen(true)
  }, [])

  return (
    <Box sx={{ width }} ref={node}>
      {label && <InputLabel>{label}</InputLabel>}
      <StyledSelect
        sx={{ width: '100%' }}
        open={open}
        onOpen={onOpen}
        displayEmpty
        // defaultValue={selectedChain?.name}
        value={selectedChain?.name ?? ''}
        disabled={disabled}
        MenuProps={{
          PaperProps: {
            sx: {
              marginTop: '5px',
              background: theme.palette.background.default,
              '& .MuiList-root': { height: '360px' }
            }
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        }}
        IconComponent={ExpandMoreIcon}
        onChange={handleChange}
        renderValue={() => {
          return (
            <MenuItem
              sx={{
                padding: 0,
                width: '100%',
                display: 'block',
                '&.Mui-selected': {
                  background: '#FFFFFF'
                },
                '&.Mui-selected:hover': {
                  background: '#FFFFFF'
                },
                '&:hover': {
                  background: 'transparent'
                }
              }}
              value={selectedChain?.name ?? ''}
              key={selectedChain?.name ?? ''}
              selected={!!selectedChain}
            >
              <Box display="flex" justifyContent="space-between" width="100%">
                <StyledImgBox sx={{ mb: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  {selectedChain ? (
                    <Image src={selectedChain.network?.logo ?? ''} alt={`${selectedChain.name} logo`} />
                  ) : (
                    <span></span>
                  )}
                </StyledImgBox>
                {addNetworkBtn && (
                  <RoundButton
                    onClick={() => {}}
                    width="100px"
                    height="28px"
                    backgroundColor="rgba(22, 22, 22, 0.1)"
                    fontSize="12px"
                    fontWeight={400}
                  >
                    Add Network
                  </RoundButton>
                )}
              </Box>

              <Typography
                variant="body1"
                sx={{
                  fontSize: '16px',
                  fontWeight: selectedChain ? 500 : 400,
                  color: selectedChain ? 'inherit' : 'rgba(22, 22, 22, 0.5)'
                }}
              >
                {selectedChain ? selectedChain.name : 'Select Network'}
              </Typography>
            </MenuItem>
          )
        }}
      >
        {chainList.map(option => (
          <MenuItem
            sx={{
              display: 'flex',
              alignItems: 'center',
              minHeight: 56,
              minWidth: 300,
              borderBottom: '1px solid rgba(66, 63, 71, 0.1)',
              '&.Mui-selected::after': {
                content: selectedChain?.name === option.name ? `url(${SelectedIcon})` : '""',
                position: 'absolute',
                right: 28
              },
              '& .MuiTypography-root': {
                fontWeight: 400
              }
            }}
            value={option.name}
            key={option.name}
            selected={selectedChain?.name === option.name}
          >
            <StyledImgBox
              sx={{ height: 40, mr: 12, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Image src={option.network?.logo ?? ''} alt={`${option.name} logo`} />
            </StyledImgBox>
            <Typography variant="body1" sx={{ fontSize: '16px', fontWeight: 500 }}>
              {option.name}
            </Typography>
          </MenuItem>
        ))}
      </StyledSelect>
    </Box>
  )
}
