import Axios from './axios'

const { post } = Axios

export function fetchBridgeFee({
  calldata,
  receiveSide,
  chainIdFrom,
  chainIdTo
}: {
  calldata: string
  receiveSide: string
  chainIdFrom: number
  chainIdTo: number
}) {
  const params = {
    chain_id_from: chainIdFrom,
    chain_id_to: chainIdTo,
    receive_side: receiveSide,
    call_data: calldata
  }

  return post(`https://api.symbiosis.finance/calculations/v1/swap/price`, params)
}
