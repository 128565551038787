import { ChangeEvent } from 'react'
import { Box } from '@mui/material'
import TextButton from 'components/Button/TextButton'
import SwitchButton from 'components/Select/ChainSwap/SwitcherButton'
import SwapRow from './SwapRow'
import useBreakpoint from 'hooks/useBreakpoint'
import { Blockchain, RangoToken } from 'utils/fetch/swap'
import BigNumber from 'bignumber.js'

interface Props {
  fromChainList: Blockchain[]
  fromChain: Blockchain | null
  setFromChain: (chain: Blockchain) => void
  fromAmount: string
  onSelectFromCurrency: () => void
  onChangeCurrencyAmount: (e: ChangeEvent<HTMLInputElement>) => void
  fromCurrency?: RangoToken
  fromBalance: string
  unit: string
  onMax?: () => void
  toChainList: Blockchain[]
  toChain: Blockchain | null
  setToChain: (chain: Blockchain) => void
  toAmount: string
  onSwitch: () => void
  onSelectToCurrency: () => void
  toCurrency?: RangoToken
  addNetworkBtn?: boolean
}

export default function SwapForm(props: Props) {
  const isDownMd = useBreakpoint('md')

  const {
    fromChainList,
    fromChain,
    setFromChain,
    fromAmount,
    onSelectFromCurrency,
    onChangeCurrencyAmount,
    fromCurrency,
    fromBalance,
    unit,
    onMax,
    toChainList,
    toChain,
    setToChain,
    toAmount,
    onSwitch,
    onSelectToCurrency,
    toCurrency,
    addNetworkBtn
  } = props

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: {
          xs: 28,
          md: 70
        },
        position: 'relative'
      }}
    >
      <SwapRow
        label1="From"
        label2="You will pay"
        chainList={fromChainList}
        selectedChain={fromChain}
        onChange={setFromChain}
        amount={fromAmount}
        onClickSelect={onSelectFromCurrency}
        onChangeInput={onChangeCurrencyAmount}
        currency={fromCurrency}
        balance={fromBalance}
        unit={unit}
        onMax={onMax}
        addNetworkBtn={addNetworkBtn}
      />

      <Box
        position="absolute"
        sx={{
          left: isDownMd ? 'calc(50% - 16px)' : 'calc(240px - 10px)',
          top: isDownMd ? 'calc(50% + 6px)' : 'calc(50% - 16px)'
        }}
      >
        <TextButton onClick={onSwitch} disabled={!fromChain || !toChain}>
          <SwitchButton rotateDeg={90} />
        </TextButton>
      </Box>

      <SwapRow
        label1="To"
        label2="You will receive"
        chainList={toChainList}
        selectedChain={toChain}
        onChange={setToChain}
        amount={toAmount && new BigNumber(toAmount).isGreaterThan(0) ? new BigNumber(toAmount).toFixed(6) : ''}
        onClickSelect={onSelectToCurrency}
        currency={toCurrency}
        inputDisabled
        addNetworkBtn={addNetworkBtn}
      />
    </Box>
  )
}
