import { useTransactionAdder } from '../state/transactions/hooks'
import { calculateGasMargin, getContract } from '../utils'
import { TransactionResponse } from '@ethersproject/providers'
import CBRIDGE_ABI from '../constants/abis/bridge/cbridge.json'
import { ContractCallbackState } from './useDepositeOnceCallback'
import { useActiveWeb3React } from '.'
import { hexlify } from '@ethersproject/bytes'
import { getAddress } from '@ethersproject/address'
import { decode } from '@ethersproject/base64'

// import { base64, keccak256 } from 'ethers/lib/utils'
// import { WithdrawReq, WithdrawType } from '../ts-proto/sgn/cbridge/v1/tx_pb'
// import { WithdrawMethodType } from '../ts-proto/sgn/gateway/v1/gateway_pb'

// export const requestRestRefund = async (transferId: string, estimated: number, signer): Promise<any> => {
//   const timestamp = Math.floor(Date.now() / 1000)
//   const withdrawReqProto = new WithdrawReq()
//   withdrawReqProto.setXferId(transferId)
//   withdrawReqProto.setReqId(timestamp)
//   withdrawReqProto.setWithdrawType(WithdrawType.WITHDRAW_TYPE_REFUND_TRANSFER)
//   let sig
//   try {
//     sig = await signer.signMessage(arrayify(keccak256(withdrawReqProto.serializeBinary())))
//   } catch (error) {
//     console.log(error)
//   }

//   const bytes = arrayify(sig)
//   const req = {
//     withdraw_req: base64.encode(withdrawReqProto.serializeBinary() || ''),
//     sig: base64.encode(bytes || ''),
//     estimated_received_amt: estimated,
//     method_type: WithdrawMethodType.WD_METHOD_TYPE_ALL_IN_ONE
//   }

//   // use your preferred RPC framework
//   return (
//     axios
//       // heads up to replace REACT_APP_SERVER_URL
//       .post(`${process.env.REACT_APP_SERVER_URL}/v1/withdrawLiquidity`, {
//         ...req
//       })
//       .then(res => {
//         return res.data
//       })
//       .catch(e => {
//         console.log('error=>', e)
//       })
//   )
// }

export function useCbridgeRefundCallback(): {
  state: ContractCallbackState
  callback:
    | undefined
    | ((
        depositAddress: string | undefined,
        wdOnchain?: string | null | undefined,
        sigs?: any[] | undefined,
        signers?: string[] | undefined,
        powers?: string[] | undefined
      ) => Promise<string>)
  error: string | null
} {
  const addTransaction = useTransactionAdder()
  const { account, library } = useActiveWeb3React()

  return {
    state: ContractCallbackState.VALID,
    callback: async function onCall(
      depositAddress: string | undefined,
      wdOnchain?: string | null | undefined,
      sigs?: any[] | undefined,
      signers?: string[] | undefined,
      powers?: string[] | undefined
    ): Promise<string> {
      if (!account || !library) {
        throw new Error('Unexpected error. account error')
      }
      if (!depositAddress) {
        throw new Error('Unexpected error. depositAddress error')
      }
      const contract = getContract(depositAddress, CBRIDGE_ABI, library, account)
      if (!contract) {
        throw new Error('Unexpected error. Contract error')
      }
      if (!wdOnchain || !sigs || !signers || !powers) {
        throw new Error('Unexpected error. args error')
      }

      const args: any[] = []
      args[0] = decode(wdOnchain)
      args[1] = sigs.map(item => {
        return decode(item)
      })
      args[2] = signers.map(item => {
        const decodeSigners = decode(item)
        const hexlifyObj = hexlify(decodeSigners)
        return getAddress(hexlifyObj)
      })

      args[3] = powers.map(item => {
        return decode(item)
      })

      return contract.estimateGas.withdraw(...args, { from: account }).then(estimatedGasLimit => {
        return contract
          .withdraw(...args, {
            gasLimit: calculateGasMargin(estimatedGasLimit),
            from: account
          })
          .then((response: TransactionResponse) => {
            const summary = `Request a refund with CBridge`
            addTransaction(response, {
              summary: summary
            })
            return response.hash
          })
      })
    },
    error: ''
  }
}
