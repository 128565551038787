import React from 'react'
import { ButtonBase, useTheme } from '@mui/material'

interface Props {
  onClick?: (() => void) | null
  primary?: boolean
  children: React.ReactNode
  width?: string | number
  height?: string | number
  fontSize?: string | number
  fontWeight?: string | number
  disabled?: boolean
  color?: string
  borderRadius?: string
  style?: React.CSSProperties
}

export default function OutlineButton(props: Props) {
  const {
    onClick,
    disabled,
    style,
    width,
    fontSize,
    fontWeight,
    color,
    primary,
    height,
    borderRadius,
    children
  } = props
  const theme = useTheme()

  return (
    <ButtonBase
      onClick={onClick ?? undefined}
      disabled={disabled}
      sx={{
        width: width || '100%',
        border: theme =>
          `1px solid ${color ? color : primary ? theme.palette.primary.main : theme.palette.text.secondary}`,
        fontSize,
        fontWeight: fontWeight || '400',
        height: height || 60,
        color: theme.palette.primary.main,
        borderRadius: borderRadius ?? 1,
        backgroundColor: theme.palette.background.default,
        '&:hover': {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main
        },
        '&:disabled': {
          opacity: theme.palette.action.disabledOpacity
        },
        ...style
      }}
    >
      {children}
    </ButtonBase>
  )
}

export function RoundOutlineButton(props: Props) {
  return <OutlineButton borderRadius="57px" {...props} />
}

export function RoundGrayOutlineButton(props: Props) {
  const { onClick, disabled, style, width, fontSize, fontWeight, primary, height, borderRadius, children } = props
  const theme = useTheme()

  return (
    <ButtonBase
      onClick={onClick ?? undefined}
      disabled={disabled}
      sx={{
        width: width || '100%',
        border: '1px solid #F3F3F3',
        fontSize,
        fontWeight: fontWeight || '400',
        height: height || 60,
        color: theme.palette.primary.main,
        borderRadius: borderRadius ?? 60,
        backgroundColor: primary ? '#F3F3F3' : 'transparent',
        '&:hover': {
          color: theme.palette.primary.main,
          backgroundColor: '#F3F3F3'
        },
        '&:disabled': {
          opacity: theme.palette.action.disabledOpacity
        },
        ...style
      }}
    >
      {children}
    </ButtonBase>
  )
}
