import { useMemo } from 'react'
import Image from 'components/Image'
import { Box, Typography } from '@mui/material'
import JourneyPopOver from 'components/JourneyStepper/JourneyPopOver'
import JourneyStepper from 'components/JourneyStepper'
import BridgeLogo from 'components/BridgeLogo'
import { Swap, Swapper } from '../../../utils/fetch/swap'
import { RangoFromToAssets } from '../../../state/rangoTransactions/actions'
import BigNumber from 'bignumber.js'

export enum JourneyType {
  TOKEN = 'Token',
  BRIDGE = 'Bridge'
}

export enum JourneyStatus {
  PENDING = 'Pending',
  SUCCESS = 'Success',
  PROCESSING = 'Processing'
}

interface Props {
  steps: Swap[]
  swappers: Swapper[] | undefined
}

const labelWidth = 40

function tokenStep(token: RangoFromToAssets) {
  return {
    label: <Image style={{ width: labelWidth }} src={token.logo} />,
    description: (
      <Box pl={16}>
        <Typography fontSize={16} fontWeight={500}>
          {token.symbol}
        </Typography>
        <Typography fontSize={12} fontWeight={400} sx={{ opacity: 0.5 }}>
          {token.blockchain}
        </Typography>
      </Box>
    )
  }
}

export default function Jounery(props: Props) {
  const { steps, swappers } = props
  const journeySteps = useMemo(() => {
    return steps
      .map((step, index) => {
        const swapper = swappers?.find(swapper => {
          return swapper.id === step.swapperId
        })
        return index === 0
          ? [
              tokenStep(step.from),
              {
                label: (
                  <BridgeLogo
                    src={swapper?.logo ?? ''}
                    size={24}
                    alt={`${swapper?.id} logo`}
                    style={{ marginTop: 10 }}
                  />
                ),
                description: (
                  <JourneyPopOver style={{ marginLeft: 4 }}>
                    <Typography fontSize={10} fontWeight={700}>
                      {step.swapperId}
                    </Typography>
                    <Box display="flex" gap={15}>
                      <Box display="flex" gap={8}>
                        <Typography fontSize={10} sx={{ opacity: 0.5 }}>
                          Fee:
                        </Typography>
                        <Typography fontSize={10}>
                          {new BigNumber(step.fee[0]?.amount).toFixed(4)} {step.fee[0]?.asset.symbol}
                        </Typography>
                      </Box>
                      <Box display="flex" gap={8}>
                        <Typography fontSize={10} sx={{ opacity: 0.5 }}>
                          Time:
                        </Typography>
                        <Typography fontSize={10}>{step.estimatedTimeInSeconds} s</Typography>
                      </Box>
                    </Box>
                  </JourneyPopOver>
                )
              },
              tokenStep(step.to)
            ]
          : [
              {
                label: (
                  <BridgeLogo
                    src={swapper?.logo ?? ''}
                    size={24}
                    alt={`${swapper?.id} logo`}
                    style={{ marginTop: 10 }}
                  />
                ),
                description: (
                  <JourneyPopOver style={{ marginLeft: 4 }}>
                    <Typography fontSize={10} fontWeight={700}>
                      {step.swapperId}
                    </Typography>
                    <Box display="flex" gap={15}>
                      <Box display="flex" gap={8}>
                        <Typography fontSize={10} sx={{ opacity: 0.5 }}>
                          Fee:
                        </Typography>
                        <Typography fontSize={10}>
                          {new BigNumber(step.fee[0]?.amount).toFixed(4)} {step.fee[0]?.asset.symbol}
                        </Typography>
                      </Box>
                      <Box display="flex" gap={8}>
                        <Typography fontSize={10} sx={{ opacity: 0.5 }}>
                          Time:
                        </Typography>
                        <Typography fontSize={10}>{step.estimatedTimeInSeconds} s</Typography>
                      </Box>
                    </Box>
                  </JourneyPopOver>
                )
              },
              tokenStep(step.to)
            ]
      })
      .flat()
  }, [steps, swappers])

  return <JourneyStepper steps={journeySteps} labelWidth={labelWidth} />
}
