import React from 'react'
import { ButtonBase, Theme, useTheme } from '@mui/material'
import { SxProps } from '@mui/system'

interface Props {
  onClick?: () => void
  width?: string | number
  height?: string | number
  backgroundColor?: string
  disabled?: boolean
  color?: string
  children?: React.ReactNode
  fontSize?: string | number
  classname?: string
  borderRadius?: string | number
  style?: React.CSSProperties & SxProps<Theme>
  primary?: boolean
  fontWeight?: string | number
}

export default function Button(props: Props) {
  const {
    onClick,
    disabled,
    style,
    width,
    height,
    fontSize,
    backgroundColor,
    color,
    children,
    borderRadius,
    primary,
    fontWeight
  } = props
  const theme = useTheme()
  return (
    <ButtonBase
      onClick={onClick}
      disabled={disabled}
      sx={{
        ...style,
        width: width || '100%',
        height: height || 60,
        fontSize: fontSize || 16,
        fontWeight: fontWeight || 500,
        transition: '.3s',
        borderRadius: borderRadius || '16px',
        backgroundColor: backgroundColor
          ? backgroundColor
          : primary
          ? theme.palette.primary.main
          : theme.palette.background.default,
        color: color ? color : primary ? theme.palette.primary.contrastText : theme.palette.text.primary,
        '&:hover': {
          color: theme.palette.primary.contrastText,
          background: theme.palette.primary.dark
        },
        '&:disabled': {
          color: 'rgba(0,0,0,0.5)',
          backgroundColor: 'rgba(0,0,0,0.1)'
        }
      }}
    >
      {children}
    </ButtonBase>
  )
}

export function RoundButton(props: Props) {
  return <Button borderRadius="57px" {...props} />
}
