import { useMemo } from 'react'
import { Box } from '@mui/material'
import TextButton from 'components/Button/TextButton'
import ChainNetworkSelect from '../ChainSelect/ChainNetworkSelect'
import { Chain } from 'models/chain'
import SwitchButton from './SwitcherButton'
import useBreakpoint from 'hooks/useBreakpoint'

export default function ChainSwap({
  fromChain,
  toChain,
  chainList,
  onSelectTo,
  onSelectFrom,
  disabledFrom,
  disabledTo,
  activeFrom,
  activeTo,
  onSwitch
}: {
  fromChain: Chain | null
  toChain: Chain | null
  chainList: Chain[]
  onSelectFrom?: (chain: Chain) => void
  onSelectTo?: (chain: Chain) => void
  disabledFrom?: boolean
  disabledTo?: boolean
  activeFrom?: boolean
  activeTo?: boolean
  onSwitch?: () => void
}) {
  const isDownMd = useBreakpoint('md')

  const fromChainList = useMemo(() => {
    return chainList.filter(chain => chain.id !== toChain?.id)
  }, [chainList, toChain])

  const toChainList = useMemo(() => {
    return chainList.filter(chain => chain.id !== fromChain?.id)
  }, [chainList, fromChain])

  return (
    <Box
      display="flex"
      flexDirection={isDownMd ? 'column' : 'row'}
      justifyContent="space-between"
      alignItems={isDownMd ? 'stretch' : 'flex-end'}
      position={'relative'}
      width="100%"
      gap={isDownMd ? 28 : 24}
    >
      <ChainNetworkSelect
        label="From"
        selectedChain={fromChain}
        chainList={fromChainList}
        onChange={onSelectFrom}
        width={isDownMd ? '100%' : '49%'}
        disabled={disabledFrom}
        active={activeFrom}
      />
      <Box position="absolute" top="50%" left={'calc(50% - 16px)'} zIndex={99} height="32px" bottom="35px">
        <TextButton onClick={onSwitch} disabled={disabledFrom || disabledTo || !onSelectTo || !onSelectFrom}>
          <SwitchButton rotateDeg={90} />
        </TextButton>
      </Box>
      <ChainNetworkSelect
        label="To"
        selectedChain={toChain}
        chainList={toChainList}
        onChange={onSelectTo}
        width={isDownMd ? '100%' : '49%'}
        disabled={disabledTo}
        active={activeTo}
      />
    </Box>
  )
}
