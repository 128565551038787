import { useActiveWeb3React } from 'hooks'
import { isAddress, shortenAddress } from 'utils'
import { useHistory, useLocation } from 'react-router'
import { SwitchTabWrapper, Tab } from 'components/SwitchTab'
import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState } from 'react'
import PaginationView from 'components/Pagination'
import TableView from 'components/Table'
import Spinner from 'components/Spinner'
// import { ChainListMap } from 'constants/chain'
import Image from 'components/Image'
// import { routes } from 'constants/routes'
import { Box, Chip, Typography, useTheme, styled } from '@mui/material'
import Copy from 'components/essential/Copy'
import { ReactComponent as UcenterIcon } from 'assets/svg/web3status_icon.svg'
import { RoundGrayOutlineButton, RoundOutlineButton } from 'components/Button/OutlineButton'
import { useMyCrossActivity, ActivityCrossRecordItemProp } from 'hooks/useAccount'
import { ReactComponent as NoDataIcon } from 'assets/svg/no-data.svg'
import { swapResultStatus, useChainSwapResult } from 'hooks/useChainSwapResult'
import CurrencyLogo from 'components/essential/CurrencyLogo'
import { ChainListMap } from 'constants/chain'
import { useWeb3React } from '@web3-react/core'
import { ReactComponent as ToRightIcon } from 'assets/svg/to-right.svg'
import { HideOnMobile } from 'theme'
import CbridgeRefund from './CbridgeRefund'
import { SUPPORT_PLATFORM } from 'models/platform'
import useBreakpoint from 'hooks/useBreakpoint'
import dayjs from 'dayjs'

const StyledChip = styled(Chip)(({ type }: { type: 'fail' | 'success' | 'pending' }) => ({
  height: 28,
  width: 64,
  fontSize: 12,
  background:
    type === 'fail'
      ? 'rgba(235, 19, 18, 0.12)'
      : type === 'pending'
      ? 'rgba(100, 65, 155, 0.12)'
      : 'rgba(17, 191, 45, 0.12)',
  color: type === 'fail' ? 'rgba(235, 19, 18, 1)' : type === 'pending' ? 'rgba(100, 65, 155, 1)' : '#11BF2D'
}))

function Chiper({ ActivityCrossRecordItem }: { ActivityCrossRecordItem: ActivityCrossRecordItemProp }) {
  const { bridge, toAccount, fromTXHash, toChain, pairID, fromChain, cbridgeTransferId } = ActivityCrossRecordItem
  const { loading: swapResultLoading, result: swapResult } = useChainSwapResult(
    bridge,
    toAccount,
    fromTXHash,
    toChain.toString(),
    pairID,
    fromChain.toString(),
    cbridgeTransferId
  )
  return swapResult === swapResultStatus.SUCCESS ? (
    <StyledChip label="success" size="small" type="success" />
  ) : swapResult === swapResultStatus.PENDING || swapResultLoading ? (
    <StyledChip label="pending" size="small" type="pending" />
  ) : swapResult === swapResultStatus.Fail ? (
    <StyledChip label="fail" size="small" type="fail" />
  ) : (
    <StyledChip label="unknown" size="small" type="pending" />
  )
}

export enum UserInfoTabs {
  // INVENTORY = 'Inventory',
  ACTIVITY = 'Activity'
}
export const UserInfoTabRoute = {
  // [UserInfoTabs.INVENTORY]: 'Inventory',
  [UserInfoTabs.ACTIVITY]: 'Activity'
}
export enum AccountEventType {
  AGGREGATOR = 'Aggregator'
}

function SwitchTab({
  currentTab,
  onTabClick
}: {
  currentTab: UserInfoTabs
  onTabClick: (tab: UserInfoTabs) => () => void
}) {
  return (
    <SwitchTabWrapper>
      {Object.keys(UserInfoTabRoute).map(tab => {
        const tabName = UserInfoTabRoute[tab as keyof typeof UserInfoTabRoute]
        return (
          <Tab key={tab} onClick={onTabClick(tab as UserInfoTabs)} selected={currentTab === tab}>
            {tabName}
          </Tab>
        )
      })}
    </SwitchTabWrapper>
  )
}

export default function Account() {
  const { account } = useActiveWeb3React()
  const { deactivate } = useWeb3React()
  const history = useHistory()
  const theme = useTheme()
  const { tab } = useParams<{ tab: string }>()
  const location = useLocation()
  const [currentTab, setCurrentTab] = useState(UserInfoTabs.ACTIVITY)
  const [currentEventType, setCurrentEventType] = useState(AccountEventType.AGGREGATOR)
  const { page: myCrossActivityPage, loading: myCrossActivityLoading, data: myCrossActivityData } = useMyCrossActivity()
  const isDownMd = useBreakpoint('md')

  useEffect(() => {
    if (!account) history.replace('/')
  }, [account, history])

  const handleTabClick = useCallback(
    tab => () => {
      setCurrentTab(tab)
      history.push('/profile/' + tab)
    },
    [history]
  )
  useEffect(() => {
    if (tab && UserInfoTabRoute[tab as keyof typeof UserInfoTabRoute]) {
      setCurrentTab(tab as UserInfoTabs)
    }
  }, [tab, location])

  const myActivityListRows = useMemo(() => {
    return myCrossActivityData.map(item => [
      <Chiper key={0} ActivityCrossRecordItem={item} />,
      <Box key={1} gap="5px" display="flex" alignItems="center">
        <CurrencyLogo size="28px" currency={item.currentToken.currency} />
        <Typography fontSize="16px">{item.amount + ' ' + item.currentToken.currency.symbol}</Typography>
      </Box>,
      <Box key={2} display="flex" justifyContent="space-between" alignItems="center" gap="20px">
        <Box gap="5px" display="flex" alignItems="center">
          <Image height="28px" src={ChainListMap[item.fromChain].logo} alt="" />
          <Typography fontSize="16px">
            {isAddress(item.srcTokenAddress) && shortenAddress(item.srcTokenAddress)}
          </Typography>
          <Copy size={24} toCopy={item.srcTokenAddress} />
        </Box>
        <HideOnMobile>
          <ToRightIcon />
        </HideOnMobile>
      </Box>,
      <Box key={3} gap="5px" display="flex" alignItems="center">
        <Image height="28px" src={ChainListMap[item.toChain].logo} alt="" />
        <Typography fontSize="16px">
          {isAddress(item.destTokenAddress) && shortenAddress(item.destTokenAddress)}
        </Typography>
        <Copy size={24} toCopy={item.destTokenAddress} />
      </Box>,
      <Box key={4} gap="5px" alignItems="center">
        <Typography fontSize="16px">
          {item.fee} {item.feeSymbol}
        </Typography>
      </Box>,
      item.bridge,
      <Typography key={5} fontSize="16px">
        {dayjs(item.time * 1000).format('MM.DD.YYYY HH:mm')}
      </Typography>,
      item.bridge === SUPPORT_PLATFORM.CBRIDGE && item.cbridgeTransferId ? (
        <CbridgeRefund cbridgeTransferId={item.cbridgeTransferId} depositAddress={item.destTokenAddress} />
      ) : (
        <></>
      )
    ])
  }, [myCrossActivityData])

  return (
    <Box
      width="100%"
      maxWidth="1222px"
      sx={{
        minHeight: `calc(100vh - ${theme.height.header})`,
        padding: '30px 15px'
      }}
    >
      <Box
        display="flex"
        justifyContent={isDownMd ? 'center' : 'space-between'}
        alignItems="center"
        style={{ marginBottom: isDownMd ? 42 : 88 }}
      >
        <Box
          display="flex"
          alignContent={'center'}
          flexDirection={isDownMd ? 'column' : 'row'}
          alignItems={isDownMd ? 'center' : 'normal'}
        >
          <UcenterIcon width={60} height={60} style={{ marginRight: isDownMd ? 0 : 20 }} />
          {account && (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-around"
              alignItems={isDownMd ? 'center' : 'normal'}
              mt={isDownMd ? 16 : 0}
              gap={isDownMd ? 16 : 4.5}
            >
              <Typography variant="body2" color={theme.palette.text.primary} sx={{ opacity: 0.5 }}>
                Connect with Metamask
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                gap="15px"
                sx={{
                  height: 48,
                  padding: '6px 16px 6px 20px',
                  background: '#F7F7F8',
                  borderRadius: '100px'
                }}
              >
                <Typography variant="body1">{shortenAddress(account)}</Typography>
                {account && <Copy size={20} toCopy={account}></Copy>}
              </Box>
            </Box>
          )}
        </Box>
        {!isDownMd && (
          <RoundOutlineButton
            borderRadius="16px"
            width="170px"
            fontWeight={500}
            height="48px"
            onClick={() => deactivate()}
          >
            Log Out
          </RoundOutlineButton>
        )}
      </Box>

      <Box display="grid" gap={isDownMd ? '24px' : '40px'}>
        <SwitchTab onTabClick={handleTabClick} currentTab={currentTab} />

        {currentTab === UserInfoTabs.ACTIVITY && (
          <>
            <Box display="flex" gap="20px" flexWrap="wrap">
              {Object.values(AccountEventType).map(item =>
                currentEventType === item ? (
                  <RoundGrayOutlineButton fontWeight={500} primary key={item} width="120px" height="40px">
                    {item}
                  </RoundGrayOutlineButton>
                ) : (
                  <RoundGrayOutlineButton
                    key={item}
                    width="120px"
                    height="40px"
                    onClick={() => setCurrentEventType(item)}
                    fontWeight={500}
                  >
                    {item}
                  </RoundGrayOutlineButton>
                )
              )}
            </Box>
            <TableView
              sidePadding="24px"
              header={['Status', 'Amount', 'From', 'To', 'Netword Fee', 'Bridge', 'Date', '']}
              rows={myActivityListRows}
              rootPadding="8px 12px"
            />
            {myCrossActivityLoading && (
              <Box display="flex" justifyContent="center">
                <Spinner size="40px" />
              </Box>
            )}
            {!myCrossActivityLoading && myActivityListRows.length === 0 && (
              <Box display="flex" justifyContent="center" alignItems="center" gap="8px">
                <NoDataIcon />
                <Typography fontSize="16" fontWeight="500">
                  No data
                </Typography>
              </Box>
            )}
            <Box display="flex" flexDirection={isDownMd ? 'column' : 'row-reverse'} pb={60}>
              <PaginationView
                count={myCrossActivityPage.totalPages}
                page={myCrossActivityPage.currentPage}
                onChange={() => {}}
                perPage={myCrossActivityPage.pageSize}
                setPage={myCrossActivityPage.setCurrentPage}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}
