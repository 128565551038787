import { ChangeEvent } from 'react'
import { Box } from '@mui/material'
import { BlockChainSelect } from 'components/Select/ChainSelect/ChainNetworkSelect'
import CurrencyInput from '../CurrencyInput'
import useBreakpoint from 'hooks/useBreakpoint'
import { Blockchain, RangoToken } from 'utils/fetch/swap'

interface Props {
  label1: string
  label2: string
  chainList: Blockchain[]
  selectedChain: Blockchain | null
  onChange: (chain: Blockchain) => void
  amount: string
  onClickSelect: () => void
  onChangeInput?: (e: ChangeEvent<HTMLInputElement>) => void
  currency?: RangoToken
  balance?: string
  unit?: string
  onMax?: () => void
  inputDisabled?: boolean
  addNetworkBtn?: boolean
}

export default function SwapRow(props: Props) {
  const isDownMd = useBreakpoint('md')

  const {
    label1,
    label2,
    chainList,
    selectedChain,
    onChange,
    amount,
    onClickSelect,
    onChangeInput,
    currency,
    balance,
    unit,
    onMax,
    inputDisabled,
    addNetworkBtn
  } = props

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row'
        },
        gap: { xs: 28, md: 12 }
      }}
    >
      <BlockChainSelect
        width={isDownMd ? '100%' : '240px'}
        label={label1}
        chainList={chainList}
        selectedChain={selectedChain}
        onChange={onChange}
        addNetworkBtn={addNetworkBtn}
      />
      <CurrencyInput
        width={isDownMd ? '100%' : '416px'}
        label={label2}
        value={amount}
        onClickSelect={onClickSelect}
        onChangeInput={onChangeInput}
        selectedCurrency={currency}
        balance={balance}
        unit={unit}
        onMax={onMax}
        inputDisabled={inputDisabled}
      />
    </Box>
  )
}
