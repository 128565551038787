import { RangoFromToAssets } from 'state/rangoTransactions/actions'
import Axios from './axios'
import { Network } from '../../constants/chain'

const { get, post } = Axios
// const BASE_URL = 'https://testapi.chainswap.com/'
const BASE_URL = 'https://api.chainswap.com/'
const RANGO_ABSE_URL = 'https://api.rango.exchange/'
const RANGO_API_KEY = '55978f60-150d-4e21-8289-5bbfd8f5df88'

export interface Asset {
  address: string | null
  blockchain: string
  symbol: string
}

export interface RouterParams {
  affiliateRef: string
  amount: string
  checkPrerequisites: boolean
  from: Asset
  to: Asset
}

export interface Blockchain {
  addressPatterns: string[]
  defaultDecimals: number
  feeAssets: Asset[]
  name: string
  network: Network | undefined
}

export interface BlockChainName {
  name: string
}

export interface Swapper {
  id: string
  logo: string
  title: string
}

export interface RangoToken {
  address: string
  blockchain: string
  decimals: number
  image: string
  name: string
  symbol: string
  usdPrice: number
}

export interface RangoAPIToken {
  a: string
  b: string
  d: number
  i: string
  n: string
  s: string
  p: number
}

export interface RangoResult {
  blockchains: Blockchain[]
  swappers: Swapper[]
  tokens: RangoToken[]
}

export interface RangoBsetRoutingReq {
  affiliateRef: string | null
  amount: string
  blockchains: string[]
  previousRequestId?: string
  checkPrerequisites: boolean
  connectedWallets:
    | {
        addresses: string[]
        blockchain: string
      }[]
    | null
  disableMultiStepTx: boolean
  from: Asset
  selectedWallets:
    | {
        [key in string]: string
      }[]
    | Record<string, string>
  swappers: string[]
  to: Asset
  transactionTypes: string[]
}

enum COMPARE_STATUS {
  IDENTICAL = 'IDENTICAL',
  NOTHING_TO_COMPARE = 'NOTHING_TO_COMPARE',
  PREVIOUS_NOT_FOUND = 'PREVIOUS_NOT_FOUND',
  PREVIOUS_ROUTE_IS_EMPTY = 'PREVIOUS_ROUTE_IS_EMPTY',
  REQUESTS_NOT_MATCHED = 'REQUESTS_NOT_MATCHED',
  ROUTE_IS_EMPTY = 'ROUTE_IS_EMPTY',
  SWAPPERS_AND_TOKENS_CHANGED = 'SWAPPERS_AND_TOKENS_CHANGED',
  SWAPPERS_CHANGED = 'SWAPPERS_CHANGED',
  TOKENS_CHANGED = 'TOKENS_CHANGED'
}

enum EXPENSE_TYPE {
  DECREASE_FROM_OUTPUT = 'DECREASE_FROM_OUTPUT',
  FROM_SOURCE_WALLET = 'FROM_SOURCE_WALLET'
}

enum FROM_AMOUNT_RESTRICTION_TYPE {
  EXCLUSIVE = 'EXCLUSIVE',
  INCLUSIVE = 'INCLUSIVE'
}

enum SWAP_CHAIN_TYPE {
  INTER_CHAIN = 'INTER_CHAIN',
  INTRA_CHAIN = 'INTRA_CHAIN'
}

export enum SWAPPER_TYPE {
  BRIDGE = 'BRIDGE',
  DEX = 'DEX'
}

export interface Node {
  from: string
  fromAddress: string
  fromBlockchain: string
  fromLogo: string
  nodes: Dapp
  to: string
  toAddress: string
  toBlockchain: string
  toLogo: string
}

export interface Dapp {
  marketId: string
  marketName: string
  percent: number
}

export interface Swap {
  estimatedTimeInSeconds: string
  fee: {
    amount: number
    asset: Asset
    expenseType: EXPENSE_TYPE
    name: string
  }[]
  from: RangoFromToAssets
  fromAmount: string
  fromAmountMaxValue: string
  fromAmountMinValue: string
  fromAmountPrecision: string
  fromAmountRestrictionType: FROM_AMOUNT_RESTRICTION_TYPE
  fromAsset: Asset
  recommendedSlippage: {
    error: boolean
    slippage: number
  }
  routes: {
    nodes: {
      from: string
      fromAddress: string
      fromBlockchain: string
      fromLogo: string
      nodes: Node[]
    }[]
  }[]
  swapChainType: SWAP_CHAIN_TYPE
  swapperId: string
  swapperType: SWAPPER_TYPE
  to: RangoFromToAssets
  toAmount: string
  toAsset: Asset
  warnings: string
}

export interface RangoBestRoutingRes {
  compareStatus: COMPARE_STATUS
  diagnosisMessages: string[]
  from: Asset
  missingBlockchains: string[]
  processingLimitReached: boolean
  requestAmount: string
  requestId: string | null
  result: {
    outputAmount: string
    swaps: Swap[]
  } | null
}

export function fetchRangoData() {
  return get(`${RANGO_ABSE_URL}meta/compact?apiKey=${RANGO_API_KEY}`)
}

export function fetchRangoBestRouter(params: RangoBsetRoutingReq) {
  return post(`${RANGO_ABSE_URL}routing/best?apiKey=${RANGO_API_KEY}`, params, {
    headers: {
      'X-Rango-Id': '7ty79ha4q6i2bo6kpiyxk7',
      'content-type': 'application/json;charset=UTF-8'
    }
  })
}

export function fetchTokens() {
  return post(`${BASE_URL}tokens`)
}

export function fetchChains() {
  return post(`${BASE_URL}chains`, {})
}

export function fetchCrossChainList(fromChainSymbol: string, fromChainId: number, toChainId: number) {
  return get(`${BASE_URL}platforms`, {
    token: fromChainSymbol,
    srcChainId: fromChainId,
    destChainId: toChainId
  })
}

export function fetchPolyNetworkFee(fromChainId: number, toChainId: number, fromToken: string, toToken: string) {
  return post('https://bridge.poly.network/v1/getfee', {
    SrcChainId: fromChainId,
    SwapTokenHash: toToken,
    Hash: fromToken,
    DstChainId: toChainId
  })
}

export function commitCrossRecord(args: {
  bridge: string
  fromChain: number
  toChain: number
  fromAccount: string
  toAccount: string
  fromTXHash: string
  pairID: string
  srcToken: string
  destToken: string
  amount: string
  amountInt: string
  symbol: string
  fee: string
  feeInt: string
  feeSymbol: string
  depositAddress: string
  cbridgeTransferId: string
}) {
  return post(`${BASE_URL}store_user_records`, {
    ...args,
    time: (new Date().getTime() / 1000).toFixed(0)
  })
}

export function getMyCrossRecord(account: string, page: number, pageSize = 8) {
  return get(`${BASE_URL}query_user_records`, {
    account,
    pagenumber: page,
    pagesize: pageSize
  })
}

export function getDashboardInfo() {
  return get(`${BASE_URL}statistics`)
}

export function getStatInfo() {
  return get(`${BASE_URL}stat_info`)
}

export function rangoCreateTransaction(requestId: string, step: number, slippage = '1') {
  return post(`${RANGO_ABSE_URL}tx/create?apiKey=${RANGO_API_KEY}`, {
    requestId,
    step,
    userSettings: {
      slippage
    },
    validations: {
      balance: true,
      fee: true
    }
  })
}

export function rangoCheckTXStatus(requestId: string, step: number, txId: string) {
  return post(`${RANGO_ABSE_URL}tx/check-status?apiKey=${RANGO_API_KEY}`, {
    requestId,
    step,
    txId
  })
}

export function rangoCheckTXApproval(requestId: string) {
  return get(`${RANGO_ABSE_URL}tx/${requestId}/check-approval?apiKey=${RANGO_API_KEY}`)
}
