import React from 'react'
import { Box, Stepper, Step, StepConnector, StepLabel } from '@mui/material'

interface Props {
  steps: { label: React.ReactNode; description: React.ReactNode }[]
  labelWidth?: number
}

export default function JourneyStepper(props: Props) {
  const { steps, labelWidth } = props

  return (
    <Stepper
      orientation="vertical"
      connector={
        <StepConnector
          sx={{
            ml: labelWidth ? labelWidth / 2 : 20,
            '& .MuiStepConnector-line': {
              borderLeft: '1px dashed rgba(0, 0, 0, 0.2)',
              minHeight: 12
            }
          }}
        />
      }
    >
      {steps.map((step, idx) => (
        <Step key={idx}>
          <Box display="flex">
            <StepLabel
              sx={{
                position: 'relative',
                padding: 0,
                '& .MuiStepLabel-iconContainer': {
                  padding: 0
                },
                height: 'fit-content',
                alignItems: 'flex-start',
                width: '100%'
              }}
              StepIconProps={{
                icon: (
                  <Box display="flex" alignItems="flex-start" gap={16}>
                    <Box
                      sx={{
                        width: labelWidth,
                        display: 'flex',
                        justifyContent: 'center',
                        background: 'transparent',
                        zIndex: 1
                      }}
                    >
                      {step.label}
                    </Box>
                  </Box>
                )
              }}
            >
              <Box
                sx={{
                  height: '100%',
                  borderLeft: idx === steps.length - 1 ? 'none' : '1px dashed rgba(0, 0, 0, 0.2)',
                  position: 'absolute',
                  left: labelWidth ? labelWidth / 2 : 0,
                  top: 0
                }}
              />
              {step.description}
            </StepLabel>
          </Box>
        </Step>
      ))}
    </Stepper>
  )
}
