import { CbridgeResultStatusProp, useCbridgeTXFetchDescResult } from 'hooks/useChainSwapResult'
import { Dots, ExternalLink } from 'theme/components'
import { useCbridgeRefundCallback } from 'hooks/useCbridgeRefundCallback'
import { useCallback } from 'react'
import TransactionPendingModal from 'components/Modal/TransactionModals/TransactionPendingModal'
import useModal from 'hooks/useModal'
import TransactionSubmittedModal from 'components/Modal/TransactionModals/TransactiontionSubmittedModal'
import MessageBox from 'components/Modal/TransactionModals/MessageBox'
import { Box } from '@mui/system'

export default function CbridgeRefund({
  cbridgeTransferId,
  depositAddress
}: {
  cbridgeTransferId: string
  depositAddress: string | undefined
}) {
  const { result } = useCbridgeTXFetchDescResult(cbridgeTransferId)
  const { callback: refundCallback } = useCbridgeRefundCallback()
  const { showModal, hideModal } = useModal()

  useCallback(() => {
    if (!refundCallback) return
    showModal(<TransactionPendingModal />)
    refundCallback(depositAddress, result?.wdOnchain, result?.sigs, result?.signers, result?.powers)
      .then(() => {
        hideModal()
        showModal(<TransactionSubmittedModal />)
      })
      .catch(err => {
        hideModal()
        showModal(
          <MessageBox type="error">{err.data && err.data.message ? err.data.message : err?.message}</MessageBox>
        )
        console.error(err)
      })
  }, [depositAddress, hideModal, refundCallback, result, showModal])

  if (result?.status === CbridgeResultStatusProp.NEED_REFUND) {
    return (
      <>
        <ExternalLink underline="always" href="https://cbridge.celer.network/#/transfer">
          Refund
        </ExternalLink>
      </>
    )
  } else if (result?.status === CbridgeResultStatusProp.REFUND_PENDING) {
    return (
      <Box display="flex" sx={{ opacity: 0.5, fontSize: 12 }}>
        Refund
        <Dots />
      </Box>
    )
  } else if (result?.status === CbridgeResultStatusProp.REFUNDED) {
    return <Box sx={{ opacity: 0.5, fontSize: 12 }}>Refuned success</Box>
  }
  return <></>
}
