import { useTransactionAdder } from '../state/transactions/hooks'
import { calculateGasMargin, checkoutContract, getContract } from '../utils'
import { TransactionResponse } from '@ethersproject/providers'
import { BridgeCrossDirection, BridgeSymbiosisDirection, SUPPORT_PLATFORM } from '../models/platform'
import { useAnyswapV2BridgeContract, useContract } from 'hooks/useContract'
import POLYNETWORK_ABI from 'constants/abis/bridge/polynetwork.json'
import ANYSWAPV1_ABI from 'constants/abis/bridge/anyswap-v1.json'
import CBRIDGE_ABI from 'constants/abis/bridge/cbridge.json'
import BICONOMY_ABI from 'constants/abis/bridge/biconomy.json'
import STARGATE_ABI from 'constants/abis/bridge/stargate_route.json'
import Symbiosis_portal_ABI from 'constants/abis/bridge/symbiosis_portal.json'
import Symbiosis_synthesis_ABI from 'constants/abis/bridge/symbiosis_synthesis.json'
import ERC20_ABI from 'constants/abis/erc20.json'
import { useActiveWeb3React } from 'hooks'
import { ChainListMap, POLY_MAPPING_CHAINID, STARGATE_MAPPING_CHAINID } from 'constants/chain'
import { CurrencyAmount } from 'constants/token'
import { useAddPopup } from 'state/application/hooks'
import { useWeb3Instance } from './useWeb3Instance'
import { ethers, utils } from 'ethers'
import { fetchBiconomySystemStatus } from 'utils/fetch/biconomy'

interface MoreDataProp {
  pairID?: string //multichain
  direction?: BridgeCrossDirection //multichain
  feeInt?: string //multichain
  underlying?: string //anyswap v2
  maxSlippage?: number // cbridge eg: slippage 0.5%
  srcPoolId?: number
  dstPoolId?: number
  minReceive?: string | undefined
  bridgeSymbiosisDirection?: BridgeSymbiosisDirection
  symbiosisSrcPortalAddress?: string
  symbiosisSrcBridgeAddress?: string
  symbiosisSrcSynthesisAddress?: string
  symbiosisDestPortalAddress?: string
  symbiosisDestBridgeAddress?: string
  symbiosisDestSynthesisAddress?: string
}

export enum ContractCallbackState {
  INVALID,
  LOADING,
  VALID
}

function usePolyNetworkDepositeCallback(
  depositAddress: string
): {
  state: ContractCallbackState
  callback:
    | undefined
    | ((
        tokenAddress: string,
        toChainId: number,
        toAccount: string,
        amount: CurrencyAmount,
        isETHER: boolean,
        fromChainId: number,
        symbol: string,
        moreData: MoreDataProp
      ) => Promise<string>)
  error: string | null
} {
  const addTransaction = useTransactionAdder()
  const contract = useContract(depositAddress, POLYNETWORK_ABI)
  const { account } = useActiveWeb3React()
  const addPopup = useAddPopup()

  return {
    state: ContractCallbackState.VALID,
    callback: async function onCall(
      tokenAddress: string,
      toChainId: number,
      toAccount: string,
      amount: CurrencyAmount,
      isETHER: boolean,
      fromChainId: number,
      symbol: string,
      moreData: MoreDataProp
    ): Promise<string> {
      if (!contract) {
        throw new Error('Unexpected error. Contract error')
      }
      if (!account) {
        throw new Error('Unexpected error. account')
      }
      if (!POLY_MAPPING_CHAINID[toChainId]) {
        throw new Error('POLY_MAPPING_CHAINID. error ' + toChainId)
      }
      if (moreData.feeInt === undefined) {
        throw new Error('feeInt. error ' + toChainId)
      }
      checkoutContract(contract.address)
      const args = [tokenAddress, POLY_MAPPING_CHAINID[toChainId], toAccount, amount.raw.toString(), moreData.feeInt, 0]

      return contract.estimateGas
        .lock(...args, { from: account, value: isETHER ? amount.raw.toString() : moreData.feeInt })
        .then(estimatedGasLimit => {
          return contract
            .lock(...args, {
              gasLimit: calculateGasMargin(estimatedGasLimit),
              from: account,
              value: isETHER ? amount.raw.toString() : moreData.feeInt
            })
            .then((response: TransactionResponse) => {
              const summary = `Cross ${amount.toSignificant() + ' ' + symbol} from ${
                ChainListMap[fromChainId].name
              } to ${ChainListMap[toChainId].name} with PolyBridge`
              const crossChain = {
                bridge: SUPPORT_PLATFORM.POLYNETWORK,
                fromChain: fromChainId,
                toChain: toChainId,
                toAddress: toAccount,
                fromTXHash: response.hash,
                pairID: '',
                value: amount.toSignificant(),
                symbol
              }
              addTransaction(response, {
                summary: summary,
                crossChain
              })
              addPopup(
                {
                  txn: {
                    hash: response.hash,
                    success: false,
                    summary,
                    crossChain
                  }
                },
                response.hash
              )
              return response.hash
            })
        })
    },
    error: ''
  }
}

function useAnyswapV1DepositeCallback(
  depositAddress: string
): {
  state: ContractCallbackState
  callback:
    | undefined
    | ((
        tokenAddress: string,
        toChainId: number,
        toAccount: string,
        amount: CurrencyAmount,
        isETHER: boolean,
        fromChainId: number,
        symbol: string,
        moreData: MoreDataProp
      ) => Promise<string>)
  error: string | null
} {
  const addTransaction = useTransactionAdder()
  const { account, library } = useActiveWeb3React()
  const addPopup = useAddPopup()
  const web3 = useWeb3Instance()

  return {
    state: ContractCallbackState.VALID,
    callback: async function onCall(
      tokenAddress: string,
      toChainId: number,
      toAccount: string,
      amount: CurrencyAmount,
      isETHER: boolean,
      fromChainId: number,
      symbol: string,
      moreData: MoreDataProp
    ): Promise<any> {
      if (!account || !web3 || !library) {
        throw new Error('Unexpected error. account, web3')
      }
      if (moreData.direction === undefined) {
        console.error('Unexpected error, direction', moreData)
        throw new Error('Unexpected error, direction')
      }

      const retResp = (response: TransactionResponse) => {
        const summary = `Cross ${amount.toSignificant() + ' ' + symbol} from ${ChainListMap[fromChainId].name} to ${
          ChainListMap[toChainId].name
        } with MultiChain`
        const crossChain = {
          bridge: SUPPORT_PLATFORM.MULTICHAIN,
          fromChain: fromChainId,
          toChain: toChainId,
          toAddress: toAccount,
          fromTXHash: response.hash,
          pairID: moreData.pairID,
          value: amount.toSignificant(),
          symbol
        }
        addTransaction(response, {
          summary: summary,
          crossChain
        })
        addPopup(
          {
            txn: {
              hash: response.hash,
              success: false,
              summary,
              crossChain
            }
          },
          response.hash
        )
        return response.hash
      }

      if (moreData.direction === BridgeCrossDirection.SRC_TO_DEST) {
        checkoutContract(depositAddress)
        // transfer
        if (isETHER) {
          return new Promise((resolve, reject) => {
            web3.eth
              .sendTransaction({
                from: account,
                to: depositAddress,
                value: amount.raw.toString()
              })
              .on('transactionHash', function(hash) {
                const summary = `Cross ${amount.toSignificant() + ' ' + symbol} from ${
                  ChainListMap[fromChainId].name
                } to ${ChainListMap[toChainId].name} with MultiChain`
                const crossChain = {
                  bridge: SUPPORT_PLATFORM.MULTICHAIN,
                  fromChain: fromChainId,
                  toChain: toChainId,
                  toAddress: toAccount,
                  fromTXHash: hash,
                  pairID: moreData.pairID,
                  value: amount.toSignificant(),
                  symbol
                }
                addTransaction({ hash, confirmations: 0, from: account, nonce: 0 } as TransactionResponse, {
                  summary: summary,
                  crossChain
                })
                addPopup(
                  {
                    txn: {
                      hash: hash,
                      success: false,
                      summary,
                      crossChain
                    }
                  },
                  hash
                )
                resolve(hash)
              })
              .catch(error => reject(error))
          })
        } else {
          const contract = getContract(tokenAddress, ERC20_ABI, library, account)
          const args = [depositAddress, amount.raw.toString()]
          return contract.estimateGas.transfer(...args, { from: account }).then(estimatedGasLimit => {
            return contract
              .transfer(...args, {
                gasLimit: calculateGasMargin(estimatedGasLimit),
                from: account
              })
              .then(retResp)
          })
        }
      } else {
        // out
        checkoutContract(tokenAddress)
        const contract = getContract(tokenAddress, ANYSWAPV1_ABI, library, account)
        const args = [amount.raw.toString(), account]
        return contract.estimateGas.Swapout(...args, { from: account }).then(estimatedGasLimit => {
          return contract
            .Swapout(...args, {
              gasLimit: calculateGasMargin(estimatedGasLimit),
              from: account
            })
            .then(retResp)
        })
      }
    },
    error: ''
  }
}

function useAnyswapV2DepositeCallback(
  depositAddress: string
): {
  state: ContractCallbackState
  callback:
    | undefined
    | ((
        tokenAddress: string,
        toChainId: number,
        toAccount: string,
        amount: CurrencyAmount,
        isETHER: boolean,
        fromChainId: number,
        symbol: string,
        moreData: MoreDataProp
      ) => Promise<string>)
  error: string | null
} {
  const addTransaction = useTransactionAdder()
  const { account } = useActiveWeb3React()
  const addPopup = useAddPopup()
  const contract = useAnyswapV2BridgeContract(depositAddress)

  return {
    state: ContractCallbackState.VALID,
    callback: async function onCall(
      _tokenAddress: string,
      toChainId: number,
      toAccount: string,
      amount: CurrencyAmount,
      isETHER: boolean,
      fromChainId: number,
      symbol: string,
      moreData: MoreDataProp
    ): Promise<any> {
      if (!account) {
        throw new Error('Unexpected error. account, web3')
      }
      if (!contract) {
        throw new Error('Unexpected error. Contract error')
      }
      if (!moreData.underlying) {
        throw new Error('Unexpected error. underlying')
      }

      const retResp = (response: TransactionResponse) => {
        const summary = `Cross ${amount.toSignificant() + ' ' + symbol} from ${ChainListMap[fromChainId].name} to ${
          ChainListMap[toChainId].name
        } with AnySwap`
        const crossChain = {
          bridge: SUPPORT_PLATFORM.ANYSWAP,
          fromChain: fromChainId,
          toChain: toChainId,
          toAddress: toAccount,
          fromTXHash: response.hash,
          pairID: moreData.pairID,
          value: amount.toSignificant(),
          symbol
        }
        addTransaction(response, {
          summary: summary,
          crossChain
        })
        addPopup(
          {
            txn: {
              hash: response.hash,
              success: false,
              summary,
              crossChain
            }
          },
          response.hash
        )
        return response.hash
      }

      checkoutContract(depositAddress)
      if (moreData.underlying) {
        if (isETHER) {
          const args = [moreData.underlying, toAccount, toChainId]
          return contract.estimateGas
            .anySwapOutNative(...args, { from: account, value: amount.raw.toString() })
            .then(estimatedGasLimit => {
              return contract
                .anySwapOutNative(...args, {
                  gasLimit: calculateGasMargin(estimatedGasLimit),
                  from: account,
                  value: amount.raw.toString()
                })
                .then(retResp)
            })
        } else {
          const args = [moreData.underlying, toAccount, amount.raw.toString(), toChainId]
          return contract.estimateGas.anySwapOutUnderlying(...args, { from: account }).then(estimatedGasLimit => {
            return contract
              .anySwapOutUnderlying(...args, {
                gasLimit: calculateGasMargin(estimatedGasLimit),
                from: account
              })
              .then(retResp)
          })
        }
      } else {
        const args = [moreData.underlying, toAccount, amount.raw.toString(), toChainId]
        return contract.estimateGas.anySwapOut(...args, { from: account }).then(estimatedGasLimit => {
          return contract
            .anySwapOut(...args, {
              gasLimit: calculateGasMargin(estimatedGasLimit),
              from: account
            })
            .then(retResp)
        })
      }
    },
    error: ''
  }
}

function makeCbridgeTransferId(
  account: string,
  tokenAddress: string,
  amount: string,
  toChainId: number,
  nonce: string,
  fromChainId: number
) {
  const transfer_id = ethers.utils.solidityKeccak256(
    ['address', 'address', 'address', 'uint256', 'uint64', 'uint64', 'uint64'],
    [
      account,
      account,
      tokenAddress,
      amount, /// send amount in String
      toChainId,
      nonce, /// same nonce as on-chain cBridge send transaction
      fromChainId
    ]
  )
  return transfer_id
}

function useCbridgeDepositeCallback(
  depositAddress: string
): {
  state: ContractCallbackState
  callback:
    | undefined
    | ((
        tokenAddress: string,
        toChainId: number,
        toAccount: string,
        amount: CurrencyAmount,
        isETHER: boolean,
        fromChainId: number,
        symbol: string,
        moreData: MoreDataProp
      ) => Promise<{ hash: string; transferId?: string; depositAddress?: string }>)
  error: string | null
} {
  const addTransaction = useTransactionAdder()
  const contract = useContract(depositAddress, CBRIDGE_ABI)
  const { account } = useActiveWeb3React()
  const addPopup = useAddPopup()

  return {
    state: ContractCallbackState.VALID,
    callback: async function onCall(
      tokenAddress: string,
      toChainId: number,
      toAccount: string,
      amount: CurrencyAmount,
      isETHER: boolean,
      fromChainId: number,
      symbol: string,
      moreData: MoreDataProp
    ): Promise<{ hash: string; transferId?: string; depositAddress?: string }> {
      if (!contract) {
        throw new Error('Unexpected error. Contract error')
      }
      if (!account) {
        throw new Error('Unexpected error. account')
      }
      if (!moreData.maxSlippage) {
        throw new Error('Unexpected error. maxSlippage')
      }
      checkoutContract(contract.address)

      const timestamp = (new Date().getTime() / 1000).toFixed()
      const cbridgeTransferId = makeCbridgeTransferId(
        account,
        tokenAddress,
        amount.raw.toString(),
        toChainId,
        timestamp,
        fromChainId
      )
      const retResp = (response: TransactionResponse) => {
        const summary = `Cross ${amount.toSignificant() + ' ' + symbol} from ${ChainListMap[fromChainId].name} to ${
          ChainListMap[toChainId].name
        } with Cbridge`
        const crossChain = {
          bridge: SUPPORT_PLATFORM.CBRIDGE,
          fromChain: fromChainId,
          toChain: toChainId,
          toAddress: toAccount,
          fromTXHash: response.hash,
          pairID: moreData.pairID,
          value: amount.toSignificant(),
          symbol,
          cbridgeTransferId,
          depositAddress
        }
        addTransaction(response, {
          summary: summary,
          crossChain
        })
        addPopup(
          {
            txn: {
              hash: response.hash,
              success: false,
              summary,
              crossChain
            }
          },
          response.hash
        )
        return {
          hash: response.hash,
          transferId: cbridgeTransferId,
          depositAddress
        }
      }
      if (isETHER) {
        const args = [toAccount, amount.raw.toString(), toChainId, timestamp, moreData.maxSlippage]
        return contract.estimateGas
          .sendNative(...args, { from: account, value: amount.raw.toString() })
          .then(estimatedGasLimit => {
            return contract
              .sendNative(...args, {
                gasLimit: calculateGasMargin(estimatedGasLimit),
                from: account,
                value: amount.raw.toString()
              })
              .then(retResp)
          })
      } else {
        const args = [toAccount, tokenAddress, amount.raw.toString(), toChainId, timestamp, moreData.maxSlippage]
        return contract.estimateGas.send(...args, { from: account }).then(estimatedGasLimit => {
          return contract
            .send(...args, {
              gasLimit: calculateGasMargin(estimatedGasLimit),
              from: account
            })
            .then(retResp)
        })
      }
    },
    error: ''
  }
}

function useBiconomyDepositeCallback(
  depositAddress: string
): {
  state: ContractCallbackState
  callback:
    | undefined
    | ((
        tokenAddress: string,
        toChainId: number,
        toAccount: string,
        amount: CurrencyAmount,
        isETHER: boolean,
        fromChainId: number,
        symbol: string,
        moreData: MoreDataProp
      ) => Promise<{ hash: string; transferId?: string; depositAddress?: string }>)
  error: string | null
} {
  const addTransaction = useTransactionAdder()
  const contract = useContract(depositAddress, BICONOMY_ABI)
  const { account } = useActiveWeb3React()
  const addPopup = useAddPopup()

  return {
    state: ContractCallbackState.VALID,
    callback: async function onCall(
      tokenAddress: string,
      toChainId: number,
      toAccount: string,
      amount: CurrencyAmount,
      isETHER: boolean,
      fromChainId: number,
      symbol: string
      // moreData: MoreDataProp
    ): Promise<{ hash: string; transferId?: string; depositAddress?: string }> {
      if (!contract) {
        throw new Error('Unexpected error. Contract error')
      }
      if (!account) {
        throw new Error('Unexpected error. account')
      }
      // checkoutContract(contract.address)

      // check status and address
      try {
        const statusRes = (await fetchBiconomySystemStatus(
          tokenAddress,
          amount.raw.toString(),
          fromChainId,
          toChainId,
          account
        )) as any
        if (statusRes.responseCode !== 200) {
          alert(statusRes.message)
          throw new Error(statusRes.data.message)
        }
        if (statusRes.depositContract.toLocaleLowerCase() !== depositAddress.toLocaleLowerCase()) {
          alert('depositAddress error')
          throw new Error('depositAddress error')
        }
      } catch (error) {
        console.error(error)
        alert('network error')
        throw new Error('network error')
      }

      const retResp = (response: TransactionResponse) => {
        const summary = `Cross ${amount.toSignificant() + ' ' + symbol} from ${ChainListMap[fromChainId].name} to ${
          ChainListMap[toChainId].name
        } with Biconomy`
        const crossChain = {
          bridge: SUPPORT_PLATFORM.BICONOMY,
          fromChain: fromChainId,
          toChain: toChainId,
          toAddress: toAccount,
          fromTXHash: response.hash,
          value: amount.toSignificant(),
          symbol,
          depositAddress
        }
        addTransaction(response, {
          summary: summary,
          crossChain
        })
        addPopup(
          {
            txn: {
              hash: response.hash,
              success: false,
              summary,
              crossChain
            }
          },
          response.hash
        )
        return {
          hash: response.hash,
          depositAddress
        }
      }
      if (isETHER) {
        const args = [toAccount, toChainId, 'Chainswap']
        return contract.estimateGas
          .depositNative(...args, { from: account, value: amount.raw.toString() })
          .then(estimatedGasLimit => {
            return contract
              .depositNative(...args, {
                gasLimit: calculateGasMargin(estimatedGasLimit),
                from: account,
                value: amount.raw.toString()
              })
              .then(retResp)
          })
      } else {
        const args = [toChainId, tokenAddress, toAccount, amount.raw.toString(), 'Chainswap']
        return contract.estimateGas.depositErc20(...args, { from: account }).then(estimatedGasLimit => {
          return contract
            .depositErc20(...args, {
              gasLimit: calculateGasMargin(estimatedGasLimit),
              from: account
            })
            .then(retResp)
        })
      }
    },
    error: ''
  }
}

function useStargateDepositeCallback(
  depositAddress: string
): {
  state: ContractCallbackState
  callback:
    | undefined
    | ((
        tokenAddress: string,
        toChainId: number,
        toAccount: string,
        amount: CurrencyAmount,
        isETHER: boolean,
        fromChainId: number,
        symbol: string,
        moreData: MoreDataProp
      ) => Promise<{ hash: string; transferId?: string; depositAddress?: string }>)
  error: string | null
} {
  const addTransaction = useTransactionAdder()
  const contract = useContract(depositAddress, STARGATE_ABI)
  const { account } = useActiveWeb3React()
  const addPopup = useAddPopup()

  return {
    state: ContractCallbackState.VALID,
    callback: async function onCall(
      tokenAddress: string,
      toChainId: number,
      toAccount: string,
      amount: CurrencyAmount,
      isETHER: boolean,
      fromChainId: number,
      symbol: string,
      moreData: MoreDataProp
    ): Promise<{ hash: string; transferId?: string; depositAddress?: string }> {
      if (!contract) {
        throw new Error('Unexpected error. Contract error')
      }
      if (!account) {
        throw new Error('Unexpected error. account')
      }
      if (!moreData.feeInt) {
        throw new Error('Unexpected error. fee')
      }
      if (isETHER) {
        throw new Error('Unsupported eth')
      }

      if (!STARGATE_MAPPING_CHAINID[toChainId]) {
        throw new Error('STARGATE_MAPPING_CHAINID. error ' + toChainId)
      }
      if (moreData.srcPoolId === undefined || moreData.dstPoolId === undefined || moreData.minReceive === undefined) {
        throw new Error('error moreData')
      }
      checkoutContract(contract.address)

      const retResp = (response: TransactionResponse) => {
        const summary = `Cross ${amount.toSignificant() + ' ' + symbol} from ${ChainListMap[fromChainId].name} to ${
          ChainListMap[toChainId].name
        } with Stargate (Please check the balance in your wallet in a few minutes)`
        const crossChain = {
          bridge: SUPPORT_PLATFORM.STARGATE,
          fromChain: fromChainId,
          toChain: toChainId,
          toAddress: toAccount,
          fromTXHash: response.hash,
          value: amount.toSignificant(),
          symbol,
          depositAddress
        }
        addTransaction(response, {
          summary: summary,
          crossChain
        })
        addPopup(
          {
            txn: {
              hash: response.hash,
              success: false,
              summary,
              crossChain
            }
          },
          response.hash
        )
        return {
          hash: response.hash,
          depositAddress
        }
      }
      const args = [
        STARGATE_MAPPING_CHAINID[toChainId],
        moreData.srcPoolId,
        moreData.dstPoolId,
        account,
        amount.raw.toString(),
        moreData.minReceive,
        [0, 0, account],
        account,
        '0x'
      ]

      return contract.estimateGas.swap(...args, { from: account, value: moreData.feeInt }).then(estimatedGasLimit => {
        if (!moreData.feeInt) return
        return contract
          .swap(...args, {
            gasLimit: calculateGasMargin(estimatedGasLimit),
            from: account,
            value: moreData.feeInt
          })
          .then(retResp)
      })
    },
    error: ''
  }
}

function useSymbiosisDepositeCallback(
  depositAddress: string
): {
  state: ContractCallbackState
  callback:
    | undefined
    | ((
        tokenAddress: string,
        toChainId: number,
        toAccount: string,
        amount: CurrencyAmount,
        isETHER: boolean,
        fromChainId: number,
        symbol: string,
        moreData: MoreDataProp
      ) => Promise<{ hash: string; transferId?: string; depositAddress?: string }>)
  error: string | null
} {
  const addTransaction = useTransactionAdder()
  const portalContract = useContract(depositAddress, Symbiosis_portal_ABI)
  const synthesisContract = useContract(depositAddress, Symbiosis_synthesis_ABI)
  const { account } = useActiveWeb3React()
  const addPopup = useAddPopup()

  return {
    state: ContractCallbackState.VALID,
    callback: async function onCall(
      tokenAddress: string,
      toChainId: number,
      toAccount: string,
      amount: CurrencyAmount,
      isETHER: boolean,
      fromChainId: number,
      symbol: string,
      moreData: MoreDataProp
    ): Promise<{ hash: string; transferId?: string; depositAddress?: string }> {
      if (moreData.bridgeSymbiosisDirection === undefined) {
        throw new Error('Unexpected error. bridgeSymbiosisDirection')
      }
      if (!moreData.feeInt) {
        throw new Error('Unexpected error. moreData.feeInt')
      }

      if (!portalContract || !synthesisContract) {
        throw new Error('Unexpected error. Contract error')
      }
      if (!account) {
        throw new Error('Unexpected error. account')
      }

      checkoutContract(synthesisContract.address)
      checkoutContract(portalContract.address)

      const clientId = utils.formatBytes32String('Chainswap')
      console.log('clientId', clientId, 'Chainswap')

      const retResp = (response: TransactionResponse) => {
        const summary = `Cross ${amount.toSignificant() + ' ' + symbol} from ${ChainListMap[fromChainId].name} to ${
          ChainListMap[toChainId].name
        } with Symbiosis (Please check the balance in your wallet in a few minutes)`
        const crossChain = {
          bridge: SUPPORT_PLATFORM.SYMBIOSIS,
          fromChain: fromChainId,
          toChain: toChainId,
          toAddress: toAccount,
          fromTXHash: response.hash,
          value: amount.toSignificant(),
          symbol,
          depositAddress
        }
        addTransaction(response, {
          summary: summary,
          crossChain
        })
        addPopup(
          {
            txn: {
              hash: response.hash,
              success: false,
              summary,
              crossChain
            }
          },
          response.hash
        )
        return {
          hash: response.hash,
          depositAddress
        }
      }

      if (moreData.bridgeSymbiosisDirection === BridgeSymbiosisDirection.BURN) {
        const args = [
          moreData.feeInt,
          tokenAddress,
          amount.raw.toString(),
          account,
          moreData.symbiosisDestPortalAddress,
          moreData.symbiosisDestBridgeAddress,
          account,
          toChainId,
          clientId
        ]
        return synthesisContract.estimateGas.burnSyntheticToken(...args, { from: account }).then(estimatedGasLimit => {
          if (!moreData.feeInt) return
          return synthesisContract
            .burnSyntheticToken(...args, {
              gasLimit: calculateGasMargin(estimatedGasLimit),
              from: account
            })
            .then(retResp)
        })
      }

      if (isETHER) {
        const args = [
          moreData.feeInt,
          account,
          moreData.symbiosisDestSynthesisAddress,
          moreData.symbiosisDestBridgeAddress,
          account,
          toChainId,
          clientId
        ]
        return portalContract.estimateGas
          .synthesizeNative(...args, { from: account, value: amount.raw.toString() })
          .then(estimatedGasLimit => {
            return portalContract
              .synthesizeNative(...args, {
                gasLimit: calculateGasMargin(estimatedGasLimit),
                from: account,
                value: amount.raw.toString()
              })
              .then(retResp)
          })
      }

      const args = [
        moreData.feeInt,
        tokenAddress,
        amount.raw.toString(),
        account,
        moreData.symbiosisDestSynthesisAddress,
        moreData.symbiosisDestBridgeAddress,
        account,
        toChainId,
        clientId
      ]

      return portalContract.estimateGas.synthesize(...args, { from: account }).then(estimatedGasLimit => {
        return portalContract
          .synthesize(...args, {
            gasLimit: calculateGasMargin(estimatedGasLimit),
            from: account
          })
          .then(retResp)
      })
    },
    error: ''
  }
}

export function useDepositeOnceCallback(platform?: SUPPORT_PLATFORM, contractAddress?: string) {
  const polyNetwork = usePolyNetworkDepositeCallback(contractAddress ?? '')
  const anyswapV1 = useAnyswapV1DepositeCallback(contractAddress ?? '')
  const anyswapV2 = useAnyswapV2DepositeCallback(contractAddress ?? '')
  const cbridge = useCbridgeDepositeCallback(contractAddress ?? '')
  const biconomy = useBiconomyDepositeCallback(contractAddress ?? '')
  const stargate = useStargateDepositeCallback(contractAddress ?? '')
  const symbiosis = useSymbiosisDepositeCallback(contractAddress ?? '')
  switch (platform) {
    case SUPPORT_PLATFORM.POLYNETWORK:
      return polyNetwork.callback
    case SUPPORT_PLATFORM.MULTICHAIN:
      return anyswapV1.callback
    case SUPPORT_PLATFORM.ANYSWAP:
      return anyswapV2.callback
    case SUPPORT_PLATFORM.CBRIDGE:
      return cbridge.callback
    case SUPPORT_PLATFORM.BICONOMY:
      return biconomy.callback
    case SUPPORT_PLATFORM.STARGATE:
      return stargate.callback
    case SUPPORT_PLATFORM.SYMBIOSIS:
      return symbiosis.callback
    default:
      return undefined
  }
}
