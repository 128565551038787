import { useEffect, useState } from 'react'
import { fetchAnyswapV1WithdrawResult, fetchPolyNetworkWithdrawResult } from '../utils/fetch/comfirmResult'
import { fetchCbridgeSwapResult } from '../utils/fetch/cbridge'
import { SUPPORT_PLATFORM } from 'models/platform'
import { useTimeIndex } from './useTimeIndex'
import { fetchBiconomyCheckStatus } from 'utils/fetch/biconomy'

export enum swapResultStatus {
  UNKNOWN,
  PENDING,
  SUCCESS,
  Fail
}
enum CbridgeWebTransferStatus {
  TRANSFER_UNKNOWN,
  TRANSFER_SUBMITTING,
  TRANSFER_FAILED,
  TRANSFER_WAITING_FOR_SGN_CONFIRMATION,
  TRANSFER_WAITING_FOR_FUND_RELEASE,
  TRANSFER_COMPLETED,
  TRANSFER_TO_BE_REFUNDED,
  TRANSFER_REQUESTING_REFUND,
  TRANSFER_REFUND_TO_BE_CONFIRMED,
  TRANSFER_CONFIRMING_YOUR_REFUND,
  TRANSFER_REFUNDED
}

const anyswapV1WithdrawFetchResult = async (
  toAddressValue: string,
  fromTXHash: string,
  destChainID: string,
  pairID: string,
  srcChainID: string
): Promise<swapResultStatus> => {
  try {
    const res: any = await fetchAnyswapV1WithdrawResult(toAddressValue, fromTXHash, destChainID, pairID, srcChainID)
    if (res?.msg === 'Success' && res.info?.status >= 10) {
      return swapResultStatus.SUCCESS
    } else if (res?.msg === 'Success' && res.info && res.info.status < 10) {
      return swapResultStatus.PENDING
    }
    return swapResultStatus.PENDING
  } catch (error) {
    return swapResultStatus.PENDING
  }
}

const polyNetworkWithdrawFetchResult = async (fromTXHash: string): Promise<swapResultStatus> => {
  try {
    const res: any = await fetchPolyNetworkWithdrawResult(fromTXHash.replace('0x', ''))
    if (
      res?.TransactionState.length === 3 &&
      res?.TransactionState[2].NeedBlocks !== 0 &&
      res?.TransactionState[2].Blocks >= res?.TransactionState[2].NeedBlocks
    ) {
      return swapResultStatus.SUCCESS
    } else if (res?.TransactionState) {
      return swapResultStatus.PENDING
    }
    return swapResultStatus.PENDING
  } catch (error) {
    return swapResultStatus.PENDING
  }
}

const cbridgeTXFetchResult = async (transferId: string) => {
  try {
    const res: any = await fetchCbridgeSwapResult(transferId)
    if (res.status === CbridgeWebTransferStatus.TRANSFER_COMPLETED) {
      return swapResultStatus.SUCCESS
    }
    if (
      res.status === CbridgeWebTransferStatus.TRANSFER_FAILED ||
      res.status === CbridgeWebTransferStatus.TRANSFER_TO_BE_REFUNDED ||
      res.status === CbridgeWebTransferStatus.TRANSFER_REFUNDED
    ) {
      return swapResultStatus.Fail
    }
    return swapResultStatus.PENDING
  } catch (error) {
    return swapResultStatus.UNKNOWN
  }
}

const biconomyTxResult = async (fromChainId: number, hash: string) => {
  try {
    const res = (await fetchBiconomyCheckStatus(fromChainId, hash)) as any
    if (res.statusCode === 2) {
      return swapResultStatus.SUCCESS
    }
    if (res.statusCode === 0) {
      return swapResultStatus.Fail
    }
    return swapResultStatus.PENDING
  } catch (error) {
    return swapResultStatus.PENDING
  }
}

export function useChainSwapResult(
  type: SUPPORT_PLATFORM,
  toAddressValue: string,
  fromTXHash: string,
  destChainID: string,
  pairID: string,
  srcChainID: string,
  transferId?: string // cbridge
) {
  const [result, setResult] = useState<swapResultStatus>(swapResultStatus.UNKNOWN)
  const [loading, setLoading] = useState(true)
  const timeIndex = useTimeIndex(30)

  useEffect(() => {
    ;(async () => {
      if (type === SUPPORT_PLATFORM.ANYSWAP) {
        setResult(swapResultStatus.UNKNOWN)
        return
      }
      if (
        type === SUPPORT_PLATFORM.MULTICHAIN &&
        (!destChainID || !fromTXHash || !pairID || !srcChainID || !toAddressValue)
      ) {
        setResult(swapResultStatus.UNKNOWN)
        return
      }
      if (type === SUPPORT_PLATFORM.POLYNETWORK && !fromTXHash) {
        setResult(swapResultStatus.UNKNOWN)
        return
      }
      if (type === SUPPORT_PLATFORM.CBRIDGE && !transferId) {
        setResult(swapResultStatus.UNKNOWN)
        return
      }

      if (result === swapResultStatus.SUCCESS || result === swapResultStatus.Fail) return
      switch (type) {
        case SUPPORT_PLATFORM.MULTICHAIN:
          const a1 = await anyswapV1WithdrawFetchResult(toAddressValue, fromTXHash, destChainID, pairID, srcChainID)
          setResult(a1)
          break
        case SUPPORT_PLATFORM.POLYNETWORK:
          const a2 = await polyNetworkWithdrawFetchResult(fromTXHash)
          setResult(a2)
          break
        case SUPPORT_PLATFORM.CBRIDGE:
          if (!transferId) {
            setResult(swapResultStatus.UNKNOWN)
            return
          }
          const r = await cbridgeTXFetchResult(transferId)
          setResult(r)
          break
        case SUPPORT_PLATFORM.BICONOMY:
          if (!srcChainID || !fromTXHash) {
            setResult(swapResultStatus.UNKNOWN)
            return
          }
          const _r = await biconomyTxResult(Number(srcChainID), fromTXHash)
          setResult(_r)
          break
        default:
          setResult(swapResultStatus.UNKNOWN)
          break
      }

      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destChainID, fromTXHash, pairID, srcChainID, toAddressValue, type, timeIndex])

  return {
    loading,
    result
  }
}

export enum CbridgeResultStatusProp {
  NORMAL_TRANSFER,
  TRANSFER_SUCCESS,
  NEED_REFUND,
  REFUND_PENDING,
  REFUNDED
}
export interface CbridgeSwapResultProp {
  status: CbridgeResultStatusProp
  wdOnchain?: string | null
  sigs?: any[]
  signers?: string[]
  powers?: string[]
}

export function useCbridgeTXFetchDescResult(transferId: string) {
  const timeIndex = useTimeIndex(20)
  const [result, setResult] = useState<CbridgeSwapResultProp | undefined>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (!transferId) return
      if (
        result?.status === CbridgeResultStatusProp.TRANSFER_SUCCESS ||
        result?.status === CbridgeResultStatusProp.REFUNDED
      ) {
        return
      }
      try {
        setLoading(true)
        const res: any = await fetchCbridgeSwapResult(transferId)
        let status = CbridgeResultStatusProp.NORMAL_TRANSFER
        if (res.status === CbridgeWebTransferStatus.TRANSFER_TO_BE_REFUNDED) {
          status = CbridgeResultStatusProp.NEED_REFUND
        } else if (
          [
            CbridgeWebTransferStatus.TRANSFER_REQUESTING_REFUND,
            CbridgeWebTransferStatus.TRANSFER_REFUND_TO_BE_CONFIRMED,
            CbridgeWebTransferStatus.TRANSFER_CONFIRMING_YOUR_REFUND
          ].includes(res.status)
        ) {
          status = CbridgeResultStatusProp.REFUND_PENDING
        } else if (res.status === CbridgeWebTransferStatus.TRANSFER_REFUNDED) {
          status = CbridgeResultStatusProp.REFUNDED
        } else if (res.status === CbridgeWebTransferStatus.TRANSFER_COMPLETED) {
          status = CbridgeResultStatusProp.TRANSFER_SUCCESS
        }
        setResult({
          status,
          wdOnchain: res.wd_onchain,
          sigs: res.sigs,
          signers: res.signers,
          powers: res.powers
        })
      } catch (error) {
        setResult(undefined)
      }
      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferId, timeIndex])

  return {
    loading,
    result
  }
}
