import { useCallback, MutableRefObject } from 'react'
import { FixedSizeList } from 'react-window'
import { Box, Typography, styled } from '@mui/material'
import { Mode } from './SelectCurrencyModal'
import useModal from 'hooks/useModal'
import { RangoToken } from '../../../utils/fetch/swap'
import { RangoTokenLogo } from '../../essential/CurrencyLogo'

interface Props {
  selectedToken?: RangoToken | null
  mode?: Mode
  onSelectToken: (token: RangoToken) => void
  rangoTokenOptions: RangoToken[]
  fixedListRef?: MutableRefObject<FixedSizeList | undefined>
}

const ListItem = styled('div')({
  display: 'flex',
  cursor: 'pointer',
  padding: '0 32px',
  height: '48px',
  justifyContent: 'space-between'
})

export default function RangoTokenList({ selectedToken, mode, onSelectToken, rangoTokenOptions, fixedListRef }: Props) {
  const { hideModal } = useModal()

  const tokenKey = useCallback((token: RangoToken, index: number): string => {
    return token ? token.symbol + token.name || index.toString() : index.toString()
  }, [])

  const itemKey = useCallback((index: number, data: RangoToken[]) => tokenKey(data[index], index), [tokenKey])

  const Row = useCallback(
    ({ data, index, style }: any) => {
      const token: RangoToken = data[index]
      const onClickCurrency = () => {
        token && onSelectToken(token)
        hideModal()
      }

      return (
        <ListItem
          style={{ ...style, opacity: selectedToken !== token ? 1 : 0.5 }}
          onClick={mode === Mode.SELECT && selectedToken !== token ? onClickCurrency : () => {}}
        >
          <Box display="flex">
            <RangoTokenLogo token={token} size={'24px'} />
            <Box display="flex" flexDirection="column" marginLeft="16px">
              <Typography variant="inherit">{token.symbol}</Typography>
              <Typography variant="caption">{token.name}</Typography>
            </Box>
          </Box>
          {/* {mode === Mode.SELECT && <span style={{ fontWeight: 500 }}>{0}</span>} */}
        </ListItem>
      )
    },
    [mode, selectedToken, onSelectToken, hideModal]
  )

  return (
    <FixedSizeList
      ref={fixedListRef as any}
      height={290}
      width="100%"
      itemCount={rangoTokenOptions.length}
      itemSize={48}
      itemData={rangoTokenOptions}
      itemKey={itemKey}
    >
      {Row}
    </FixedSizeList>
  )
}
