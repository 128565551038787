import { Alert, Link } from '@mui/material'
import { SUPPORT_PLATFORM } from 'models/platform'

export default function SlippageAlert({ platform }: { platform?: SUPPORT_PLATFORM }) {
  if (platform === SUPPORT_PLATFORM.CBRIDGE || platform === SUPPORT_PLATFORM.STARGATE)
    return (
      <Alert
        severity="warning"
        sx={{
          mt: 10,
          '&>*': {
            padding: 0
          }
        }}
      >
        Low slippage may lead to failed transactions
      </Alert>
    )

  return null
}

export function NoticeAlert({ platform }: { platform?: SUPPORT_PLATFORM }) {
  if (platform === SUPPORT_PLATFORM.SYMBIOSIS)
    return (
      <Alert
        severity="warning"
        sx={{
          mt: 10,
          '&>*': {
            padding: 0
          }
        }}
      >
        Symbiosis uses sTokens to implement cross-chain swaps. Thus, when you get sTokens you make cross-chain swaps
        possible; in other words, you bridge blockchains. Please refer to the{' '}
        <Link target="_blank" href="https://docs.symbiosis.finance/user-guide-webapp/dealing-with-stokens">
          documentation
        </Link>{' '}
        for more information
      </Alert>
    )

  return null
}
