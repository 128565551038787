import { Typography, Box, StepLabel } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Spinner from 'components/Spinner'
import BridgeLogo from 'components/BridgeLogo'
import DymmyCurrencyLogo from 'assets/images/ethereum-logo.png'
import {
  RangoTransactionsStatusProp,
  RangoTransactionSwaps,
  RangoTransactionSwapStatus
} from 'state/rangoTransactions/actions'
import { useCallback, useEffect, useState } from 'react'
import { useActiveWeb3React } from 'hooks'
import { stepSwapTransfer } from '../SwapFunction'
import { useWeb3Instance } from 'hooks/useWeb3Instance'
import CancelIcon from '@mui/icons-material/Cancel'
import SmallButton from 'components/Button/SmallButton'
import { useUpdateTransactionsStatusAndStep } from 'state/rangoTransactions/hooks'
import BigNumber from 'bignumber.js'
import { Swapper } from 'utils/fetch/swap'

interface StepperItemProp {
  labelWidth: number
  transData: RangoTransactionSwaps
  stepCount: number
  currentTransStep: number
  selfStep: number
  requestId: string
  slippage: string
  globalStatus: RangoTransactionsStatusProp
  swappers: Swapper[] | undefined
}

export default function StepperItem({
  labelWidth,
  transData,
  stepCount,
  selfStep,
  currentTransStep,
  requestId,
  slippage,
  swappers,
  globalStatus
}: StepperItemProp) {
  const { chainId, library, account } = useActiveWeb3React()
  const web3 = useWeb3Instance()
  const [message, setMessage] = useState('')
  const [runningTag, setRunningTag] = useState(false)
  const updateTransactionsStatusAndStep = useUpdateTransactionsStatusAndStep()

  useEffect(() => {
    if (
      window.ethereum &&
      window.ethereum.request &&
      window.ethereum.removeListener &&
      window.ethereum.on &&
      web3 &&
      chainId &&
      library &&
      account &&
      stepCount &&
      runningTag === false &&
      selfStep === currentTransStep &&
      globalStatus === RangoTransactionsStatusProp.ACTIVATING &&
      (transData.stepStatus === RangoTransactionSwapStatus.PENDING ||
        transData.stepStatus === RangoTransactionSwapStatus.RUNNING)
    ) {
      const _ethereum = {
        removeListener: window.ethereum.removeListener,
        request: window.ethereum.request,
        on: window.ethereum.on
      }
      stepSwapTransfer(
        library,
        web3,
        _ethereum,
        account,
        transData,
        requestId,
        currentTransStep,
        stepCount,
        slippage || '1',
        (v: string) => setMessage(v)
      )
      setRunningTag(true)
    }
  }, [
    account,
    stepCount,
    chainId,
    currentTransStep,
    library,
    requestId,
    runningTag,
    selfStep,
    slippage,
    transData,
    transData.stepStatus,
    web3,
    globalStatus
  ])

  const retry = useCallback(() => {
    setRunningTag(true)
    if (
      window.ethereum &&
      window.ethereum.request &&
      window.ethereum.removeListener &&
      window.ethereum.on &&
      web3 &&
      chainId &&
      library &&
      account &&
      stepCount &&
      selfStep === currentTransStep &&
      globalStatus !== RangoTransactionsStatusProp.FAILED &&
      (transData.stepStatus === RangoTransactionSwapStatus.PENDING ||
        transData.stepStatus === RangoTransactionSwapStatus.RUNNING)
    ) {
      const _ethereum = {
        removeListener: window.ethereum.removeListener,
        request: window.ethereum.request,
        on: window.ethereum.on
      }

      updateTransactionsStatusAndStep(requestId, account, RangoTransactionsStatusProp.ACTIVATING, undefined)

      stepSwapTransfer(
        library,
        web3,
        _ethereum,
        account,
        transData,
        requestId,
        currentTransStep,
        stepCount,
        slippage || '1',
        (v: string) => setMessage(v)
      )
    }
  }, [
    account,
    chainId,
    currentTransStep,
    globalStatus,
    library,
    requestId,
    selfStep,
    slippage,
    stepCount,
    transData,
    updateTransactionsStatusAndStep,
    web3
  ])

  const swapper = swappers?.find(swapper => {
    return swapper.id === transData.swapperId
  })

  return (
    <Box display="flex">
      <StepLabel
        sx={{
          position: 'relative',
          padding: 0,
          '& .MuiStepLabel-iconContainer': {
            padding: 0
          },
          height: 'fit-content',
          alignItems: 'flex-start',
          width: '100%'
        }}
        StepIconProps={{
          icon: (
            <Box display="flex" alignItems="flex-start" gap={16}>
              <Box
                sx={{
                  width: labelWidth,
                  display: 'flex',
                  justifyContent: 'center',
                  background: 'transparent',
                  zIndex: 1
                }}
              >
                <BridgeLogo size={labelWidth} src={swapper?.logo ?? DymmyCurrencyLogo} alt={``} />
              </Box>
            </Box>
          )
        }}
      >
        <Box
          sx={{
            height: '100%',
            //last?
            borderLeft: stepCount === currentTransStep ? 'none' : '1px dashed rgba(0, 0, 0, 0.2)',
            position: 'absolute',
            left: labelWidth ? labelWidth / 2 : 0,
            top: 0
          }}
        />
        <Box pl={8} display="flex" justifyContent="space-between" position="relative" gap={'6px'}>
          <Box>
            <Typography fontSize={12} fontWeight={500}>
              {transData.swapperId}
            </Typography>
            <Typography fontSize={12} color="rgba(219, 132, 3, 1)" lineHeight={1.2}>
              {message}
            </Typography>
          </Box>
          {globalStatus === RangoTransactionsStatusProp.CANCEL && selfStep === currentTransStep ? (
            <SmallButton onClick={retry}>Retry</SmallButton>
          ) : (
            <>
              {transData.stepStatus === RangoTransactionSwapStatus.SUCCESS && (
                <Box display={'flex'} alignItems={'center'}>
                  <Typography>
                    {new BigNumber(transData.stepOutputAmount || 0).toFixed(2)}
                    {transData.toAssets.symbol}
                  </Typography>
                  <CheckCircleIcon sx={{ color: '#3AC261' }} />
                </Box>
              )}
              {transData.stepStatus === RangoTransactionSwapStatus.PENDING && (
                <Typography fontSize={12} sx={{ opacity: 0.5 }}>
                  Pending...
                </Typography>
              )}
              {transData.stepStatus === RangoTransactionSwapStatus.RUNNING && (
                <Box display={'flex'} alignItems={'center'}>
                  <Typography>
                    {new BigNumber(transData.stepOutputAmount || 0).toFixed(2)}
                    {transData.toAssets.symbol}
                  </Typography>
                  <Spinner size={20} />
                </Box>
              )}
              {transData.stepStatus === RangoTransactionSwapStatus.FAILED && <CancelIcon sx={{ color: 'red' }} />}
            </>
          )}
        </Box>
      </StepLabel>
    </Box>
  )
}
