import { useState, useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import { Box, AppBar, styled, useTheme } from '@mui/material'
import ChainSwap from '../../assets/svg/chain_swap.svg'
import { ShowOnMobile } from 'theme/index'
import Image from 'components/Image'
import TextButton from 'components/Button/TextButton'
import MobileMenu from './MobileMenu'
import { Close } from '@mui/icons-material'
import { ReactComponent as MenuIcon } from 'assets/componentsIcon/menu_icon.svg'

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'relative',
  height: theme.height.mobileHeader,
  backgroundColor: theme.palette.background.default,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxShadow: 'none',
  padding: '16px 18px 16px 16px',
  borderBottom: `1px solid rgba(0,0,0,0.1)`,
  zIndex: theme.zIndex.modal + 1
}))

const MainLogo = styled(NavLink)({
  lineHeight: 0,
  '& img': {
    width: 136,
    height: 34.7
  },
  '&:hover': {
    cursor: 'pointer'
  }
})

export default function MobileHeader() {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleDismiss = useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <>
      <MobileMenu
        isOpen={isOpen}
        onDismiss={() => {
          setIsOpen(false)
        }}
      />
      <ShowOnMobile breakpoint="md">
        <StyledAppBar>
          <Box display="flex" alignItems="center">
            <MainLogo id={'chainswap'} to={'/'}>
              <Image src={ChainSwap} alt={'chainswap'} />
            </MainLogo>
          </Box>
          {isOpen ? (
            <TextButton onClick={handleDismiss}>{<Close sx={{ color: theme.palette.primary.main }} />}</TextButton>
          ) : (
            <TextButton onClick={handleClick}>{<MenuIcon />}</TextButton>
          )}
        </StyledAppBar>
      </ShowOnMobile>
    </>
  )
}

// function Accordion({ children, placeholder }: { children: React.ReactNode; placeholder: string }) {
//   const [isOpen, setIsOpen] = useState(false)
//   const handleClick = useCallback(() => {
//     setIsOpen(state => !state)
//   }, [])
//   return (
//     <>
//       <Box sx={navLinkSx} display="flex" alignItems="center" gap={12} onClick={handleClick}>
//         {placeholder}{' '}
//         <ExpandMore
//           sx={{
//             transform: isOpen ? 'rotate(180deg)' : ''
//           }}
//         />
//       </Box>
//       <Box padding="0 15px"> {isOpen && children}</Box>
//     </>
//   )
// }
