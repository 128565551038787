import { ChangeEvent } from 'react'
import { Box, useTheme, Button, Typography } from '@mui/material'
import NumericalInput from 'components/Input/InputNumerical'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InputLabel from 'components/Input/InputLabel'
import useBreakpoint from 'hooks/useBreakpoint'
import { RangoTokenLogo } from 'components/essential/CurrencyLogo'
import { RangoToken } from '../../../utils/fetch/swap'

interface Props {
  value: string
  onClickSelect?: () => void
  selectedCurrency?: RangoToken
  inputDisabled?: boolean
  onChangeInput?: (e: ChangeEvent<HTMLInputElement>) => void
  width?: string | number
  label?: string
  balance?: string
  unit?: string
  onMax?: () => void
}

interface RangoProps {
  value: string
  onClickSelect?: () => void
  selectedToken?: RangoToken
  inputDisabled?: boolean
  onChangeInput?: (e: ChangeEvent<HTMLInputElement>) => void
}

export default function CurrencyInput(props: Props) {
  const theme = useTheme()
  const isDownMd = useBreakpoint('md')

  const {
    value,
    onClickSelect,
    selectedCurrency,
    inputDisabled,
    onChangeInput,
    width,
    label,
    balance,
    unit,
    onMax
  } = props

  return (
    <Box width={width ?? '100%'}>
      <Box display="flex" justifyContent="space-between">
        {label && <InputLabel>{label}</InputLabel>}
        {balance && (
          <Typography fontSize={12} sx={{ opacity: 0.5 }}>
            Available: {balance} {unit ?? ''}
          </Typography>
        )}
      </Box>

      <Box
        borderRadius="16px"
        bgcolor={theme.palette.background.default}
        display="flex"
        gap={20}
        sx={{ padding: { xs: '20px 20px', md: '21px 24px' }, flexDirection: { xs: 'column', md: 'row' } }}
      >
        <Box display="flex" alignItems="center">
          <Button
            onClick={onClickSelect}
            sx={{
              fontWeight: 400,
              fontSize: 16,
              transition: '.3s',
              width: {
                xs: '100%',
                md: 150
              },
              justifyContent: 'space-between'
            }}
            endIcon={<ExpandMoreIcon />}
          >
            <Box display="flex" gap={12} alignItems="center">
              {selectedCurrency ? (
                <>
                  <RangoTokenLogo token={selectedCurrency} size="40px" />
                  <Box>
                    <Typography fontWeight={500}>{selectedCurrency.symbol}</Typography>
                    <Typography
                      maxWidth={80}
                      display={'inline-block'}
                      whiteSpace={'nowrap'}
                      overflow={'hidden'}
                      textOverflow={'ellipsis'}
                      fontSize={12}
                      color={theme.palette.text.primary}
                      sx={{ opacity: 0.5 }}
                    >
                      {selectedCurrency.name}
                    </Typography>
                  </Box>
                </>
              ) : (
                <Typography fontSize={16} sx={{ opacity: 0.5 }} component="div">
                  Select a Token
                </Typography>
              )}
            </Box>
          </Button>
        </Box>

        <NumericalInput
          value={value}
          maxWidth={isDownMd ? '100%' : 218}
          disabled={inputDisabled}
          onChange={onChangeInput}
          onMax={onMax}
        />
      </Box>
    </Box>
  )
}

export function TokenInput(props: RangoProps) {
  const theme = useTheme()
  const { value, onClickSelect, selectedToken, inputDisabled, onChangeInput } = props

  return (
    <Box
      borderRadius="16px"
      width="100%"
      bgcolor={theme.palette.background.default}
      display="flex"
      gap={20}
      sx={{ padding: { xs: '20px 20px', md: '32px 24px' }, flexDirection: { xs: 'column', md: 'row' } }}
    >
      <Button
        onClick={onClickSelect}
        sx={{
          fontWeight: 400,
          fontSize: 16,
          transition: '.3s'
        }}
        endIcon={<ExpandMoreIcon />}
        fullWidth
      >
        <Box display="flex" gap={12} alignItems="center" width="100%">
          {selectedToken ? (
            <>
              <RangoTokenLogo token={selectedToken} size="40px" />
              <Box>
                <Typography fontWeight={500}>{selectedToken.symbol}</Typography>
                <Typography fontSize={12} color={theme.palette.text.primary} sx={{ opacity: 0.5 }}>
                  {selectedToken.name}
                </Typography>
              </Box>
            </>
          ) : (
            'Selet a Token'
          )}
        </Box>
      </Button>
      <NumericalInput value={value} maxWidth={248} disabled={inputDisabled} onChange={onChangeInput} />
    </Box>
  )
}
