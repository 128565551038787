import { useState, useMemo, useCallback, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import ActionButton from 'components/Button/ActionButton'
import ConfirmSwapModal from './ConfirmSwapModal'
// import TransactionPendingModal from 'components/Modal/TransactionModals/TransactionPendingModal'
import SwapForm from './SwapForm'
import Divider from 'components/Divider'
import Jounery from './Jounery'
import { useBestRouting, useFetchRangoData } from 'hooks/useFetchBaseData'
import useModal from 'hooks/useModal'
import Card from 'components/Card'
import { Blockchain, RangoToken } from '../../utils/fetch/swap'
import SelectRangoTokenModal from '../../components/Input/CurrencyInputPanel/SelectRangoTokenModal'
import { useActiveWeb3React } from '../../hooks'
import { SUPPORTED_NETWORKS } from '../../constants/chain'
import { useWalletModalToggle } from '../../state/application/hooks'
import { triggerSwitchChain } from '../../utils/triggerSwitchChain'
import { Currency, ETHER, Token } from '../../constants/token'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import BigNumber from 'bignumber.js'
// import MockCommit from './MockCommit'

enum SWAP_ERROR {
  ENTER_AMOUNT = 'Enter Amount'
}

export default function Swap() {
  const { showModal, hideModal } = useModal()
  const [fromChain, setFromChain] = useState<Blockchain | null>(null)
  const [toChain, setToChain] = useState<Blockchain | null>(null)
  const [fromCurrency, setFromCurrency] = useState<RangoToken | undefined>()
  const [toCurrency, setToCurrency] = useState<RangoToken | undefined>()
  const [fromAmount, setFromAmount] = useState('')
  const [toAmount, setToAmount] = useState('0.0')

  const toggleWalletModal = useWalletModalToggle()
  const { account, chainId, library } = useActiveWeb3React()

  //const [params, setParams] = useState<RangoBsetRoutingReq | undefined>()

  const fromToken: Token | Currency | undefined = useMemo(() => {
    if (fromCurrency && chainId && chainId?.toString() === fromChain?.network?.chainNum.toString()) {
      return !fromCurrency.address ? ETHER : new Token(chainId, fromCurrency.address, fromCurrency.decimals)
    }
    return undefined
  }, [chainId, fromChain?.network?.chainNum, fromCurrency])

  const isValidFromAmount = useMemo(() => {
    if (new BigNumber(fromAmount).isNaN() || new BigNumber(fromAmount).isLessThanOrEqualTo(0)) {
      return false
    }
    return true
  }, [fromAmount])

  const fromBalance = useCurrencyBalance(account ?? undefined, fromToken)
  const bestRoutingParams = useMemo(() => {
    if (!fromCurrency || !toCurrency || !fromAmount) return undefined
    if (!isValidFromAmount) {
      return undefined
    }
    return {
      affiliateRef: 'SEelXB',
      amount: fromAmount,
      blockchains: [],
      checkPrerequisites: false,
      disableMultiStepTx: false,
      connectedWallets: [],
      from: {
        address: fromCurrency.address,
        blockchain: fromCurrency.blockchain,
        symbol: fromCurrency.symbol
      },
      selectedWallets: {},
      swappers: [],
      to: {
        address: toCurrency.address,
        blockchain: toCurrency.blockchain,
        symbol: toCurrency.symbol
      },
      transactionTypes: ['EVM']
    }
  }, [fromAmount, fromCurrency, isValidFromAmount, toCurrency])

  const { result: rangoData } = useFetchRangoData()
  const { result: bestRouting, loading } = useBestRouting(bestRoutingParams)

  const [fromChainList, toChainList] = useMemo(() => {
    if (!rangoData?.blockchains) return [[], []]
    return [rangoData.blockchains, rangoData.blockchains]
  }, [rangoData])

  const [fromTokenList, toTokenList] = useMemo(() => {
    if (!rangoData?.tokens) return [[], []]
    return [
      rangoData.tokens.filter(token => {
        return token.blockchain === fromChain?.name
      }),
      rangoData.tokens.filter(token => {
        return token.blockchain === toChain?.name
      })
    ]
  }, [rangoData, fromChain, toChain])

  console.log('bestRouting', bestRouting)
  useEffect(() => {
    if (!fromChain || !toChain || !fromAmount) {
      return setToAmount('0.0')
    }
  }, [fromChain, toChain, fromAmount])

  // const fromChainList = useMemo(() => {
  //   return ChainList.filter(chain => chain.id !== toChain?.id)
  // }, [toChain])

  // const toChainList = useMemo(() => {
  //   return ChainList.filter(chain => chain.id !== fromChain?.id)
  // }, [fromChain])

  const getError = useMemo(() => {
    if (!fromAmount) {
      return SWAP_ERROR.ENTER_AMOUNT
    }

    return undefined
  }, [fromAmount])

  const onSwitch = useCallback(() => {
    if (!fromChain || !toChain) {
      return
    }

    setFromCurrency(toCurrency)
    setToCurrency(fromCurrency)
    setFromChain(toChain)
    setToChain(fromChain)
  }, [toChain, fromChain, toCurrency, fromCurrency])

  const isEnoughBalance = useMemo(() => {
    if (!fromBalance || new BigNumber(fromBalance.toSignificant()).isLessThan(fromAmount || 0)) {
      return false
    }
    return true
  }, [fromAmount, fromBalance])

  const btnStatus = useMemo(() => {
    const status = {
      disabled: true,
      text: ''
    }
    if (!fromChain) {
      return { ...status, text: 'Please select from chain' }
    }
    if (!toChain) {
      return { ...status, text: 'Please select to chain' }
    }
    if (!fromCurrency) {
      return { ...status, text: 'Please select from asset' }
    }
    if (!toCurrency) {
      return { ...status, text: 'Please select to asset' }
    }
    if (!isValidFromAmount) {
      return { ...status, text: 'Please enter amount' }
    }
    if (!account) {
      return { ...status, disabled: false, text: 'Connect to wallet' }
    }
    if (chainId && fromChain.network?.chainNum && chainId.toString() !== fromChain?.network?.chainNum.toString()) {
      return {
        ...status,
        disabled: false,
        text: `Switch to ${SUPPORTED_NETWORKS[fromChain.network?.chainNum]?.chainName}`
      }
    }
    if (!isEnoughBalance) {
      return {
        ...status,
        disabled: true,
        text: `Insufficient balance`
      }
    }
    if (!bestRouting || !bestRouting.result) {
      return { ...status, text: 'No routes found' }
    }
    return { ...status, disabled: false, text: 'confirm' }
  }, [account, bestRouting, chainId, fromChain, fromCurrency, isEnoughBalance, isValidFromAmount, toChain, toCurrency])

  const onMax = useCallback(() => {
    setFromAmount(fromBalance?.toSignificant() ?? '0')
  }, [fromBalance])

  const onSwap = useCallback(() => {
    if (!account) {
      toggleWalletModal()
      return
    }
    if (chainId && fromChain?.network?.chainNum && chainId.toString() !== fromChain?.network?.chainNum.toString()) {
      triggerSwitchChain(library, fromChain?.network?.chainNum, account)
      return
    }
    if (!fromCurrency || !toCurrency || !fromAmount || !toAmount || !fromChain || !toChain) {
      return
    }

    bestRoutingParams &&
      showModal(
        <ConfirmSwapModal
          fromCurrency={fromCurrency}
          toCurrency={toCurrency}
          fromAmount={fromAmount}
          toAmount={bestRouting?.result?.outputAmount ?? ''}
          routeParams={bestRoutingParams}
          fee={'0.005987'}
          priceImpact={'-1.02%'}
          allowedSlippage={'0.50%'}
          onCancel={() => hideModal()}
          swaps={bestRouting?.result?.swaps ?? []}
          resultId={bestRouting?.requestId ?? undefined}
        />
      )
  }, [
    account,
    chainId,
    fromChain,
    fromCurrency,
    toCurrency,
    fromAmount,
    toAmount,
    toChain,
    bestRoutingParams,
    showModal,
    bestRouting?.result?.outputAmount,
    bestRouting?.result?.swaps,
    bestRouting?.requestId,
    toggleWalletModal,
    library,
    hideModal
  ])

  const mapData = useMemo(() => {
    return {
      perPrice: fromCurrency?.usdPrice ?? '--',
      estimatedTime:
        bestRouting?.result?.swaps
          .map(({ estimatedTimeInSeconds }) => {
            return estimatedTimeInSeconds
          })
          .reduce(function(prev, curr) {
            return prev + curr
          }) ?? '--'
    }
  }, [bestRouting?.result?.swaps, fromCurrency?.usdPrice])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: 24,
          width: { xs: '100%', md: 1192 },
          padding: { xs: '0 16px', md: 0 }
        }}
      >
        <Box width="100%" maxWidth={780}>
          <Card grey>
            <Box
              maxWidth={'100%'}
              display="flex"
              flexDirection="column"
              gap="32px"
              sx={{ padding: { xs: '32px 20px 30px', md: '24px 60px 40px' } }}
              minHeight={682}
            >
              <Typography fontSize={28} fontWeight={700}>
                Swap
              </Typography>
              <SwapForm
                fromChainList={fromChainList}
                fromChain={fromChain}
                setFromChain={(chain: Blockchain) => {
                  setFromCurrency(undefined)
                  setFromChain(chain as Blockchain)
                }}
                fromAmount={fromAmount}
                onSelectFromCurrency={() => {
                  fromTokenList.length &&
                    showModal(
                      <SelectRangoTokenModal
                        onSelectToken={token => setFromCurrency(token as RangoToken)}
                        tokenList={fromTokenList}
                      />
                    )
                }}
                onChangeCurrencyAmount={e => {
                  setFromAmount(e.target.value || '')
                }}
                fromCurrency={fromCurrency}
                fromBalance={fromBalance?.toSignificant() ?? ''}
                unit=""
                onMax={onMax}
                onSwitch={onSwitch}
                toChainList={toChainList}
                toChain={toChain}
                setToChain={(chain: Blockchain) => setToChain(chain as Blockchain)}
                toAmount={bestRouting?.result?.outputAmount ?? ''}
                onSelectToCurrency={() => {
                  toTokenList.length &&
                    showModal(
                      <SelectRangoTokenModal
                        onSelectToken={token => setToCurrency(token as RangoToken)}
                        tokenList={toTokenList}
                      />
                    )
                }}
                toCurrency={toCurrency}
              />
              <Box>
                <ActionButton
                  pending={loading && isEnoughBalance && isValidFromAmount}
                  pendingText={'Loading'}
                  actionText={btnStatus.text}
                  onAction={onSwap}
                  error={btnStatus.disabled ? btnStatus.text : undefined}
                />
                <Typography fontSize={10} sx={{ opacity: 0.5 }}>
                  If no routes found, you can try to modify the amount or try again.
                </Typography>
              </Box>
            </Box>
          </Card>
        </Box>

        <Box width="100%" maxWidth={380}>
          <Card grey>
            <Box
              width="100%"
              sx={{
                padding: {
                  xs: '32px 20px',
                  md: '24px 52px'
                },
                minHeight: {
                  xs: 0,
                  md: 682
                }
              }}
            >
              <Typography fontSize={28} fontWeight={700} mb={32}>
                Journey Map
              </Typography>
              {getError ? (
                <Typography>Enter the amount to begin the journey!</Typography>
              ) : (
                <>
                  <Box display="flex" flexDirection="column" gap={12} mb={20}>
                    {Object.keys(mapData).map((key, idx) => (
                      <Box key={idx} display="flex" justifyContent="space-between">
                        <Typography fontSize={12} fontWeight={400}>
                          {key}:
                        </Typography>
                        <Typography fontSize={12} fontWeight={500}>
                          {mapData[key as keyof typeof mapData]}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  <Divider />
                  <Box mt={20}>
                    {bestRouting?.result?.swaps ? (
                      <Jounery swappers={rangoData?.swappers} steps={bestRouting?.result?.swaps} />
                    ) : null}
                  </Box>
                </>
              )}
            </Box>
          </Card>
        </Box>
      </Box>
    </>
  )
}
