import { createAction } from '@reduxjs/toolkit'

export interface RangoFromToAssets {
  address: string | null
  blockchain: string
  symbol: string
  decimals: number
  logo: string
}

export enum RangoTransactionSwapStatus {
  PENDING = 'pending',
  RUNNING = 'running',
  SUCCESS = 'success',
  FAILED = 'failed'
}

export interface RangoTransactionSwaps {
  fromAssets: RangoFromToAssets
  fromAmount: string
  toAssets: RangoFromToAssets
  outAmount: string | null
  swapperId: string
  swapperType: 'DEX' | 'BRIDGE'
  stepStatus: RangoTransactionSwapStatus
  stepOutputAmount?: string
  lastHash: string
  isApproveTX: boolean
}
export enum RangoTransactionsStatusProp {
  PENDING = 'penging',
  ACTIVATING = 'activating',
  SUCCESS = 'success',
  FAILED = 'failed',
  CANCEL = 'cancel'
}
export interface SerializableRangoTransactions {
  account: string
  requestId: string
  requestAmount: string
  outputAmount: string
  swaps: RangoTransactionSwaps[]
  transactionsStatus: RangoTransactionsStatusProp
  stepCount: number
  currentStep: number
  slippage: string
  isClose?: boolean
  time: number
}

export const addRangoTransaction = createAction<SerializableRangoTransactions>('rangoTransactions/addRangoTransaction')

export const clearRangoAllTransactions = createAction('rangoTransactions/clearRangoAllTransactions')

export const updateSwapStepData = createAction<{
  curStep: number
  requestId: string
  account: string
  lastHash: string | undefined
  isApproveTX: boolean | undefined
  stepStatus: RangoTransactionSwapStatus | undefined
  stepOutputAmount: string | undefined
}>('rangoTransactions/updateSwapStepData')

export const updateTransactionsStatusAndStep = createAction<{
  requestId: string
  account: string
  transactionsStatus: RangoTransactionsStatusProp | undefined
  newStep: number | undefined
}>('rangoTransactions/updateTransactionsStatusAndStep')

export const updateTransactionsClose = createAction<{
  requestId: string
  account: string
}>('rangoTransactions/updateTransactionsClose')
