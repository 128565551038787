import { ZERO_ADDRESS } from '../../constants'
import Axios from './axios'

const { get, post } = Axios

export function fetchBiconomyNetworkFee(fromChainId: number, toChainId: number, fromToken: string, amount: string) {
  return get('https://hyphen-v2-api.biconomy.io/api/v1/data/transferFee', {
    fromChainId: fromChainId,
    toChainId: toChainId,
    tokenAddress: fromToken === ZERO_ADDRESS ? '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee' : fromToken,
    amount: amount
  })
}

export function fetchBiconomyPoolInfo(tokenAddress: string, fromChainId: number, toChainId: number) {
  return get('https://hyphen-v2-api.biconomy.io/api/v1/insta-exit/get-pool-info', {
    tokenAddress: tokenAddress === ZERO_ADDRESS ? '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee' : tokenAddress,
    fromChainId,
    toChainId
  })
}

export function fetchBiconomySystemStatus(
  tokenAddress: string,
  amount: string,
  fromChainId: number,
  toChainId: number,
  userAddress: string
) {
  return post('https://hyphen-v2-api.biconomy.io/api/v1/insta-exit/system-status', {
    tokenAddress: tokenAddress === ZERO_ADDRESS ? '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee' : tokenAddress,
    amount,
    fromChainId,
    toChainId,
    userAddress
  })
}

export function fetchBiconomyCheckStatus(fromChainId: number, depositHash: string) {
  return get('https://hyphen-v2-api.biconomy.io/api/v1/insta-exit/check-status', {
    fromChainId,
    depositHash
  })
}
