import Button from './Button'
import Spinner from 'components/Spinner'
import { Typography, Box } from '@mui/material'
import { ReactComponent as ActionCompletedIcon } from 'assets/componentsIcon/action_completed.svg'

export default function ActionButton({
  error,
  pending,
  success,
  onAction,
  actionText,
  pendingText,
  height,
  width,
  disableAction,
  successText,
  actionIcon
}: {
  error?: string | undefined
  pending?: boolean
  success?: boolean
  onAction: (() => void) | undefined
  actionText: string
  pendingText?: string
  successText?: string
  height?: string
  width?: string
  disableAction?: boolean
  actionIcon?: React.ReactNode
  successIcon?: React.ReactNode
}) {
  return (
    <>
      {error || pending ? (
        <Button disabled height={height} width={width}>
          {pending ? (
            <>
              <Spinner marginRight={16} />
              {pendingText || 'Waiting Confirmation'}
            </>
          ) : (
            error
          )}
        </Button>
      ) : success ? (
        <Button disabled height={height} width={width}>
          <Box mr={16} display="flex" alignItems="center">
            <ActionCompletedIcon />
          </Box>
          <Typography color="#FFFFFF">{successText ?? actionText}</Typography>
        </Button>
      ) : (
        <Button primary height={height} width={width} onClick={onAction} disabled={disableAction}>
          <>
            {actionIcon}
            {actionText}
          </>
        </Button>
      )}
    </>
  )
}
