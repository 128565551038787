import { useEffect, useState } from 'react'

export function useTimeIndex(time: number) {
  const [index, setIndex] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setIndex(index + 1)
    }, time * 1000)
  }, [index, time])

  return index
}
