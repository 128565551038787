import { Box, Typography, useTheme } from '@mui/material'
import Button from 'components/Button/Button'
import { Chain } from 'models/chain'
import React from 'react'
import Modal from '../index'
import DestinationAddress from './DestinationAddress'
import { SwapUpChain } from './SwapChain'
import Image from 'components/Image'
import { PlatformInfo } from 'constants/PlatformInfo'
import { SUPPORT_PLATFORM } from 'models/platform'

export default function DepositConfirmationModal({
  children,
  toChain,
  fromChain,
  title,
  fees,
  platform,
  destinationAddress,
  willReceiveAmountStr,
  onConfirm
}: {
  children?: React.ReactNode
  toChain: Chain | null
  fromChain: Chain | null
  platform?: SUPPORT_PLATFORM
  title?: string
  fees: string
  destinationAddress: string | undefined | null
  willReceiveAmountStr: string | JSX.Element
  onConfirm?: () => void
}) {
  const theme = useTheme()

  return (
    <Modal closeIcon>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="28px"
        justifyItems="center"
        width="100%"
        sx={{
          padding: {
            xs: 20,
            md: 40
          }
        }}
      >
        <Typography variant="h6">{title || 'Confirm'}</Typography>
        <Box justifySelf="start" width="100%">
          {fromChain && toChain && <SwapUpChain to={toChain} from={fromChain} />}
          {children}
          {platform && (
            <Box display="flex" justifyContent="space-between" mt="10px" mb="30px" alignContent="center">
              <Image height="24px" src={PlatformInfo[platform]?.logo} alt={`${PlatformInfo[platform]?.name}-logo`} />
              <Typography variant="body2" color={theme.textColor.text4}>
                You will use {platform} for cross-chain
              </Typography>
            </Box>
          )}
          <Box display="flex" justifyContent="space-between" mt="15px" alignItems="center">
            <Typography>You will expect to receive</Typography>
            <Typography color="#9867FF" fontSize="18px">
              {willReceiveAmountStr}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" mt="15px">
            <Typography>Fees</Typography>
            <Typography>{fees}</Typography>
          </Box>
          <DestinationAddress address={destinationAddress ?? ''} justifyContent="space-between" />
          <Button onClick={onConfirm} style={{ marginTop: 32 }} backgroundColor="rgba(22, 22, 22, 0.9)" color="#fff">
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
