import { useMemo, useState } from 'react'
import { Box, Typography, Grid, useTheme, ButtonBase } from '@mui/material'
import { ReactComponent as DashBoardIcon } from 'assets/componentsIcon/dashboard_icon.svg'
import Card, { OutlinedCard } from 'components/Card'
import LogoText from 'components/LogoText'
import { RoundButton } from 'components/Button/Button'
import Table from 'components/Table'
import { PlatformInfo } from 'constants/PlatformInfo'
import { SUPPORT_PLATFORM } from 'models/platform'
import { ChainId, ChainListMap } from 'constants/chain'
import { useDebankERC20Asset } from 'hooks/useERC20Data'
import CurrencyLogo from 'components/essential/CurrencyLogo'
import { SUPPORTED_ACCOUNT_TOKEN_VALUE, useAccountAllTotalValue } from 'hooks/useAccountTotalValue'
import { Token } from 'constants/token'
import { ShowPriceAndBalance } from './Balance'
// import Price from './Price'
import NoDataIcon from 'assets/svg/no_data.svg'
import Image from 'components/Image'
import { useActiveWeb3React } from 'hooks'
import CButton from 'components/Button/Button'
import { useWalletModalToggle } from 'state/application/hooks'
import Spinner from 'components/Spinner'
import { useDashboardInfo } from 'hooks/useDashboardInfo'
import { ZERO_ADDRESS } from 'constants/index'
// import UseDataPng from 'assets/images/use_data.png'
// import UseDataMobile from 'assets/images/use_data_mobile.svg'
import useBreakpoint from 'hooks/useBreakpoint'
import AreaChart from 'components/Chart'
import { useStatInfo } from 'hooks/useStatInfo'
import { toFormatGroup } from 'utils'

const platforms = [
  {
    key: SUPPORT_PLATFORM.ANYSWAP,
    available: true
  },
  {
    key: SUPPORT_PLATFORM.MULTICHAIN,
    available: true
  },
  {
    key: SUPPORT_PLATFORM.POLYNETWORK,
    available: true
  },
  {
    key: SUPPORT_PLATFORM.BICONOMY,
    available: true
  },
  {
    key: SUPPORT_PLATFORM.CBRIDGE,
    available: true
  },
  {
    key: SUPPORT_PLATFORM.STARGATE,
    available: true
  },
  {
    key: SUPPORT_PLATFORM.SYMBIOSIS,
    available: true
  },
  {
    key: SUPPORT_PLATFORM.O3SWAP,
    available: false
  },
  {
    key: SUPPORT_PLATFORM.CONNEXT,
    available: false
  },
  {
    key: SUPPORT_PLATFORM.WORMHOLE,
    available: false
  }
]

// function Pagination({
//   onPrevious,
//   onNext,
//   hidePrevious,
//   hideNext
// }: {
//   onPrevious: () => void
//   onNext: () => void
//   hidePrevious?: boolean
//   hideNext?: boolean
// }) {
//   return (
//     <Box display="flex" gap={12} justifyContent="flex-end" mt={30} alignItems="right">
//       <IconButton
//         onClick={onPrevious}
//         sx={{
//           width: 36,
//           height: 36,
//           border: '1px solid rgba(22, 22, 22, 0.2)',
//           borderRadius: '5px',
//           opacity: hidePrevious ? 0.5 : 1,
//           cursor: hidePrevious ? 'not-allowed' : 'pointer'
//         }}
//       >
//         <PaginationPrevious />
//       </IconButton>
//       <IconButton
//         onClick={onNext}
//         sx={{
//           width: 36,
//           height: 36,
//           border: '1px solid rgba(22, 22, 22, 0.2)',
//           borderRadius: '5px',
//           opacity: hideNext ? 0.5 : 1,
//           cursor: hideNext ? 'not-allowed' : 'pointer'
//         }}
//       >
//         <PaginationNext />
//       </IconButton>
//     </Box>
//   )
// }

export default function Dashboard() {
  const theme = useTheme()
  const toggleWalletModal = useWalletModalToggle()
  const [selectedChainId, setSelectedChainId] = useState<ChainId>(ChainId.MAINNET)
  const { account } = useActiveWeb3React()
  const isDownMd = useBreakpoint('md')

  const { loading: accountAssetsLoading, result: accountAssets } = useDebankERC20Asset(selectedChainId)
  const accountAssetsMap = useMemo(
    () =>
      accountAssets.map(item => ({
        ...item,
        token: new Token(
          item.chainId,
          item.isEther ? ZERO_ADDRESS : item.address,
          item.decimals,
          item.symbol,
          item.name,
          item.logoUrl
        )
      })),
    [accountAssets]
  )

  const {
    result: { total: totalValue, chainValue },
    loading: totalValueLoading
  } = useAccountAllTotalValue()
  const { data: dashboardInfo } = useDashboardInfo()
  const { data: statInfo } = useStatInfo()

  return (
    <Box
      sx={{
        padding: {
          xs: '0 15px'
        }
      }}
      width="100%"
      maxWidth={1192}
    >
      <Box display="flex" gap={19.9} alignItems="center" mb={isDownMd ? 24 : 36}>
        <DashBoardIcon />
        <Typography fontSize={32} fontWeight={700}>
          Dashboard
        </Typography>
      </Box>
      <Grid container spacing={24}>
        <Grid item xs={12} lg={7}>
          <OutlinedCard>
            <Box padding={isDownMd ? 12 : 24} height={496} display="grid" gap="20px">
              <Card color="#EEEDF7" height="148px">
                <Box padding="28px 24px 32px">
                  <Typography fontSize={16} sx={{ opacity: 0.6 }}>
                    Total Trade Volume
                  </Typography>
                  <Typography fontSize={32} fontWeight={700} mt="28px">
                    {statInfo?.totalVolume ? toFormatGroup(statInfo?.totalVolume) : '--'}
                  </Typography>
                </Box>
              </Card>
              {statInfo?.dayVolume ? (
                <AreaChart
                  id="transaction-volume"
                  unit="USDT"
                  lineColor={theme.palette.text.primary}
                  height={280}
                  areaSeriesData={statInfo?.dayVolume || []}
                />
              ) : (
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={280}>
                  <Spinner size="40px" />
                </Box>
              )}
              {/* <Image height="280px" width="100%" src={isDownMd ? UseDataMobile : UseDataPng}></Image> */}
            </Box>
          </OutlinedCard>
        </Grid>
        <Grid item xs={12} lg={5}>
          <OutlinedCard>
            <Box padding={isDownMd ? 12 : 24} height={496}>
              <Card color="#EFFAFE" height="148px">
                <Box padding="28px 24px 32px">
                  <Typography fontSize={16} sx={{ opacity: 0.6 }}>
                    Number of Assets Supported
                  </Typography>
                  <Typography fontSize={32} fontWeight={700} mt="28px">
                    {dashboardInfo?.supportedTokens || '--'} TOKENS
                  </Typography>
                </Box>
              </Card>
              <Box
                display="flex"
                flexDirection="column"
                sx={{
                  overflowY: 'auto',
                  height: 280,
                  marginRight: -20,
                  paddingRight: 14
                }}
                className="overflowClass"
                gap={8}
                mt={32}
              >
                {platforms.map((platform, idx) => (
                  <OutlinedCard key={idx}>
                    <Box
                      width="100%"
                      height={60}
                      padding="16px 24px"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <LogoText logo={PlatformInfo[platform.key].logo} text={PlatformInfo[platform.key].name} />
                      <Box
                        width={80}
                        height={20}
                        borderRadius={40}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          background: platform.available ? 'rgba(43, 208, 49, 0.16)' : 'rgba(152, 103, 255, 0.16)'
                        }}
                      >
                        <Typography fontSize={12} color={platform.available ? '#2BD031' : '#9867FF'}>
                          {platform.available ? 'Live' : 'Soon'}
                        </Typography>
                      </Box>
                    </Box>
                  </OutlinedCard>
                ))}
              </Box>
            </Box>
          </OutlinedCard>
        </Grid>
      </Grid>
      <Grid container spacing={24} mt={isDownMd ? 0 : 22} mb="20px">
        <Grid item xs={12} lg={4}>
          <Card
            color={theme.palette.primary.main}
            padding={isDownMd ? '20px 20px 31px' : '28px 24px'}
            height={isDownMd ? 188 : 619}
          >
            <Typography
              fontSize={isDownMd ? 12 : 16}
              color={theme.palette.primary.contrastText}
              sx={{ opacity: 0.6 }}
              mb={isDownMd ? 14 : 28}
            >
              My Value
            </Typography>
            <Typography fontSize={28} fontWeight={700} color={theme.palette.primary.contrastText}>
              {totalValueLoading ? '--' : '$ ' + (totalValue || 0).toFixed(2)}
            </Typography>
            <Box
              display="flex"
              flexDirection={isDownMd ? 'row' : 'column'}
              gap={isDownMd ? 12 : 8}
              mt={isDownMd ? 27 : 48}
              sx={{
                overflowX: isDownMd ? 'auto' : 'inherit',
                overflowY: isDownMd ? 'inherit' : 'auto',
                maxHeight: 400,
                '&::-webkit-scrollbar': {
                  display: 'none'
                }
              }}
            >
              <ChainButton
                chainId={null}
                onClick={() => {}}
                selected={selectedChainId === null}
                value={totalValueLoading ? '--' : (totalValue || 0).toFixed(2)}
              />
              {SUPPORTED_ACCOUNT_TOKEN_VALUE.map(chainIdItem => (
                <ChainButton
                  onClick={() => setSelectedChainId(chainIdItem)}
                  key={chainIdItem}
                  chainId={chainIdItem}
                  selected={chainIdItem === selectedChainId}
                  value={totalValueLoading ? '--' : (chainValue?.[chainIdItem] || 0).toFixed(2)}
                />
              ))}
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Box display="flex" flexDirection="column" justifyContent="space-between">
            <Box>
              <Box display="flex" justifyContent="space-between" mb={20}>
                <Box display="flex" gap={40}>
                  <RoundButton width={112} primary>
                    Asset
                  </RoundButton>
                  {/* <TextButton primary>NFT</TextButton> */}
                </Box>
                {/* <Input
                  placeholder="Search"
                  startAdornment={<SearchIcon />}
                  value={searchTerm}
                  onChange={e => onSearch(e.target.value)}
                  background="#F7F7F8"
                  maxWidth={240}
                  height={60}
                /> */}
              </Box>
              <Box display={isDownMd ? 'flex' : 'block'} flexDirection="column" gap={10}>
                <Table
                  header={['Asset', 'Chain', 'Price', 'Balance']}
                  sidePadding="24px"
                  rootPadding="10px"
                  stickyHeader
                  stickyHeaderBg="#fff"
                  maxHeight="500px"
                  rows={accountAssetsMap.map((item, idx) => {
                    return [
                      <LogoText
                        key={`token-logo-${idx}`}
                        logo={<CurrencyLogo currency={item.token} />}
                        text={item.symbol}
                      />,
                      <LogoText
                        key={`chain-logo-${idx}`}
                        logo={ChainListMap[selectedChainId].logo}
                        text={ChainListMap[selectedChainId].symbol}
                      />,
                      // <Price key={`price=${idx}`} token={token} />,
                      // <Balance key={`balance=${idx}`} token={token} />
                      <Typography key={0} fontWeight={500} fontSize={16}>
                        {item.price ? '$' + item.price.toFixed(4) : '--'}
                      </Typography>,
                      <ShowPriceAndBalance amount={item.amount} key={1} price={item.price} />
                    ]
                  })}
                />

                {account ? (
                  accountAssetsLoading ? (
                    <Box display="flex" justifyContent="center" mt={isDownMd ? 50 : 200} mb={isDownMd ? 50 : 0}>
                      <Spinner size="40px" />
                    </Box>
                  ) : (
                    accountAssets.length === 0 && (
                      <Box
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        mt={isDownMd ? 50 : 200}
                        mb={isDownMd ? 50 : 0}
                      >
                        <Image src={NoDataIcon} />
                        <Typography ml={5} fontSize={16} fontWeight={500}>
                          No Data
                        </Typography>
                      </Box>
                    )
                  )
                ) : (
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mt={isDownMd ? 50 : 200}
                    mb={isDownMd ? 50 : 0}
                  >
                    <CButton onClick={toggleWalletModal} width="150px" height="60px" primary>
                      Connect Wallet
                    </CButton>
                  </Box>
                )}
              </Box>
              {/* <Pagination
              hidePrevious={page === 0}
              hideNext={nextPageIndex < page}
              onPrevious={onPrevious}
              onNext={onNext}
            /> */}
              {/* <Pagination count={1} page={page} setPage={setPage} boundaryCount={0} perPage={6} siblingCount={0} /> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

function ChainButton({
  chainId,
  onClick,
  selected,
  value,
  disabled
}: {
  chainId: ChainId | null
  onClick: () => void
  selected: boolean
  value: number | string
  disabled?: boolean
}) {
  const theme = useTheme()
  const isDownMd = useBreakpoint('md')

  return (
    <ButtonBase
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: isDownMd ? 'flex-start' : 'space-between',
        gap: 16,
        height: isDownMd ? 48 : 60,
        borderRadius: '57px',
        padding: isDownMd ? '15px 16px' : '20px 24px',
        '&:hover': {
          background: '#423F47'
        },
        border: '1px solid #423F47',
        background: selected ? '#423F47' : 'transparent',
        '&:disabled': {
          cursor: 'not-allowed',
          opacity: 0.3
        }
        // width: isDownMd ? '300px' : '100%'
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {chainId ? (
        <LogoText
          logo={ChainListMap[chainId].logo}
          text={ChainListMap[chainId].symbol}
          gapSize={16}
          color={theme.palette.primary.contrastText}
        />
      ) : (
        <Typography noWrap fontSize={16} color={theme.palette.primary.contrastText}>
          All
        </Typography>
      )}

      <Typography noWrap fontSize={16} fontWeight={500} color={theme.palette.primary.contrastText}>
        $ {value}
      </Typography>
    </ButtonBase>
  )
}
