import { ZERO_ADDRESS } from '../constants'
import { Token } from 'constants/token'
import { useEffect, useState } from 'react'
import {
  Blockchain,
  BlockChainName,
  fetchRangoBestRouter,
  fetchRangoData,
  fetchTokens,
  RangoAPIToken,
  RangoBestRoutingRes,
  RangoBsetRoutingReq,
  RangoResult,
  Swapper
} from 'utils/fetch/swap'
import { rangoBlockChain } from '../constants/chain'

// interface TokenListProp {
//   name: string
//   symbol: string
//   decimals: number
//   logoUrl: string
//   chainId: number
//   address: string
// }

export function useFetchTokenList() {
  const [result, setResult] = useState<Token[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      try {
        const _data: any = await fetchTokens()
        setResult(
          _data.map(
            ({
              Name,
              Symbol,
              Decimals,
              LogoUrl
            }: {
              Name: string
              Symbol: string
              Decimals: number
              LogoUrl: string
            }) => {
              return new Token(1, ZERO_ADDRESS, Decimals, Symbol, Name, LogoUrl)
            }
          )
        )
      } catch (error) {
        console.error('fetch tokens error', error)
        setResult([])
      }
      setLoading(false)
    })()
  }, [])

  return {
    result,
    loading
  }
}

export function useFetchRangoData() {
  const [result, setResult] = useState<RangoResult>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      try {
        const _data: any = await fetchRangoData()
        const blockchains = _data.blockchains
          .filter(({ name }: BlockChainName) => {
            return rangoBlockChain(name)
          })
          .map(({ addressPatterns, defaultDecimals, feeAssets, name }: Blockchain) => {
            const network = rangoBlockChain(name)
            return {
              addressPatterns,
              defaultDecimals,
              feeAssets,
              name,
              network
            }
          })
        const swappers = _data.swappers.map(({ id, logo, title }: Swapper) => {
          return { id, logo, title }
        })
        const tokens = _data.tokens
          .map(({ a, b, d, i, n, s, p }: RangoAPIToken) => {
            return {
              address: a,
              blockchain: b,
              decimals: d,
              image: i,
              name: n,
              symbol: b === 'ETH' && s === 'ETH' && a ? 'WETH' : s,
              usdPrice: p
            }
          })
          .filter((item: { symbol: string }) => {
            return item.symbol !== 'ETH' && item.symbol !== 'WETH'
          })

        setResult({
          blockchains,
          swappers,
          tokens
        })
      } catch (error) {
        console.error('fetch tokens error', error)
        setResult(undefined)
      }
      setLoading(false)
    })()
  }, [])

  return {
    result,
    loading
  }
}

function bestRoutingRequestNumber(plus = false) {
  const cur = Number(window.sessionStorage.getItem('bestRoutingRequestNumber'))
  if (plus) {
    window.sessionStorage.setItem('bestRoutingRequestNumber', (cur + 1).toString())
    return cur + 1
  }
  return cur
}

export function useBestRouting(req: RangoBsetRoutingReq | undefined | null) {
  const [result, setResult] = useState<RangoBestRoutingRes | undefined>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const curRequestNumber = bestRoutingRequestNumber(true)
    if (!req) {
      return setResult(undefined)
    }
    setLoading(true)
    ;(async () => {
      try {
        const _data: any = await fetchRangoBestRouter(req)
        if (curRequestNumber === bestRoutingRequestNumber()) {
          setLoading(false)
          setResult(_data as RangoBestRoutingRes)
        }
      } catch (error) {
        console.error('useBestRouting', error)
        setResult(undefined)
      }
      setLoading(false)
    })()
  }, [req])

  return {
    result,
    loading
  }
}
