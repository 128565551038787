import { useSelector, useDispatch } from 'react-redux'
import { AppDispatch, AppState } from '../index'
import { useActiveWeb3React } from '../../hooks'
import { useCallback, useMemo } from 'react'
import {
  addRangoTransaction,
  clearRangoAllTransactions,
  RangoTransactionSwaps,
  RangoTransactionSwapStatus,
  SerializableRangoTransactions,
  RangoTransactionsStatusProp,
  updateTransactionsStatusAndStep,
  updateTransactionsClose
} from './actions'
import { RangoBestRoutingRes /*rangoCreateTransaction*/ } from '../../utils/fetch/swap'

const now = () => new Date().getTime()

export function useAccountRangoTransactions() {
  const { account } = useActiveWeb3React()
  const state = useSelector<AppState, AppState['rangoTransactions']>(state => state.rangoTransactions)

  return account ? state[account] ?? {} : {}
}

export function useAccountRangoTransactionsByRequestId(requestId: string): SerializableRangoTransactions | undefined {
  const trans = useAccountRangoTransactions()
  return trans[requestId] || undefined
}

export function useAccountNewestRangoEffectiveTransactions() {
  const allTrans = useAccountRangoTransactions()
  const trans = Object.values(allTrans).filter(item => !item.isClose)
  if (!trans || !Object.values(trans).length) return undefined
  const newestActive = Object.values(trans).reduce((pre, cur) => {
    if (!cur) return pre
    if (pre.time < cur.time) {
      return cur
    }
    return pre
  })
  return newestActive
}

export function useRangoTransactionsBetweenAssets(data: SerializableRangoTransactions | undefined) {
  return useMemo(() => {
    if (!data || !data.swaps.length) return undefined
    const _first = data.swaps[0]
    const _end = data.swaps[data.swaps.length - 1]
    return {
      fromAssets: _first.fromAssets,
      toAssets: _end.toAssets
    }
  }, [data])
}

export function useClearTransactions() {
  const dispatch = useDispatch<AppDispatch>()

  return useCallback(() => {
    dispatch(clearRangoAllTransactions())
  }, [dispatch])
}

export function useAddRangoTransaction(): (data: RangoBestRoutingRes, slippage?: string) => void {
  const dispatch = useDispatch<AppDispatch>()
  const { account } = useActiveWeb3React()

  return useCallback(
    (data: RangoBestRoutingRes, slippage = '1') => {
      if (!account) throw new Error('account not find')
      if (!data.requestId) throw new Error('requestId not find')

      const swaps: RangoTransactionSwaps[] = !data.result
        ? []
        : data.result?.swaps.map(item => ({
            fromAssets: item.from,
            fromAmount: item.fromAmount,
            toAssets: item.to,
            outAmount: item.toAmount,
            swapperId: item.swapperId,
            swapperType: item.swapperType,
            stepStatus: RangoTransactionSwapStatus.PENDING,
            lastHash: '',
            isApproveTX: false
          }))

      const rangoTransactions: SerializableRangoTransactions = {
        account: account || '',
        requestId: data.requestId,
        requestAmount: data.requestAmount.toString(),
        outputAmount: data.result?.outputAmount ?? '0',
        swaps: swaps,
        transactionsStatus: RangoTransactionsStatusProp.ACTIVATING,
        stepCount: data.result?.swaps.length ?? 0,
        currentStep: 1,
        slippage,
        time: now()
      }
      dispatch(addRangoTransaction(rangoTransactions))
    },
    [account, dispatch]
  )
}

export function useUpdateTransactionsStatusAndStep() {
  const dispatch = useDispatch<AppDispatch>()

  return useCallback(
    (
      requestId: string,
      account: string,
      transactionsStatus: RangoTransactionsStatusProp | undefined,
      newStep: number | undefined
    ) => {
      dispatch(updateTransactionsStatusAndStep({ requestId, account, transactionsStatus, newStep }))
    },
    [dispatch]
  )
}

export function useUpdateTransactionsClose() {
  const dispatch = useDispatch<AppDispatch>()

  return useCallback(
    (requestId: string, account: string) => {
      dispatch(updateTransactionsClose({ requestId, account }))
    },
    [dispatch]
  )
}
