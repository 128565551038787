import React, { useMemo } from 'react'
import Logo from './LogoBase'
import { Currency } from '../../../constants/token/currency'
import { Token } from '../../../constants/token/token'
import { RangoToken } from '../../../utils/fetch/swap'

export const getTokenLogoURL = (address: string) =>
  `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${address}/logo.png`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const srcs: string[] = useMemo(() => {
    const ret = []
    if (currency?.logo) ret.push(currency.logo)
    if (currency instanceof Token) {
      ret.push(getTokenLogoURL(currency.address))
    }
    return ret
  }, [currency])
  return (
    <Logo
      style={{
        ...style,
        width: size,
        height: size,
        borderRadius: size,
        boxShadow: ' 0px 6px 10px rgba(0, 0, 0, 0.025)'
      }}
      srcs={srcs}
      alt={`${currency?.symbol ?? 'token'} logo`}
    />
  )
}

export function RangoTokenLogo({
  token,
  size = '24px',
  style
}: {
  token?: RangoToken
  size?: string
  style?: React.CSSProperties
}) {
  return (
    <Logo
      style={{
        ...style,
        width: size,
        height: size,
        borderRadius: size,
        boxShadow: ' 0px 6px 10px rgba(0, 0, 0, 0.025)'
      }}
      srcs={token?.image ? [token.image] : []}
      alt={`${token?.symbol ?? 'token'} logo`}
    />
  )
}
