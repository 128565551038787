import axios from 'axios'

axios.defaults.timeout = 200000
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

axios.interceptors.request.use(
  function(config) {
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function(response) {
    if (response.status !== 200) {
      return Promise.reject(response)
    }
    return response.data
  },
  function(error) {
    return Promise.reject(error)
  }
)

const Axios = {
  get(url: string, params: { [key: string]: any } = {}) {
    return axios.get(url, { params })
  },
  post(url: string, data: { [key: string]: any } = {}, params = {}) {
    return axios.post(url, data, params)
  }
}

export default Axios
