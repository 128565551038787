import { Token } from 'constants/token'
import { BridgeCrossDirection, CrossChainProp, SUPPORT_PLATFORM } from 'models/platform'
import { useEffect, useState } from 'react'
import { fetchCrossChainList } from 'utils/fetch/swap'

export function useBridgeSearchResult(fromChainSymbol: string, fromChainId: number, toChainId: number) {
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<CrossChainProp[]>([])

  useEffect(() => {
    ;(async () => {
      if (!fromChainSymbol || !fromChainId || !toChainId) {
        setResult([])
        return
      }
      if (fromChainId === 1 && toChainId === 56 && fromChainSymbol === 'ETH') {
        setResult([])
        return
      }
      setLoading(true)
      try {
        const res: any = await fetchCrossChainList(fromChainSymbol, fromChainId, toChainId)
        setResult(
          res.map(
            ({
              Platform,
              SrcToken,
              DestToken,
              MinAmount,
              MaxAmount,
              Direction,
              PairId
            }: {
              Platform: string
              SrcToken: any
              DestToken: any
              MinAmount: string
              MaxAmount: string
              Direction?: number
              PairId?: string
            }) => {
              return {
                platform: Platform as SUPPORT_PLATFORM,
                srcToken: new Token(
                  Number(SrcToken.ChainId),
                  SrcToken.TokenAddress,
                  SrcToken.Decimals,
                  SrcToken.Symbol,
                  SrcToken.Name,
                  SrcToken.LogoUrl
                ),
                destToken: new Token(
                  Number(DestToken.ChainId),
                  DestToken.TokenAddress,
                  DestToken.Decimals,
                  DestToken.Symbol,
                  DestToken.Name,
                  DestToken.LogoUrl
                ),
                depositeContract: SrcToken.DepositAddress,
                // depositeContract: '0x374B8a9f3eC5eB2D97ECA84Ea27aCa45aa1C57EF',
                minAmount: MinAmount,
                maxAmount: MaxAmount,
                moreData: {
                  // multichain
                  direction: Number(Direction === undefined ? 0 : Direction) as BridgeCrossDirection,
                  pairID: PairId,
                  feeRate: SrcToken.SwapFeeRate as number,
                  maxFee: SrcToken.MaximumSwapFee as number,
                  minFee: SrcToken.MinimumSwapFee as number,
                  underlying: SrcToken.UnderlyingAddress ?? undefined,
                  destUnderlying: DestToken.UnderlyingAddress ?? undefined,
                  srcPoolId: SrcToken?.Extra?.PoolID,
                  dstPoolId: DestToken?.Extra?.PoolID,
                  bridgeSymbiosisDirection: Number(Direction === undefined ? 0 : Direction),
                  symbiosisSrcPortalAddress: SrcToken?.Extra?.Portal,
                  symbiosisSrcBridgeAddress: SrcToken?.Extra?.Bridge,
                  symbiosisSrcSynthesisAddress: SrcToken?.Extra?.Synthesis,
                  symbiosisDestPortalAddress: DestToken?.Extra?.Portal,
                  symbiosisDestBridgeAddress: DestToken?.Extra?.Bridge,
                  symbiosisDestSynthesisAddress: DestToken?.Extra?.Synthesis
                }
              }
            }
          )
        )
      } catch (error) {
        console.error(' BridgeSearchResult~ error', error)
        setResult([])
      }
      setLoading(false)
    })()
  }, [fromChainId, fromChainSymbol, toChainId])

  return {
    loading,
    result
  }
}
