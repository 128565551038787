import Axios from './axios'

const { get, post } = Axios

export function fetchAnyswapV1WithdrawResult(
  toAddressValue: string,
  fromTXHash: string,
  destChainID: string,
  pairID: string,
  srcChainID: string,
  type = 1
) {
  const func = type === 1 ? 'getHashStatus' : 'getWithdrawHashStatus'
  let params = `${toAddressValue}/${fromTXHash}/${destChainID}/${pairID}/${srcChainID}`
  if (pairID === 'fantom') {
    params = params + '?pairid=fantom'
  }
  return get(`https://bridgeapi.anyswap.exchange/v2/${func}/${params}`)
}

export function fetchPolyNetworkWithdrawResult(fromTXHash: string) {
  return post(`https://bridge.poly.network/v1/transactionofhash`, {
    Hash: fromTXHash
  })
}
