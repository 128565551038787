import { useCallback, useMemo } from 'react'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { useTheme, Box, styled, Typography } from '@mui/material'
import { NetworkContextName } from '../../constants'
import useENSName from '../../hooks/useENSName'
import { useWalletModalToggle } from '../../state/application/hooks'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { TransactionDetails } from '../../state/transactions/reducer'
import { shortenAddress } from '../../utils'
import WalletModal from 'components/Modal/WalletModal/index'
import Spinner from 'components/Spinner'
import { RoundButton } from 'components/Button/Button'
import { SUPPORTED_WALLETS } from 'constants/index'
import { injected } from 'connectors/'
import { useActiveWeb3React } from 'hooks/'
import { ReactComponent as Web3StatusIcon } from 'assets/svg/web3status_icon.svg'
import { useHistory } from 'react-router'
import Copy from 'components/essential/Copy'
import { UserInfoTabRoute, UserInfoTabs } from 'pages/Account'

const ActionButton = styled(RoundButton)(({ theme }) => ({
  fontSize: '14px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: 320,
    width: '100%',
    borderRadius: 49,
    height: '40px'
  }
}))

const UserButtonWrap = styled('div')({
  marginLeft: '5px',
  position: 'relative',
  width: 36,
  height: 36,
  '& > div': {
    opacity: 0,
    visibility: 'hidden'
  },
  '&:hover': {
    '& > div': {
      opacity: 1,
      visibility: 'visible'
    }
  }
})

const UserMenuWrapper = styled('div')({
  position: 'absolute',
  top: '50px',
  right: 0,
  zIndex: 2000,
  minWidth: '15rem',
  boxSizing: 'border-box',
  backgroundColor: '#ffffff',
  overflow: 'hidden',
  borderRadius: '16px',
  transitionDuration: '0.3s',
  transitionProperty: 'visibility, opacity',
  display: 'flex',
  border: '1px solid #ededed',
  flexDirection: 'column',
  '& > div:first-child': {
    padding: '16px 24px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #ededed',
    width: '100%'
  },
  '& > button:last-child': {
    padding: '16px 24px',
    borderTop: '1px solid #ededed'
  }
})

const UserMenuItem = styled('button')({
  padding: '12px 24px',
  width: '100%',
  border: 'none',
  backgroundColor: 'transparent',
  textAlign: 'left',
  fontSize: '16px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#ededed'
  }
})

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

function Web3StatusInner() {
  const { account, error } = useWeb3React()
  const { ENSName } = useENSName(account ?? undefined)
  const allTransactions = useAllTransactions()
  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])
  const pending = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash)
  const hasPendingTransactions = !!pending.length
  const toggleWalletModal = useWalletModalToggle()
  const theme = useTheme()
  const { connector } = useActiveWeb3React()

  function formatConnectorName() {
    const { ethereum } = window
    const isMetaMask = !!(ethereum && ethereum.isMetaMask)
    const name = Object.keys(SUPPORTED_WALLETS)
      .filter(
        k =>
          SUPPORTED_WALLETS[k].connector === connector && (connector !== injected || isMetaMask === (k === 'METAMASK'))
      )
      .map(k => SUPPORTED_WALLETS[k].name)[0]
    return <Typography sx={{ fontSize: 12, opacity: 0.6 }}>Connected with {name}</Typography>
  }
  const history = useHistory()
  const toShowUserPanel = useCallback(() => {
    history.push('/profile')
    return
  }, [history])

  if (account) {
    return (
      <Box sx={{ cursor: 'pointer' }} style={{ marginBottom: 15 }}>
        {formatConnectorName()}
        <Box
          sx={{
            width: 160,
            height: 44,
            borderRadius: '46px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: theme.bgColor.bg6,
            pl: 16
          }}
        >
          {hasPendingTransactions ? (
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 8 }} onClick={toggleWalletModal}>
              <Spinner color={theme.textColor.text5} size="16px" />
              <Box component="span" sx={{ ml: 3 }}>
                <Typography sx={{ fontSize: 14, ml: 8 }} color={theme.textColor.text5}>
                  {pending?.length} Pending
                </Typography>
              </Box>
            </Box>
          ) : (
            <Typography fontSize={13} mr={8} color={theme.palette.text.primary}>
              {ENSName || shortenAddress(account)}
            </Typography>
          )}
          <UserButtonWrap>
            <Web3StatusIcon
              onClick={toShowUserPanel}
              style={{ height: '100%', position: 'absolute', cursor: 'pointer' }}
            />
            <UserMenu account={account} />
          </UserButtonWrap>
        </Box>
      </Box>
    )
  } else if (error) {
    return (
      <ActionButton width="140px" height="44px" onClick={toggleWalletModal} primary>
        {error instanceof UnsupportedChainIdError ? 'Wrong Network' : 'Error'}
      </ActionButton>
    )
  } else {
    return (
      <ActionButton
        backgroundColor={theme.palette.primary.main}
        width="152px"
        height="44px"
        fontSize="13px"
        borderRadius="60px"
        onClick={toggleWalletModal}
        primary
      >
        Connect Wallet
      </ActionButton>
    )
  }
}

export default function Web3Status() {
  const { active, account } = useWeb3React()
  const contextNetwork = useWeb3React(NetworkContextName)

  const { ENSName } = useENSName(account ?? undefined)

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash)
  const confirmed = sortedRecentTransactions.filter(tx => tx.receipt).map(tx => tx.hash)

  if (!contextNetwork.active && !active) {
    return null
  }

  return (
    <>
      <Web3StatusInner />
      <WalletModal ENSName={ENSName ?? undefined} pendingTransactions={pending} confirmedTransactions={confirmed} />
    </>
  )
}

function UserMenu({ account }: { account?: string | null }) {
  const toggleWalletModal = useWalletModalToggle()
  const history = useHistory()

  return (
    <UserMenuWrapper>
      <Box display="flex" gap="10px" alignItems="center">
        <Web3StatusIcon style={{ width: 24 }} />
        <Typography fontSize={14} style={{ color: '#000' }}>
          {account && shortenAddress(account)}
        </Typography>
        {account && <Copy size={20} toCopy={account} />}
      </Box>
      <Box display="flex" flexDirection="column">
        <UserMenuItem onClick={() => history.push('/profile/' + UserInfoTabs.ACTIVITY)}>
          {UserInfoTabRoute[UserInfoTabs.ACTIVITY]}
        </UserMenuItem>
        <UserMenuItem onClick={toggleWalletModal}>Wallet</UserMenuItem>
      </Box>
    </UserMenuWrapper>
  )
}
