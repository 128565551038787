import { Box, Typography } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Chain } from 'models/chain'
import LogoText from 'components/LogoText'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

interface Props {
  from: Chain
  to: Chain
}

export default function SwapChain(props: Props) {
  const { from, to } = props

  return (
    <Box
      bgcolor="rgba(22, 22, 22, 0.1)"
      borderRadius="10px"
      height="48px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding="0 36px"
      width="100%"
    >
      <Box display="flex" alignItems="center">
        <Typography mr={12} sx={{ opacity: 0.6 }}>
          From:
        </Typography>
        <LogoText logo={from.logo} text={from.symbol} />
      </Box>
      <ArrowForwardIcon />
      <Box display="flex" alignItems="center">
        <Typography mr={12} sx={{ opacity: 0.6 }}>
          To:
        </Typography>
        <LogoText logo={to.logo} text={to.symbol} />
      </Box>
    </Box>
  )
}

export function SwapUpChain(props: Props) {
  const { from, to } = props

  return (
    <Box
      bgcolor="rgba(22, 22, 22, 0.1)"
      borderRadius="10px"
      // height="48px"
      display="grid"
      gap="10px"
      // alignItems="center"
      // justifyContent="space-between"
      padding="20px 30px"
      width="100%"
    >
      <Box display="flex" alignItems="center">
        <Typography mr={12} sx={{ opacity: 0.6 }}>
          From:
        </Typography>
        <LogoText logo={from.logo} text={from.name} />
      </Box>
      <ArrowDownwardIcon />
      <Box display="flex" alignItems="center">
        <Typography mr={12} sx={{ opacity: 0.6 }}>
          To:
        </Typography>
        <LogoText logo={to.logo} text={to.name} />
      </Box>
    </Box>
  )
}
