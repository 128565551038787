import React from 'react'
import { Button, useTheme } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

interface Props {
  onClick?: () => void
  width?: string
  height?: string
  children?: React.ReactNode
  primary?: boolean
  disabled?: boolean
  style?: React.CSSProperties
}

export default function SelectButton(props: Props) {
  const { onClick, disabled, style, width, height, primary, children } = props
  const theme = useTheme()

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      sx={{
        ...style,
        width: width || '100%',
        height: height || 60,
        backgroundColor: primary ? theme.palette.primary.main : theme.palette.background.default,
        color: theme.palette.text.primary,
        borderRadius: 1,
        fontSize: 16,
        fontWeight: 400,
        transition: '.3s',
        // border: '1px solid transparent',
        '&:hover': {
          color: theme.palette.primary.contrastText,
          background: theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.main}`,
          '& span': {
            color: '#fff'
          },
          '& p': {
            color: '#fff'
          }
        },
        display: 'flex'
      }}
      endIcon={<ExpandMoreIcon />}
    >
      {children}
    </Button>
  )
}
