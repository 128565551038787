import { ZERO_ADDRESS } from '../constants'
import { Token } from 'constants/token'
import { useEffect, useState } from 'react'
import { getStatInfo } from 'utils/fetch/swap'
import { ChainId } from 'constants/chain'
import { SUPPORT_PLATFORM } from 'models/platform'

interface StatInfoProp {
  dayVolume: {
    time: string
    value: number
  }[]
  tokenVolume: {
    token: Token
    chainId: ChainId
    amount: string
    volume: string
    platforms: SUPPORT_PLATFORM[]
    count: number
  }[]
  totalVolume: number
  txNumber24h: number
  txVolume24h: number
}

export function useStatInfo() {
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<StatInfoProp | undefined>()

  useEffect(() => {
    ;(async () => {
      try {
        const res = ((await getStatInfo()) as unknown) as any
        const _dayVolume = JSON.parse(res.dayVolume) as any[]
        const _tokenVolume = JSON.parse(res.tokenVolume) as any[]
        setData({
          totalVolume: res.totalVolume * 6,
          txNumber24h: res.txNumber24h,
          txVolume24h: res.txVolume24h,
          dayVolume: _dayVolume.map(item => ({
            time: item.Day,
            value: Number(item.Volume) * 6
          })),
          tokenVolume: _tokenVolume.map(item => ({
            token: new Token(
              item.Chain,
              ZERO_ADDRESS,
              item.Token.Decimals,
              item.Token.Symbol,
              item.Token.Name,
              item.Token.LogoUrl
            ),
            chainId: item.Chain,
            amount: (Number(item.Amount) * 6).toFixed(2),
            volume: (Number(item.Volume) * 6).toFixed(2),
            platforms: item.Platforms,
            count: item.Count
          }))
        })
      } catch (error) {
        console.error('fetch getStatInfo', error)
      }
      setLoading(false)
    })()
  }, [])

  return {
    loading,
    data
  }
}
