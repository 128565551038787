import { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { useActiveWeb3React } from 'hooks'
import { useCoingeckoData } from 'hooks/useCoingecko'
import { useCurrencyBalance } from 'state/wallet/hooks'
import { Token, ETHER } from 'constants/token'
import isZero from 'utils/isZero'

interface Props {
  token?: Token
}

export default function Balance(props: Props) {
  const { token } = props
  const { account } = useActiveWeb3React()

  const data = useCoingeckoData(token?.address)

  const isETHER = useMemo(() => isZero(token?.address ?? ''), [token?.address])
  const fromBalance = useCurrencyBalance(account ?? undefined, isETHER ? ETHER : token)

  // const balance = useCurrencyBalance(account ?? undefined, token)

  const usdPrice = data?.result?.market_data?.current_price?.usd

  const showValue = useMemo(() => {
    if (usdPrice && fromBalance) {
      return '$' + (usdPrice * Number(fromBalance.toSignificant())).toFixed(2)
    }
    return '--'
  }, [fromBalance, usdPrice])

  const showBalance = useMemo(() => {
    if (fromBalance) {
      const _val = fromBalance.toSignificant()
      if (Number(_val) < 0.001) {
        return '<0.001'
      }
      return _val
    }
    return '--'
  }, [fromBalance])

  return (
    <Box display="flex" alignItems="flex-end">
      <Typography fontWeight={500} fontSize={16}>
        {showValue}
      </Typography>
      <Typography fontSize="13px" color="rgba(0, 0, 0, 1)">
        / {showBalance}
      </Typography>
    </Box>
  )
}

export function ShowPriceAndBalance({ price, amount }: { price: number | null; amount: number }) {
  const currValue = useMemo(() => {
    return price ? (price * amount).toFixed(2) : '--'
  }, [amount, price])

  const showBalance = useMemo(() => {
    if (amount) {
      const _val = amount
      if (Number(_val) < 0.001) {
        return '<0.001'
      }
      return _val.toFixed(3)
    }
    return '--'
  }, [amount])

  return (
    <Box display="flex" alignItems="flex-end">
      <Typography fontWeight={500} fontSize={16}>
        {price ? '$' + currValue : '--'}
      </Typography>
      <Typography fontSize="13px" color="rgba(0, 0, 0, 1)">
        / {showBalance}
      </Typography>
    </Box>
  )
}
