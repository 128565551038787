import { useCallback, useEffect, useMemo, useState } from 'react'
import JourneyNotification from './JourneyNotification'
import {
  useAccountNewestRangoEffectiveTransactions,
  useRangoTransactionsBetweenAssets,
  useUpdateTransactionsClose
} from 'state/rangoTransactions/hooks'
import { toFormatGroup } from 'utils'
import { RangoTransactionsStatusProp } from 'state/rangoTransactions/actions'
import { useFetchRangoData } from 'hooks/useFetchBaseData'

// const journeyDetailSteps = useMemo(() => {
//   return [
//     {
//       type: JourneyType.TOKEN,
//       logo: DymmyCurrencyLogo,
//       title: '0.00015 ETH',
//       description: 'BSC'
//     },
//     {
//       type: JourneyType.BRIDGE,
//       logo: DummyBridgeLogo,
//       title: 'cBridge v2.0',
//       status: JourneyStatus.SUCCESS,
//       txs: [
//         {
//           title: 'View transaction',
//           link: '#'
//         }
//       ]
//     },
//     {
//       type: JourneyType.TOKEN,
//       logo: DymmyCurrencyLogo,
//       title: 'ETH',
//       description: 'BSC'
//     },
//     {
//       type: JourneyType.BRIDGE,
//       logo: DummyBridgeLogo,
//       title: 'cBridge v2.0',
//       status: JourneyStatus.SUCCESS,
//       txs: [
//         {
//           title: 'AnySwaptx',
//           link: '#'
//         },
//         {
//           title: 'ETH tx',
//           link: '#'
//         },
//         {
//           title: 'BSX tx',
//           link: '#'
//         }
//       ]
//     },
//     {
//       type: JourneyType.TOKEN,
//       logo: DymmyCurrencyLogo,
//       title: 'ETH',
//       description: 'BSC'
//     }
//   ]
// }, [])

export default function Index() {
  const activeTran = useAccountNewestRangoEffectiveTransactions()
  const betweenAssets = useRangoTransactionsBetweenAssets(activeTran)
  const [showNotification, setShowNotification] = useState(false)
  const updateTransactionsClose = useUpdateTransactionsClose()
  const { result: rangoData } = useFetchRangoData()

  useEffect(() => {
    if (activeTran?.requestId) {
      setShowNotification(true)
    }
  }, [activeTran?.requestId])
  activeTran?.requestAmount

  const JourneyNotificationTitle = useMemo(
    () =>
      `Swap ${activeTran?.requestAmount}${betweenAssets?.fromAssets.symbol}(${
        betweenAssets?.fromAssets.blockchain
      }) to ${toFormatGroup(activeTran?.outputAmount || '', 6)} ${betweenAssets?.toAssets.symbol}(${
        betweenAssets?.toAssets.blockchain
      }) `,
    [activeTran, betweenAssets]
  )

  const progressRate = useMemo(() => {
    if (!activeTran) return 0
    if (activeTran.transactionsStatus === RangoTransactionsStatusProp.SUCCESS) return 1
    return (activeTran.currentStep - 1) / activeTran.stepCount
  }, [activeTran])

  const onHide = useCallback(() => {
    if (!activeTran) return
    setShowNotification(false)
    updateTransactionsClose(activeTran.requestId, activeTran.account)
  }, [activeTran, updateTransactionsClose])

  useEffect(() => {
    activeTran?.requestId && setShowNotification(true)
  }, [activeTran?.requestId])

  if (!activeTran) return null
  return (
    <>
      {showNotification && (
        <JourneyNotification
          title={JourneyNotificationTitle}
          swappers={rangoData?.swappers}
          onHide={onHide}
          transactions={activeTran}
          progressRate={progressRate}
        />
      )}
    </>
  )
}

// {showNotification && (
//   <JourneyNotification
//     title="Swap 1.0 ETH to 0.0000001ETH"
//     onHide={() => setShowNotification(false)}
//     steps={journeyNotificationSteps}
//     detailLink="#"
//     progressRate={0.4}
//   />
// )}
// <JourneyDetailModal
//   steps={journeyDetailSteps}
//   finishedStr={'Finished 16 min ago @ 2022/02/12  10:02:31'}
//   requestId={'b8a11b45-fccf-4b99-a96b-cc01e45f45ed'}
//   isOpen={showDetailModal}
// />
