import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone'
import { Typography, Box, styled, useTheme } from '@mui/material'
import { useActiveWeb3React } from 'hooks/'
import { ExternalLink } from 'theme/components'
import { getEtherscanLink } from 'utils'
import { swapResultStatus } from 'hooks/useChainSwapResult'
import { ReactComponent as SuccessIcon } from 'assets/componentsIcon/statusIcon/success_icon.svg'
import { ReactComponent as ErrorIcon } from 'assets/componentsIcon/statusIcon/error_icon.svg'
// import { ReactComponent as PendingIcon } from 'assets/componentsIcon/statusIcon/pending_icon.svg'
import Spinner from 'components/Spinner'
import { SUPPORT_PLATFORM } from 'models/platform'

export const RotateSpan = styled('span')(`
  @keyframes rotate{from{transform: rotate(0deg)}
    to{transform: rotate(360deg)}
  };
  animation: rotate 3s linear infinite;
  display: inline-block;
  width: 24px;
  height: 24px;
`)

export default function TransactionPopup({
  hash,
  success,
  summary
}: {
  hash: string
  success?: boolean
  summary?: string
}) {
  const { chainId } = useActiveWeb3React()

  return (
    <Box display="grid" gap="8px">
      <Box display="flex" alignItems="flex-start" flexWrap="nowrap" style={{ paddingRight: 10 }}>
        <div style={{ paddingRight: 16 }}>
          {success ? (
            <CheckCircleOutlineOutlinedIcon color="success" height={20} width={20} />
          ) : (
            <ReportGmailerrorredOutlinedIcon color="error" height={20} width={20} />
          )}
        </div>
        <Typography variant="inherit">{summary ?? 'Hash: ' + hash.slice(0, 8) + '...' + hash.slice(58, 65)}</Typography>{' '}
      </Box>
      {chainId && (
        <ExternalLink
          underline="always"
          href={getEtherscanLink(chainId ? chainId : 1, hash, 'transaction')}
          style={{ margin: '9px 32px' }}
        >
          View on explorer
        </ExternalLink>
      )}
    </Box>
  )
}

export function TransactionCrossChainPopup({
  hash,
  swapResult,
  swapResultLoading,
  bridge,
  summary
}: {
  hash: string
  swapResult: swapResultStatus
  swapResultLoading: boolean
  summary?: string
  bridge?: string
}) {
  const { chainId } = useActiveWeb3React()
  const theme = useTheme()

  return (
    <Box display="grid" gap="8px">
      <Box display="flex" alignItems="flex-start" flexWrap="nowrap" style={{ paddingRight: 10 }}>
        <div style={{ paddingRight: 16 }}>
          {swapResult === swapResultStatus.SUCCESS ? (
            <SuccessIcon height={20} width={20} />
          ) : swapResult === swapResultStatus.PENDING || swapResultLoading ? (
            // <RotateSpan>
            //   <PendingIcon height={20} width={20} />
            // </RotateSpan>
            <Spinner color={theme.textColor.text5} size="20px" />
          ) : swapResult === swapResultStatus.Fail ? (
            <ErrorIcon height={20} width={20} />
          ) : (
            <HelpTwoToneIcon color="info" height={20} width={20} />
          )}
        </div>
        <Typography variant="inherit">{summary ?? 'Hash: ' + hash.slice(0, 8) + '...' + hash.slice(58, 65)}</Typography>{' '}
      </Box>
      {bridge === SUPPORT_PLATFORM.ANYSWAP ? (
        <Typography variant="inherit">
          Please wait minutes to check the cross-chain tx status on the {'  '}
          <ExternalLink underline="always" href={`https://anyswap.net/explorer?tabparams=tools&hash=${hash}`}>
            explorer
          </ExternalLink>
        </Typography>
      ) : bridge === SUPPORT_PLATFORM.CBRIDGE && swapResult === swapResultStatus.Fail ? (
        <Typography variant="inherit" textAlign="center">
          <ExternalLink
            underline="always"
            href="https://cbridge.celer.network/#/transfer"
            style={{ margin: '9px 32px' }}
          >
            Click to refund
          </ExternalLink>
        </Typography>
      ) : (
        <></>
      )}
      {chainId && bridge !== SUPPORT_PLATFORM.ANYSWAP && bridge !== SUPPORT_PLATFORM.CBRIDGE && (
        <ExternalLink
          underline="always"
          href={getEtherscanLink(chainId ? chainId : 1, hash, 'transaction')}
          style={{ margin: '9px 32px' }}
        >
          View on explorer
        </ExternalLink>
      )}
    </Box>
  )
}
