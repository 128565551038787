import Card from 'components/Card'
import Image from 'components/Image'
import { Typography, Box } from '@mui/material'
import { Asset } from '../../../../utils/fetch/swap'
import { shortenAddress } from '../../../../utils'
import { useActiveWeb3React } from '../../../../hooks'
import MetaMaskLogo from 'assets/walletIcon/metamask.png'
import BigNumber from 'bignumber.js'

interface Props {
  amount: string | number
  asset: Asset
}

export default function WalletCard(props: Props) {
  const { amount, asset } = props
  const { account } = useActiveWeb3React()
  return (
    <Card grey>
      <Box
        sx={{
          height: amount ? 100 : 80,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Image src={MetaMaskLogo} width={28} />
          <Typography fontSize={12} mt={14} sx={{ opacity: 0.5 }}>
            {account && shortenAddress(account, 3)}
          </Typography>
          {amount && (
            <Typography fontSize={12} fontWeight={500} mt={4}>
              {new BigNumber(amount).toFixed(4)} {asset.symbol}
            </Typography>
          )}
        </Box>
      </Box>
    </Card>
  )
}
