import { Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { styled } from '@mui/material'
import Header from '../components/Header'
import Polling from '../components/essential/Polling'
import Popups from '../components/essential/Popups'
import Web3ReactManager from '../components/essential/Web3ReactManager'
import WarningModal from '../components/Modal/WarningModal'
import ComingSoon from './ComingSoon'
import { ModalProvider } from 'context/ModalContext'
import Footer from 'components/Footer'
import BridgeAggregator from './BridgeAggregator'
import Dashboard from './Dashboard'
import Account from './Account'
// import Dashboard from './Dashboard'
import Swap from './Swap'
import Statistics from './Statistics'
import SwapResultPopups from 'components/SwapResultPopups'

const AppWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  overflowX: 'hidden',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    height: '100vh'
  }
}))

const ContentWrapper = styled('div')({
  width: '100%',
  maxHeight: '100vh',
  overflow: 'auto',
  alignItems: 'center'
})

const BodyWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minHeight: `calc(100vh - ${theme.height.header} - ${theme.height.footer})`,
  padding: '50px 0 80px',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    minHeight: `calc(100vh - ${theme.height.header} - ${theme.height.mobileHeader})`,
    paddingTop: 20
  },
  '& .overflowClass': {
    '&::-webkit-scrollbar': {
      width: 6,
      height: 6
    },

    '&::-webkit-scrollbar-track': {
      background: 'transparent',
      borderRadius: 6
    },

    '&::-webkit-scrollbar-thumb': {
      background: '#ccc',
      borderRadius: 6
    },

    '&::-webkit-scrollbar-thumb:hover': {
      background: '#ddd'
    }
  }
}))

export default function App() {
  return (
    <Suspense fallback={null}>
      <ModalProvider>
        <AppWrapper id="app">
          <ContentWrapper>
            <Header />
            <BodyWrapper id="body">
              <Popups />
              <Polling />
              <SwapResultPopups />
              <WarningModal />
              <Web3ReactManager>
                <Switch>
                  {/* <Route path="/" component={BridgeAggregator} /> */}
                  <Route exact strict path="/dashboard" component={Dashboard} />
                  <Route exact strict path="/bridge" component={BridgeAggregator} />
                  <Route exact strict path="/swap" component={Swap} />
                  <Route exact strict path="/nft" component={ComingSoon} />
                  <Route exact strict path="/dao" component={ComingSoon} />
                  <Route exact strict path="/statistics" component={Statistics} />
                  <Route strict path="/profile/:tab" component={Account} />
                  <Route strict path="/profile" component={Account} />
                  <Redirect path="/" to="/dashboard" />
                </Switch>
              </Web3ReactManager>
            </BodyWrapper>
            <Footer />
          </ContentWrapper>
        </AppWrapper>
      </ModalProvider>
    </Suspense>
  )
}
