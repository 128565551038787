import { PLATFORM_INFO, SUPPORT_PLATFORM } from '../models/platform'
// import MultiChainLogo from '../assets/images/multiChain.png'
import MultiChainLogo from '../assets/svg/platforms/multi_chain.svg'
import PolyBridgeLogo from '../assets/svg/platforms/poly_bridge.svg'
import ConnextLogo from '../assets/svg/platforms/connext.svg'
import WarmholeLogo from '../assets/svg/platforms/warmhole.svg'
import O3swapLogo from '../assets/svg/platforms/o3swap.svg'
import AnySwapLogo from '../assets/images/192x192_anyswap_icon.png'
import CbridgeLogo from '../assets/images/cbridge.png'
import SymbiosisLogo from '../assets/images/symbiosis.png'
import BiconomyLogo from '../assets/images/biconomy_logo.png'
import StargateLogo from '../assets/images/stargate_logo.svg'

export const PlatformInfo: { [key in SUPPORT_PLATFORM]: PLATFORM_INFO } = {
  [SUPPORT_PLATFORM.POLYNETWORK]: {
    name: 'PolyBridge',
    intro: `PolyBridge is the cross-chain bridge for transferring assets between the leading smart contract blockchains. It aims to build the next generation Internet infrastructure, realize interoperability between multiple chains.`,
    logo: PolyBridgeLogo,
    link: 'https://poly.network/'
  },
  [SUPPORT_PLATFORM.MULTICHAIN]: {
    name: 'MultiChain',
    intro:
      'The multi-chain cross-chain platform multichain.xyz is jointly developed by Anyswap and yearn.finance (YFI) founder Andre Cronje. It supports a variety of third-party assets, and supports cross-chain transactions of multi-chain assets. More tokens will be continuously updated in the future to support more cross-chain transactions.',
    logo: MultiChainLogo,
    link: 'https://multichain.xyz/'
  },
  [SUPPORT_PLATFORM.ANYSWAP]: {
    name: 'AnySwap',
    intro:
      'Anyswap is a fully decentralized cross chain swap protocol, based on Fusion DCRM technology, with automated pricing and liquidity system. Anyswap is a decentralized application running on the Fusion , Binance Smart Chain , Ethereum and Fantom blockchains. The first application from Anyswap is a DEX (Decentralized Exchange), which is called anyswap.exchange. This is the User Guide for the DEX.',
    logo: AnySwapLogo,
    link: 'https://anyswap.exchange/#/router'
  },
  [SUPPORT_PLATFORM.CBRIDGE]: {
    name: 'CBridge',
    intro:
      'cBridge 2.0 introduces the best-in-class cross-chain token bridging experience with deep liquidity for users, highly efficient and easy-to-use liquidity management for both cBridge node operators and Liquidity Providers who do not want to operate cBridge nodes, and new exciting developer-oriented features such as general message bridging for cases like cross-chain DEX and NFTs.',
    logo: CbridgeLogo,
    link: 'https://cbridge.celer.network/'
  },
  [SUPPORT_PLATFORM.BICONOMY]: {
    name: 'Biconomy',
    intro:
      'Blockchain transactions made simple. Enable Gasless meta-transactions, instant cross-chain transfers, easy layer 2 onboarding & zero failed transactions.',
    logo: BiconomyLogo,
    link: 'https://www.biconomy.io/'
  },
  [SUPPORT_PLATFORM.STARGATE]: {
    name: 'Stargate',
    intro: 'A Composable Omnichain Native Asset Bridge',
    logo: StargateLogo,
    link: 'https://stargate.finance/'
  },
  [SUPPORT_PLATFORM.SYMBIOSIS]: {
    name: 'Symbiosis',
    intro:
      'Symbiosis is a decentralized multi-chain liquidity protocol. It allows users to swap assets between all the blockchains while remaining the sole owners of the funds.',
    logo: SymbiosisLogo,
    link: 'https://symbiosis.finance/'
  },
  [SUPPORT_PLATFORM.CONNEXT]: {
    name: 'Connext',
    intro: '',
    logo: ConnextLogo,
    link: ''
  },
  [SUPPORT_PLATFORM.WORMHOLE]: {
    name: 'Wormhole',
    intro: '',
    logo: WarmholeLogo,
    link: ''
  },
  [SUPPORT_PLATFORM.O3SWAP]: {
    name: 'O3Swap',
    intro: '',
    logo: O3swapLogo,
    link: ''
  }
}
