import axios from 'axios'

const BASE_URL = 'https://api.coingecko.com/api/v3'

const client = axios.create({
  baseURL: `${BASE_URL}`,
  responseType: 'json'
})

export function fetchCoingeckoData(tokenAddress: string) {
  return client.get(`/coins/ethereum/contract/${tokenAddress}`, {})
}
