// import { RangoTransactionSwaps, RangoTransactionSwapStatus } from 'state/rangoTransactions/actions'
import { Web3Provider } from '@ethersproject/providers'
import {
  RangoTransactionSwaps,
  RangoTransactionsStatusProp,
  updateSwapStepData,
  updateTransactionsStatusAndStep,
  RangoTransactionSwapStatus
} from 'state/rangoTransactions/actions'
import { rangoCheckTXApproval, rangoCheckTXStatus, rangoCreateTransaction } from 'utils/fetch/swap'
import { triggerSwitchChain } from 'utils/triggerSwitchChain'
import Web3 from 'web3'
import store from 'state/index'
import { rangoBlockChain } from 'constants/chain'

function sleep(time: number) {
  return new Promise(resolve => setTimeout(resolve, time))
}

interface CheckTransferStatusRes {
  explorerUrl: { description: string | null; url: string }[]
  extraMessage: string | null
  outputAmount: string | null
  status: 'success' | 'running' | 'failed' | null
  timestamp: number
}
export function checkTransferStatus(requestId: string, step: number, hash: string): Promise<CheckTransferStatusRes> {
  return new Promise(async (resolve, reject) => {
    try {
      const resData: any = await rangoCheckTXStatus(requestId, step, hash)
      resolve(resData)
    } catch (_) {
      const _ret: CheckTransferStatusRes = {
        explorerUrl: [],
        extraMessage: 'Network error',
        outputAmount: '',
        status: 'running',
        timestamp: new Date().getTime()
      }
      reject(_ret)
    }
  })
}

interface CheckApprovalRes {
  isApproved: boolean | undefined
  extraMessage: string | null
}
function checkApprovalStatus(requestId: string): Promise<CheckApprovalRes> {
  return new Promise(async (resolve, reject) => {
    try {
      const resData: any = await rangoCheckTXApproval(requestId)
      resolve({
        extraMessage: '',
        isApproved: resData.isApproved as boolean
      })
    } catch (_) {
      const _ret: CheckApprovalRes = {
        extraMessage: 'Network error',
        isApproved: undefined
      }
      reject(_ret)
    }
  })
}

interface CreateTransactionRes {
  blockChain: string
  data: string
  from: string | null
  gasLimit: string | null
  gasPrice: string | null
  nonce: number | null
  to: string
  value: string | null
  isApprovalTx: boolean
  type: string
}
export function createTransaction(
  requestId: string,
  step: number,
  slippage: string
): Promise<{ error: null | string; ok: boolean; transaction: CreateTransactionRes }> {
  return new Promise((resolve, reject) => {
    ;(async function _createTX() {
      try {
        const resData: any = rangoCreateTransaction(requestId, step, slippage)
        resolve(resData)
      } catch (error) {
        console.error('createTransaction error', error)
        reject()
      }
    })()
  })
}

interface SwapEthereum {
  on: (...args: any[]) => void
  removeListener: (...args: any[]) => void
  request: (...args: any[]) => any
}
export function checkIsSupportChain(
  needChainId: number,
  ethereum: SwapEthereum,
  library: Web3Provider,
  account: string
): Promise<number> {
  return new Promise(resolve => {
    ;(async () => {
      // if (!window.ethereum || !window.ethereum.request || !window.ethereum.on) return
      function onChainChanged(newChainId: number) {
        if ('0x' + needChainId.toString(16) === newChainId.toString()) {
          // window.ethereum && window.ethereum.removeListener && window.ethereum.removeListener(onChainChanged)
          resolve(needChainId)
        }
      }
      const curChainId = await ethereum.request({ method: 'eth_chainId' })
      if ('0x' + needChainId.toString(16) === curChainId.toString()) {
        resolve(needChainId)
      } else {
        // ethereum.on('chainChanged', onChainChanged)
        if (window.ethereum && window.ethereum.on) {
          window.ethereum.on('chainChanged', onChainChanged)
        }
        triggerSwitchChain(library, needChainId, account)
      }
    })()
  })
}

export function sendTransaction(
  web3: Web3,
  createTransactionData: CreateTransactionRes,
  account: string
): Promise<string> {
  return new Promise((resolve, reject) => {
    web3.eth
      .sendTransaction({
        from: createTransactionData.from || undefined || account,
        to: createTransactionData.to || undefined,
        value: createTransactionData.value || undefined,
        gasPrice: createTransactionData.gasPrice || undefined,
        nonce: createTransactionData.nonce || undefined,
        data: createTransactionData.data
      })
      .on('transactionHash', function(hash) {
        resolve(hash)
      })
      .catch(error => reject(error))
  })
}

export async function stepSwapTransfer(
  library: Web3Provider,
  web3: Web3,
  ethereum: SwapEthereum,
  account: string,
  transData: RangoTransactionSwaps,
  requestId: string,
  step: number,
  stepCount: number,
  slippage: string,
  setMessage: (val: string) => void
) {
  console.log('🚀 ~ file: SwapFunction.ts ~ line 165 ~ transData', transData)
  let _lastHash = transData.lastHash
  let _isApproveTX = transData.isApproveTX
  let _stepStatus = transData.stepStatus
  let _stepOutputAmount = transData.stepOutputAmount
  while (true) {
    if (_stepStatus === RangoTransactionSwapStatus.RUNNING && _lastHash) {
      while (true) {
        try {
          setMessage('Transaction result pending...')
          if (_isApproveTX) {
            setMessage('Approve result pending...')
            const checkApprovalStatusRes = await checkApprovalStatus(requestId)

            if (checkApprovalStatusRes.isApproved !== true) {
              await sleep(10000)
              continue
            } else {
              setMessage('Approved')
              _stepStatus === RangoTransactionSwapStatus.SUCCESS
              _stepOutputAmount = ''
              const _newData = {
                curStep: step,
                requestId,
                account,
                lastHash: _lastHash,
                isApproveTX: _isApproveTX,
                stepStatus: _stepStatus,
                stepOutputAmount: _stepOutputAmount
              }
              store.dispatch(updateSwapStepData(_newData))
              break
            }
          } else {
            const checkTransferStatusRes = await checkTransferStatus(requestId, step, _lastHash)
            // set info
            _stepStatus = checkTransferStatusRes.status
              ? (checkTransferStatusRes.status as RangoTransactionSwapStatus)
              : RangoTransactionSwapStatus.RUNNING
            _stepOutputAmount = checkTransferStatusRes.outputAmount || ''
            const _newData = {
              curStep: step,
              requestId,
              account,
              lastHash: _lastHash,
              isApproveTX: _isApproveTX,
              stepStatus: _stepStatus,
              stepOutputAmount: _stepOutputAmount
            }
            store.dispatch(updateSwapStepData(_newData))

            if (_stepStatus === RangoTransactionSwapStatus.RUNNING) {
              await sleep(10000)
              continue
            } else {
              if (_stepStatus === RangoTransactionSwapStatus.SUCCESS) {
                setMessage('success')
                if (step === stepCount) {
                  store.dispatch(
                    updateTransactionsStatusAndStep({
                      requestId,
                      account,
                      transactionsStatus: RangoTransactionsStatusProp.SUCCESS,
                      newStep: undefined
                    })
                  )
                  return
                } else {
                  store.dispatch(
                    updateTransactionsStatusAndStep({
                      requestId,
                      account,
                      transactionsStatus: RangoTransactionsStatusProp.ACTIVATING,
                      newStep: step + 1
                    })
                  )
                  return
                }
              } else {
                setMessage('Transaction failed')
                store.dispatch(
                  updateTransactionsStatusAndStep({
                    requestId,
                    account,
                    transactionsStatus: RangoTransactionsStatusProp.FAILED,
                    newStep: undefined
                  })
                )
                return
              }
            }
          }
        } catch (_) {
          console.warn(_)
          await sleep(10000)
        }
      }
    }

    try {
      setMessage('Begin create transaction')
      _stepStatus = RangoTransactionSwapStatus.RUNNING
      const _newData = {
        curStep: step,
        requestId,
        account,
        lastHash: _lastHash,
        isApproveTX: _isApproveTX,
        stepStatus: _stepStatus,
        stepOutputAmount: _stepOutputAmount
      }
      store.dispatch(updateSwapStepData(_newData))
      const createTransactionRes = await createTransaction(requestId, step, slippage)

      if (createTransactionRes.ok === true) {
        setMessage(`Please switch to ${createTransactionRes.transaction.blockChain}`)
        // transData.fromAssets.blockchain to chainid
        const chain = rangoBlockChain(transData.fromAssets.blockchain)
          ? Number(rangoBlockChain(transData.fromAssets.blockchain)?.chainId ?? 0)
          : undefined
        if (!chain) throw new Error('chainId not support')
        await checkIsSupportChain(chain, ethereum, library, account)
        _isApproveTX = createTransactionRes.transaction.isApprovalTx
        if (createTransactionRes.transaction.isApprovalTx) {
          setMessage(`Please confirm approve in you wallet`)
        } else {
          setMessage(`Please confirm transaction in you wallet`)
        }
        try {
          const txHash = await sendTransaction(web3, createTransactionRes.transaction, account)
          //update info
          _lastHash = txHash
          const _newData = {
            curStep: step,
            requestId,
            account,
            lastHash: _lastHash,
            isApproveTX: _isApproveTX,
            stepStatus: _stepStatus,
            stepOutputAmount: _stepOutputAmount
          }
          store.dispatch(updateSwapStepData(_newData))
          store.dispatch(
            updateTransactionsStatusAndStep({
              requestId,
              account,
              transactionsStatus: RangoTransactionsStatusProp.ACTIVATING,
              newStep: undefined
            })
          )
        } catch (error) {
          console.warn('🚀 sendTransaction ~ error', error)
          setMessage(`User denied transaction signature or send failed`)
          store.dispatch(
            updateTransactionsStatusAndStep({
              requestId,
              account,
              transactionsStatus: RangoTransactionsStatusProp.CANCEL,
              newStep: undefined
            })
          )
          return
        }
      } else {
        setMessage(createTransactionRes.error || 'Error, Please try again later')
        store.dispatch(
          updateTransactionsStatusAndStep({
            requestId,
            account,
            transactionsStatus: RangoTransactionsStatusProp.CANCEL,
            newStep: undefined
          })
        )
        return
      }
    } catch (_) {
      console.warn(_)
      setMessage('Error, Please try again later')
      store.dispatch(
        updateTransactionsStatusAndStep({
          requestId,
          account,
          transactionsStatus: RangoTransactionsStatusProp.CANCEL,
          newStep: undefined
        })
      )
      return
    }
  }
}
