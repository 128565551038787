import JSBI from 'jsbi'
import { SUPPORT_PLATFORM } from 'models/platform'
import { useEffect, useMemo, useState } from 'react'
import { getMyCrossRecord } from 'utils/fetch/swap'
import { useActiveWeb3React } from '.'
import { ChainId } from '../constants/chain'
import { TokenAmount, Token } from '../constants/token'

export interface ActivityCrossRecordItemProp {
  bridge: SUPPORT_PLATFORM
  fromChain: number
  toChain: number
  fromAccount: string
  toAccount: string
  fromTXHash: string
  pairID: string
  currentToken: TokenAmount
  srcTokenAddress: string
  destTokenAddress: string
  fee: string
  feeSymbol: string
  time: number
  amount: string
  cbridgeTransferId: string
  depositAddress: string
}

export function useMyCrossActivity(): {
  loading: boolean
  page: {
    totalPages: number
    currentPage: number
    setCurrentPage: (page: number) => void
    pageSize: number
  }
  data: ActivityCrossRecordItemProp[]
} {
  const { account } = useActiveWeb3React()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [list, setList] = useState<ActivityCrossRecordItemProp[]>([])
  const pageSize = 10

  useEffect(() => {
    if (!account) return
    setCurrentPage(1)
  }, [account])

  useEffect(() => {
    ;(async () => {
      if (!account) {
        setList([])
        return
      }
      try {
        setIsLoading(true)
        setList([])
        const _recordres = ((await getMyCrossRecord(account, currentPage, pageSize)) as unknown) as any
        const recordList: ActivityCrossRecordItemProp[] = _recordres.Records.map(
          (item: {
            bridge: any
            fromChain: ChainId
            toChain: ChainId
            fromAccount: any
            toAccount: any
            fromTXHash: any
            pairID: any
            Decimals: number
            srcToken: string
            destToken: string
            Symbol: string
            amountInt: string
            destChain: ChainId
            fee: any
            feeSymbol: any
            time: number
            LogoUrl: string
            Name: string
            amount: string
            cbridgeTransferId: string
            depositAddress: string
          }) => {
            return {
              bridge: item.bridge,
              fromChain: item.fromChain as ChainId,
              toChain: item.toChain as ChainId,
              fromAccount: item.fromAccount,
              toAccount: item.toAccount,
              fromTXHash: item.fromTXHash,
              pairID: item.pairID,
              currentToken: new TokenAmount(
                new Token(item.fromChain, item.srcToken, item.Decimals, item.Symbol, item.Name, item.LogoUrl),
                JSBI.BigInt(item.amountInt)
              ),
              amount: item.amount,
              srcTokenAddress: item.srcToken,
              destTokenAddress: item.destToken,
              fee: item.fee,
              feeSymbol: item.feeSymbol,
              time: item.time,
              cbridgeTransferId: item.cbridgeTransferId ?? '',
              depositAddress: item.depositAddress ?? ''
            }
          }
        )
        setList(recordList)
        setIsLoading(false)
        _recordres && setTotalPages(Math.ceil((_recordres as any)?.TotalNumber / pageSize) ?? 0)
      } catch (error) {
        console.error('fetch useMyActivity', error)
      }
    })()
  }, [account, currentPage])

  const res = useMemo(
    () => ({ page: { totalPages, currentPage, setCurrentPage, pageSize }, loading: isLoading, data: list }),
    [currentPage, isLoading, list, totalPages]
  )
  return res
}
