import { Chain } from 'models/chain'
import { ReactComponent as ETH } from 'assets/svg/eth_logo.svg'
import EthUrl from 'assets/svg/eth_logo.svg'
import BSCInvert from '../assets/svg/binance.svg'
import { ReactComponent as BSCLogo } from '../assets/svg/binance.svg'
import { ReactComponent as AVAXLogo } from '../assets/svg/avax_logo.svg'
import AVAXUrl from '../assets/svg/avax_logo.svg'
import ArbitrumUrl from '../assets/svg/arbitrum_logo.svg'
import { ReactComponent as ArbitrumLogo } from '../assets/svg/arbitrum_logo.svg'
import HuobiUrl from '../assets/svg/huobi.svg'
import { ReactComponent as HuobiLogo } from '../assets/svg/huobi.svg'
import { ReactComponent as OptimisticLogo } from '../assets/svg/optimistic.svg'
import OptimisticUrl from '../assets/svg/optimistic.svg'
import { ReactComponent as OKExChainLogo } from '../assets/svg/OKExChain.svg'
import OKExChainUrl from '../assets/svg/OKExChain.svg'
import { ReactComponent as XDaiLogo } from '../assets/svg/xDai.svg'
import XDaiUrl from '../assets/svg/xDai.svg'
import { ReactComponent as MaticLogo } from '../assets/svg/matic.svg'
import MaticUrl from '../assets/svg/matic.svg'
import { ReactComponent as FantonLogo } from '../assets/svg/fanton.svg'
import FantonUrl from '../assets/svg/fanton.svg'
import { ReactComponent as KccLogo } from '../assets/svg/kcc.svg'
import KccUrl from '../assets/svg/kcc.svg'
import { ReactComponent as MoonriverLogo } from '../assets/svg/Moonriver.svg'
import MoonriverUrl from '../assets/svg/Moonriver.svg'
import { ReactComponent as IotexLogo } from '../assets/svg/Iotex.svg'
import IotexUrl from '../assets/svg/Iotex.svg'
import { ReactComponent as FSNLogo } from '../assets/svg/FSN.svg'
import FSNUrl from '../assets/svg/FSN.svg'
import { ReactComponent as CELOLogo } from '../assets/svg/CELO.svg'
import CELOUrl from '../assets/svg/CELO.svg'
import { ReactComponent as HARMONYLogo } from '../assets/svg/HARMONY.svg'
import HARMONYUrl from '../assets/svg/HARMONY.svg'
import { ReactComponent as TelosLogo } from '../assets/svg/Telos.svg'
import TelosUrl from '../assets/svg/Telos.svg'
import { ReactComponent as ShideLogo } from '../assets/svg/Shide.svg'
import ShideUrl from '../assets/svg/Shide.svg'
import CROUrl from '../assets/images/cro.png'
import OETHUrl from '../assets/images/OETH.png'

export enum ChainId {
  MAINNET = 1,
  CRO = 25,
  OPTIMISTIC = 10,
  TELOS = 40,
  BSC = 56,
  OKEX = 66,
  XDAI = 100,
  HECO = 128,
  MATIC = 137,
  FANTOM = 250,
  BOBA = 288,
  KCC = 321,
  SHIDEN = 336,
  MOONRIVER = 1285,
  IOTEX = 4689,
  FUSION = 32659,
  ARBITRUM = 42161,
  AVALANCHE = 43114,
  CELO = 42220,
  HARMONY = 1666600000
}

export const ChainList: Chain[] = [
  {
    icon: <ETH />,
    logo: EthUrl,
    symbol: 'ETH',
    name: 'Ethereum Mainnet',
    id: ChainId.MAINNET,
    hex: '0x1'
  },
  {
    icon: <OptimisticLogo />,
    logo: OptimisticUrl,
    symbol: 'Optimistic',
    name: 'Optimistic Ethereum',
    id: ChainId.OPTIMISTIC,
    hex: '0xa'
  },
  {
    icon: <img src={CROUrl} />,
    logo: CROUrl,
    symbol: 'CRO',
    name: 'CRO mainnet',
    id: ChainId.CRO,
    hex: '0x19'
  },
  {
    icon: <TelosLogo />,
    logo: TelosUrl,
    symbol: 'Telos',
    name: 'Telos EVM Mainnet',
    id: ChainId.TELOS,
    hex: '0x28'
  },
  {
    icon: <BSCLogo />,
    logo: BSCInvert,
    symbol: 'BSC',
    name: 'Binance Smart Chain',
    id: ChainId.BSC,
    hex: '0x38'
  },
  {
    icon: <OKExChainLogo />,
    logo: OKExChainUrl,
    symbol: 'OKEx',
    name: 'OKExChain Mainnet',
    id: ChainId.OKEX,
    hex: '0x42'
  },
  {
    icon: <XDaiLogo />,
    logo: XDaiUrl,
    symbol: 'xDai',
    name: 'xDAI Chain',
    id: ChainId.XDAI,
    hex: '0x64'
  },
  {
    icon: <HuobiLogo />,
    logo: HuobiUrl,
    symbol: 'HECO',
    name: 'Huobi ECO Chain',
    id: ChainId.HECO,
    hex: '0x80'
  },
  {
    icon: <MaticLogo />,
    logo: MaticUrl,
    symbol: 'MATIC',
    name: 'Polygon Mainnet',
    id: ChainId.MATIC,
    hex: '0x89'
  },
  {
    icon: <FantonLogo />,
    logo: FantonUrl,
    symbol: 'Fantom',
    name: 'Fantom Opera',
    id: ChainId.FANTOM,
    hex: '0xfa'
  },
  {
    icon: <img src={OETHUrl} />,
    logo: OETHUrl,
    symbol: 'Boba',
    name: 'Boba mainnet',
    id: ChainId.BOBA,
    hex: '0x120'
  },
  {
    icon: <KccLogo />,
    logo: KccUrl,
    symbol: 'KCC',
    name: 'KCC-MAINNET',
    id: ChainId.KCC,
    hex: '0x141'
  },
  {
    icon: <ShideLogo />,
    logo: ShideUrl,
    symbol: 'SDN',
    name: 'Shiden Network Mainnet',
    id: ChainId.SHIDEN,
    hex: '0x150'
  },
  {
    icon: <MoonriverLogo />,
    logo: MoonriverUrl,
    symbol: 'MOVR',
    name: 'Moonriver',
    id: ChainId.MOONRIVER,
    hex: '0x505'
  },
  {
    icon: <IotexLogo />,
    logo: IotexUrl,
    symbol: 'IoTeX',
    name: 'IoTex',
    id: ChainId.IOTEX,
    hex: '0x1251'
  },
  {
    icon: <FSNLogo />,
    logo: FSNUrl,
    symbol: 'FSN',
    name: 'Fusion',
    id: ChainId.FUSION,
    hex: '0x7f93'
  },
  {
    icon: <CELOLogo />,
    logo: CELOUrl,
    symbol: 'CELO',
    name: 'Celo Mainnet',
    id: ChainId.CELO,
    hex: '0xA4EC'
  },
  {
    icon: <HARMONYLogo />,
    logo: HARMONYUrl,
    symbol: 'ONE',
    name: 'Harmony Mainnet Shard',
    id: ChainId.HARMONY,
    hex: '0x63564C40'
  },
  {
    icon: <AVAXLogo />,
    logo: AVAXUrl,
    symbol: 'Avalanche',
    name: 'Avalanche Network',
    id: ChainId.AVALANCHE,
    hex: '0xA86A'
  },
  {
    icon: <ArbitrumLogo />,
    logo: ArbitrumUrl,
    symbol: 'Arbitrum',
    name: 'Arbitrum Network',
    id: ChainId.ARBITRUM,
    hex: '0xA4B1'
  }
]

export const ChainListMap: {
  [key: number]: { icon: JSX.Element; link?: string; selectedIcon?: JSX.Element } & Chain
} = ChainList.reduce((acc, item) => {
  acc[item.id] = item
  return acc
}, {} as any)

export interface Network {
  chainId: string
  chainNum: ChainId
  logo: string
  chainName: string
  nativeCurrency: {
    name: string
    symbol: string
    decimals: number
  }
  rpcUrls: string[]
  blockExplorerUrls: string[]
}

export const SUPPORTED_NETWORKS: {
  [chainId in ChainId]?: Network
} = {
  [ChainId.MAINNET]: {
    chainId: '0x1',
    chainNum: ChainId.MAINNET,
    logo: EthUrl,
    chainName: 'Ethereum',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: ['https://mainnet.infura.io/v3'],
    blockExplorerUrls: ['https://etherscan.com']
  },
  [ChainId.OPTIMISTIC]: {
    chainId: '0xa',
    chainNum: ChainId.OPTIMISTIC,
    logo: OptimisticUrl,
    chainName: 'Optimistic Ethereum',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: ['https://mainnet.optimism.io'],
    blockExplorerUrls: ['https://optimistic.etherscan.io']
  },
  [ChainId.CRO]: {
    chainId: '0x19',
    chainNum: ChainId.CRO,
    logo: CROUrl,
    chainName: 'CRO mainnet',
    nativeCurrency: {
      name: 'CRO',
      symbol: 'CRO',
      decimals: 18
    },
    rpcUrls: ['https://cronode1.anyswap.exchange'],
    blockExplorerUrls: ['https://cronos.crypto.org/explorer']
  },
  [ChainId.TELOS]: {
    chainId: '0x28',
    chainNum: ChainId.TELOS,
    logo: TelosUrl,
    chainName: 'Telos EVM Mainnet',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: ['https://mainnet.telos.net/evm'],
    blockExplorerUrls: ['https://www.teloscan.io/']
  },
  [ChainId.BSC]: {
    chainId: '0x38',
    chainNum: ChainId.BSC,
    logo: BSCInvert,
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18
    },
    rpcUrls: ['https://bsc-dataseed.binance.org'],
    blockExplorerUrls: ['https://bscscan.com']
  },
  [ChainId.OKEX]: {
    chainId: '0x42',
    chainNum: ChainId.OKEX,
    logo: OKExChainUrl,
    chainName: 'OKEx',
    nativeCurrency: {
      name: 'OKEx Token',
      symbol: 'OKT',
      decimals: 18
    },
    rpcUrls: ['https://exchainrpc.okex.org'],
    blockExplorerUrls: ['https://www.oklink.com/okexchain']
  },
  [ChainId.XDAI]: {
    chainId: '0x64',
    chainNum: ChainId.XDAI,
    logo: XDaiUrl,
    chainName: 'xDai',
    nativeCurrency: {
      name: 'xDai Token',
      symbol: 'xDai',
      decimals: 18
    },
    rpcUrls: ['https://rpc.xdaichain.com'],
    blockExplorerUrls: ['https://blockscout.com/poa/xdai']
  },
  [ChainId.MATIC]: {
    chainId: '0x89',
    chainNum: ChainId.MATIC,
    logo: MaticUrl,
    chainName: 'Matic',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18
    },
    rpcUrls: ['https://rpc-mainnet.maticvigil.com'], // ['https://matic-mainnet.chainstacklabs.com/'],
    blockExplorerUrls: ['https://explorer-mainnet.maticvigil.com']
  },
  [ChainId.FANTOM]: {
    chainId: '0xfa',
    chainNum: ChainId.FANTOM,
    logo: FantonUrl,
    chainName: 'Fantom',
    nativeCurrency: {
      name: 'Fantom',
      symbol: 'FTM',
      decimals: 18
    },
    rpcUrls: ['https://rpcapi.fantom.network'],
    blockExplorerUrls: ['https://ftmscan.com']
  },
  [ChainId.BOBA]: {
    chainId: '0x120',
    chainNum: ChainId.BOBA,
    logo: OETHUrl,
    chainName: 'Boba mainnet',
    nativeCurrency: {
      name: 'OETH',
      symbol: 'OETH',
      decimals: 18
    },
    rpcUrls: ['https://mainnet.boba.network'],
    blockExplorerUrls: ['https://blockexplorer.boba.network']
  },
  [ChainId.KCC]: {
    chainId: '0x141',
    chainNum: ChainId.KCC,
    logo: KccUrl,
    chainName: 'KCC-MAINNET',
    nativeCurrency: {
      name: 'KuCoin Token',
      symbol: 'KCS',
      decimals: 18
    },
    rpcUrls: ['https://rpc-mainnet.kcc.network'],
    blockExplorerUrls: ['https://explorer.kcc.io/en']
  },
  [ChainId.SHIDEN]: {
    chainId: '0x150',
    chainNum: ChainId.SHIDEN,
    logo: ShideUrl,
    chainName: 'Shiden Network Mainnet',
    nativeCurrency: {
      name: 'Shiden Network',
      symbol: 'SDN',
      decimals: 18
    },
    rpcUrls: ['https://rpc.shiden.astar.network:8545'],
    blockExplorerUrls: ['https://shiden.subscan.io']
  },
  [ChainId.MOONRIVER]: {
    chainId: '0x505',
    chainNum: ChainId.MOONRIVER,
    logo: MoonriverUrl,
    chainName: 'Moonriver',
    nativeCurrency: {
      name: 'Moonriver',
      symbol: 'MOVR',
      decimals: 18
    },
    rpcUrls: ['https://rpc.moonriver.moonbeam.network'],
    blockExplorerUrls: ['https://blockscout.moonriver.moonbeam.network']
  },
  [ChainId.IOTEX]: {
    chainId: '0x1251',
    chainNum: ChainId.IOTEX,
    logo: IotexUrl,
    chainName: 'IoTex',
    nativeCurrency: {
      name: 'IoTeX',
      symbol: 'IOTX',
      decimals: 18
    },
    rpcUrls: ['https://babel-api.mainnet.iotex.io/'],
    blockExplorerUrls: ['https://iotexscan.io/']
  },
  [ChainId.FUSION]: {
    chainId: '0x7f93',
    chainNum: ChainId.FUSION,
    logo: FSNUrl,
    chainName: 'Fusion',
    nativeCurrency: {
      name: 'Fusion',
      symbol: 'FSN',
      decimals: 18
    },
    rpcUrls: ['https://mainnet.anyswap.exchange'],
    blockExplorerUrls: ['https://fsnex.com/']
  },
  [ChainId.CELO]: {
    chainId: '0xA4EC',
    chainNum: ChainId.CELO,
    logo: CELOUrl,
    chainName: 'Celo',
    nativeCurrency: {
      name: 'Celo',
      symbol: 'CELO',
      decimals: 18
    },
    rpcUrls: ['https://forno.celo.org'],
    blockExplorerUrls: ['https://explorer.celo.org']
  },
  [ChainId.HARMONY]: {
    chainId: '0x63564C40',
    chainNum: ChainId.HARMONY,
    logo: HARMONYUrl,
    chainName: 'Harmony',
    nativeCurrency: {
      name: 'One Token',
      symbol: 'ONE',
      decimals: 18
    },
    rpcUrls: [
      'https://api.harmony.one',
      'https://s1.api.harmony.one',
      'https://s2.api.harmony.one',
      'https://s3.api.harmony.one'
    ],
    blockExplorerUrls: ['https://explorer.harmony.one/']
  },
  [ChainId.AVALANCHE]: {
    chainId: '0xA86A',
    chainNum: ChainId.AVALANCHE,
    logo: AVAXUrl,
    chainName: 'Avalanche',
    nativeCurrency: {
      name: 'Avalanche Token',
      symbol: 'AVAX',
      decimals: 18
    },
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://cchain.explorer.avax.network']
  },
  [ChainId.ARBITRUM]: {
    chainId: '0xA4B1',
    chainNum: ChainId.ARBITRUM,
    logo: ArbitrumUrl,
    chainName: 'Arbitrum',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://mainnet-arb-explorer.netlify.app']
  },
  [ChainId.HECO]: {
    chainId: '0x80',
    chainNum: ChainId.HECO,
    logo: HuobiUrl,
    chainName: 'Huobi ECO Chain Mainnet',
    nativeCurrency: {
      name: 'Huobi Token',
      symbol: 'HT',
      decimals: 18
    },
    rpcUrls: ['https://http-mainnet.hecochain.com'],
    blockExplorerUrls: ['https://hecoinfo.com/']
  }
}

export const POLY_MAPPING_CHAINID: { [key in number]: number } = {
  56: 6,
  42161: 19,
  43114: 21,
  100: 20,
  10: 23,
  1: 2,
  58: 3,
  128: 7,
  66: 12,
  137: 17,
  250: 22
}

export const STARGATE_MAPPING_CHAINID: { [key in number]: number } = {
  1: 1,
  56: 2,
  43114: 6,
  137: 9,
  42161: 10,
  10: 11,
  250: 12
}

export function rangoBlockChain(name: string) {
  switch (name) {
    case 'ETH':
      return SUPPORTED_NETWORKS[1]
    case 'BSC':
      return SUPPORTED_NETWORKS[56]
    case 'POLYGON':
      return SUPPORTED_NETWORKS[137]
    case 'FANTOM':
      return SUPPORTED_NETWORKS[250]
    case 'ARBITRUM':
      return SUPPORTED_NETWORKS[42161]
    case 'CELO':
      return SUPPORTED_NETWORKS[42220]
    case 'MOONRIVER':
      return SUPPORTED_NETWORKS[1285]
    case 'XDAI':
      return SUPPORTED_NETWORKS[100]
    case 'HARMONY':
      return SUPPORTED_NETWORKS[1666600000]
    case 'AVAX_CCHAIN':
      return SUPPORTED_NETWORKS[43114]
    case 'OPTIMISM':
      return SUPPORTED_NETWORKS[10]
    default:
      return undefined
  }
}
