import { ButtonBase, Stepper, StepConnector, Box, Typography, Step, styled, Divider } from '@mui/material'
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { SerializableRangoTransactions } from 'state/rangoTransactions/actions'
import StepperItem from './StepperItem'
import CloseIcon from '@mui/icons-material/Close'
import { Swapper } from 'utils/fetch/swap'
import useBreakpoint from 'hooks/useBreakpoint'
import SmallButton from 'components/Button/SmallButton'
import Copy from 'components/essential/Copy'
import { ExternalLink } from 'theme/components'

const DeleteTooltip = styled(Box)({
  display: 'none',
  position: 'absolute',
  right: 0,
  top: 0,
  width: '100%',
  zIndex: 10000,
  backgroundColor: '#fff',
  padding: '10px 15px'
})

interface Props {
  title: string
  transactions: SerializableRangoTransactions
  onHide: () => void
  progressRate?: number
  swappers: Swapper[] | undefined
}

const labelWidth = 28

export default function JourneyNotification(props: Props) {
  const { title, transactions, onHide, progressRate, swappers } = props
  const isDownMd = useBreakpoint('md')

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          md: '380px'
        },
        borderRadius: {
          xs: 0,
          md: '8px'
        },
        position: isDownMd ? 'relative' : 'absolute',
        right: 0,
        top: 0,
        zIndex: 1,
        boxShadow: '0 0 5px #aaa',
        background: '#FFFFFF',
        overflow: 'hidden'
      }}
    >
      <Box
        sx={{
          width: '100%',
          padding: '16px 16px 0'
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={14}>
          <Typography fontWeight={700}>{title}</Typography>
          <Box
            sx={{
              '&:hover': {
                '.delete-tooltip': {
                  display: 'flex'
                }
              }
            }}
          >
            <DeleteTooltip className="delete-tooltip">
              <Typography variant="body1">
                This record will disappear after you remove it. Are you sure you want to remove it?{' '}
                <SmallButton onClick={onHide}>Remove</SmallButton>
              </Typography>
            </DeleteTooltip>
            <ButtonBase>
              <CloseIcon fontSize="small" style={{ transform: 'rotate(-90deg)' }} />
            </ButtonBase>
          </Box>
        </Box>

        <Stepper
          orientation="vertical"
          connector={
            <StepConnector
              sx={{
                ml: labelWidth ? labelWidth / 2 : 20,
                '& .MuiStepConnector-line': {
                  borderLeft: '1px dashed rgba(0, 0, 0, 0.2)',
                  minHeight: 12
                }
              }}
            />
          }
        >
          {transactions.swaps.map((item, index) => (
            <Step key={index}>
              <StepperItem
                key={index}
                swappers={swappers}
                stepCount={transactions.stepCount}
                currentTransStep={transactions.currentStep}
                slippage={transactions.slippage}
                globalStatus={transactions.transactionsStatus}
                selfStep={index + 1}
                requestId={transactions.requestId}
                transData={item}
                labelWidth={labelWidth}
              ></StepperItem>
            </Step>
          ))}
        </Stepper>
        {/* <Typography mt={16}>
            <ExternalLink underline="always" href={detailLink}>
              View Details
            </ExternalLink>
          </Typography> */}
        <Divider sx={{ mt: 10, mb: 10 }} />
        <Box display={'flex'}>
          <Typography variant="body2">RequestID: {transactions.requestId}</Typography>
          <Copy toCopy={transactions.requestId} />
        </Box>
        <Box mt={5}>
          <Typography variant="body2">
            If you encounter any swap problems, please go{' '}
            <ExternalLink underline="always" href="https://t.me/rangoexchange">
              here
            </ExternalLink>{' '}
            to ask with your RequestID.
          </Typography>
        </Box>
      </Box>
      <Box sx={{ borderBottom: '2px solid #3AC261', width: progressRate || 0, mt: 18 }} />
    </Box>
  )
}
