// import { useCallback } from 'react'
import { Box, Typography } from '@mui/material'
// import { RoundButton } from 'components/Button/Button'
// import { RoundOutlineButton } from 'components/Button/OutlineButton'
import Input from 'components/Input/index'
// import { ReactComponent as CurrencyLogo } from 'assets/svg/eth_logo.svg'
// import { ReactComponent as CoinGecko } from 'assets/svg/coingecko_logo.svg'
import { ReactComponent as ArrowUpperRight } from 'assets/componentsIcon/arrow_upper_right.svg'
import Card from 'components/Card'
import { ExternalLink } from 'theme/components'
import Image from 'components/Image'
// import QuestionHelper from 'components/essential/QuestionHelper'
import { CrossChainProp } from 'models/platform'
import { getEtherscanLink } from 'utils'
import CurrencyLogo from 'components/essential/CurrencyLogo'
import isZero from 'utils/isZero'
import { PlatformInfo } from 'constants/PlatformInfo'
import { ChainListMap } from 'constants/chain'
import useBreakpoint from 'hooks/useBreakpoint'

interface Props {
  bridge: CrossChainProp | undefined
  showMinAmountStr: string
  showMaxAmountStr: string
  fees: string
}

export default function Information({ bridge, showMinAmountStr, showMaxAmountStr, fees }: Props) {
  const isDownMd = useBreakpoint('md')
  // const theme = useTheme()

  // const getBridgeOptions = useCallback(() => {
  //   const options = bridgeOptions ? bridgeOptions.filter(option => option !== bridge) : []

  //   return (
  //     <Box display="flex" justifyContent="space-between" mt={24}>
  //       <Image src={options[0].logo} alt={`${options[0].name}-logo`} />
  //       <RoundButton width={100} height={28} fontSize={12} fontWeight={400} backgroundColor="rgba(22, 22, 22, 0.1)">
  //         Other Options
  //       </RoundButton>
  //     </Box>
  //   )
  // }, [bridge, bridgeOptions])

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          md: 380
        },
        minHeight: 600,
        marginTop: {
          sm: 20,
          md: 0
        },
        marginBottom: {
          xs: 37,
          md: 0
        }
      }}
      display="grid"
      gap={20}
      gridTemplateRows="minmax(270px, 300px) 1fr"
    >
      <Card width="100%" padding={isDownMd ? '32px 20px' : '24px 52px'} grey>
        <Typography variant="h5" mb={10}>
          Token Information
        </Typography>
        {bridge ? (
          <>
            <Box mb="20px" display="grid" gap="9px">
              <Typography variant="body1" display="flex" alignContent="center" gap="10px">
                <Image height="20px" src={ChainListMap[bridge.srcToken.chainId].logo} alt="" />
                {ChainListMap[bridge.srcToken.chainId].name}
              </Typography>
              <Box
                padding="12px"
                height="48px"
                display="flex"
                justifyContent="space-between"
                style={{ background: 'rgba(116, 104, 190, 0.1)', borderRadius: 10 }}
              >
                <Box display="flex" alignItems="center" gap="12px">
                  <CurrencyLogo currency={bridge.srcToken} />
                  <Typography fontSize={16} fontWeight={500}>
                    {bridge.srcToken.symbol}
                  </Typography>
                </Box>
                {bridge?.srcToken.address && !isZero(bridge?.srcToken.address) && (
                  <Box display="flex" alignItems="center">
                    {/* <CoinGecko /> */}
                    <ExternalLink
                      href={getEtherscanLink(bridge?.srcToken.chainId ?? 1, bridge?.srcToken.address || '', 'token')}
                    >
                      <ArrowUpperRight style={{ marginLeft: 5 }} />
                    </ExternalLink>
                  </Box>
                )}
              </Box>
            </Box>
            <Box mb="20px" display="grid" gap="9px">
              <Typography variant="body1" display="flex" alignContent="center" gap="10px">
                <Image height="20px" src={ChainListMap[bridge.destToken.chainId].logo} alt="" />
                {ChainListMap[bridge.destToken.chainId].name}
              </Typography>
              <Box
                padding="12px"
                height="48px"
                display="flex"
                justifyContent="space-between"
                style={{ background: 'rgba(116, 104, 190, 0.1)', borderRadius: 10 }}
              >
                <Box display="flex" alignItems="center" gap="12px">
                  <CurrencyLogo currency={bridge.destToken} />
                  <Typography fontSize={16} fontWeight={500}>
                    {bridge.destToken.symbol}
                  </Typography>
                </Box>
                {bridge?.destToken.address && !isZero(bridge?.destToken.address) && (
                  <Box display="flex" alignItems="center">
                    {/* <CoinGecko /> */}
                    <ExternalLink
                      href={getEtherscanLink(bridge?.destToken.chainId ?? 1, bridge?.destToken.address || '', 'token')}
                    >
                      <ArrowUpperRight style={{ marginLeft: 5 }} />
                    </ExternalLink>
                  </Box>
                )}
              </Box>
            </Box>
          </>
        ) : (
          <Box mt={32}>
            <Box display="flex">
              <Typography textAlign="center" mr={5}>
                No Token is selected.
              </Typography>
            </Box>
          </Box>
        )}
      </Card>
      <Card width="100%" padding={isDownMd ? '32px 20px' : '24px 52px'} grey>
        <Typography variant="h5" mb={32} mt={10}>
          Bridge Information
        </Typography>
        {bridge ? (
          <>
            <Box display="flex" alignItems="center" mb={12} gap="10px">
              <Image
                height="24px"
                src={PlatformInfo[bridge.platform]?.logo}
                alt={`${PlatformInfo[bridge.platform]?.name}-logo`}
              />
              {PlatformInfo[bridge.platform]?.name}
              {/* <QuestionHelper text="...." /> */}
            </Box>

            <Typography sx={{ opacity: 0.5 }} lineHeight="15px" fontSize={12}>
              <Box
                sx={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 5,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {PlatformInfo[bridge.platform]?.intro}
              </Box>
              <ExternalLink underline="always" style={{ fontWeight: 500 }} href={PlatformInfo[bridge.platform].link}>
                More
              </ExternalLink>
            </Typography>

            <Box display="grid" gap="24px" mt={28}>
              <Box display="grid" gap="10px">
                <Typography fontSize={14} fontWeight={500}>
                  Daily quota per address
                </Typography>
                <Input value={'Unlimited'} disabled background="rgba(116, 104, 190, .1)" />
              </Box>
              <Box display="grid" gap="10px">
                <Typography fontSize={14} fontWeight={500}>
                  Transaction limit
                </Typography>
                <Box
                  padding="12px 24px"
                  minHeight="48px"
                  flexWrap="wrap"
                  display="flex"
                  justifyContent="space-between"
                  style={{ background: 'rgba(116, 104, 190, 0.1)', borderRadius: 10 }}
                >
                  <Typography fontSize={14} fontWeight={500}>
                    {showMinAmountStr}
                  </Typography>
                  <Typography fontSize={14} fontWeight={500}>
                    {showMaxAmountStr}
                  </Typography>
                </Box>
              </Box>
              <Box display="grid" gap="10px">
                <Typography fontSize={14} fontWeight={500}>
                  Fees
                </Typography>
                <Input value={fees} disabled background="rgba(116, 104, 190, .1)" />
              </Box>
            </Box>

            {/* <Typography fontSize={12} mt={12} color="rgba(22, 22, 22, 0.5)">
              percentage of that transfer
            </Typography>
            <div>{getBridgeOptions()}</div> */}
          </>
        ) : (
          <Box mt={32}>
            <Box display="flex">
              <Typography textAlign="center" mr={5}>
                No bridge is selected.
              </Typography>
              {/* <ExternalLink href="#" underline="always" sx={{ fontSize: 14, fontWeight: 400 }}>
                What is a bridge?
              </ExternalLink> */}
            </Box>
            {/* <Box mt={24}>
              <RoundOutlineButton width={120} height={32} fontSize={14}>
                user guide
              </RoundOutlineButton>
            </Box> */}
          </Box>
        )}
      </Card>
    </Box>
  )
}
