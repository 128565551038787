import { useCallback, MutableRefObject } from 'react'
import { FixedSizeList } from 'react-window'
import { Box, Typography, styled } from '@mui/material'
import { Currency } from 'constants/token'
import { Mode } from './SelectCurrencyModal'
import useModal from 'hooks/useModal'
import CurrencyLogo from 'components/essential/CurrencyLogo'

interface Props {
  selectedCurrency?: Currency | null
  mode?: Mode
  onSelectCurrency: (currency: Currency) => void
  currencyOptions: Currency[]
  fixedListRef?: MutableRefObject<FixedSizeList | undefined>
}

const ListItem = styled('div')({
  display: 'flex',
  cursor: 'pointer',
  padding: '0 32px',
  height: '48px',
  justifyContent: 'space-between'
})

export default function CurrencyList({
  selectedCurrency,
  mode,
  onSelectCurrency,
  currencyOptions,
  fixedListRef
}: Props) {
  const { hideModal } = useModal()

  const currencyKey = useCallback((currency: Currency): string => {
    return currency ? currency.symbol || '' : ''
  }, [])

  const itemKey = useCallback((index: number, data: Currency[]) => currencyKey(data[index]), [currencyKey])

  const Row = useCallback(
    ({ data, index, style }: any) => {
      const currency: Currency = data[index]
      const onClickCurrency = () => {
        currency && onSelectCurrency(currency)
        hideModal()
      }

      return (
        <ListItem
          style={{ ...style, opacity: selectedCurrency !== currency ? 1 : 0.5 }}
          onClick={mode === Mode.SELECT && selectedCurrency !== currency ? onClickCurrency : () => {}}
        >
          <Box display="flex">
            <CurrencyLogo currency={currency} style={{ width: '30px', height: '30px' }} />
            <Box display="flex" flexDirection="column" marginLeft="16px">
              <Typography variant="inherit">{currency.symbol}</Typography>
              <Typography variant="caption">{currency.name}</Typography>
            </Box>
          </Box>
          {/* {mode === Mode.SELECT && <span style={{ fontWeight: 500 }}>{0}</span>} */}
        </ListItem>
      )
    },
    [mode, selectedCurrency, onSelectCurrency, hideModal]
  )

  return (
    <FixedSizeList
      ref={fixedListRef as any}
      height={290}
      width="100%"
      itemCount={currencyOptions.length}
      itemSize={48}
      itemData={currencyOptions}
      itemKey={itemKey}
    >
      {Row}
    </FixedSizeList>
  )
}
