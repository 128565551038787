import { useState, useEffect } from 'react'
import { useActiveWeb3React } from 'hooks'
import { fetchAccountPositions } from 'utils/fetch/blockVision'
import { ChainId } from 'constants/chain'
import { fetchAccountTokenList } from 'utils/fetch/debank'
// import { useTokenBalances } from 'state/wallet/hooks'
// import { ChainId } from 'constants/chain'
import { isAddress } from 'utils'

export type PositionType = {
  blockNumber: string
  contractAddress: string
  value: number
}

export function useERC20AccountPosition(pageIndex: number) {
  const { account, chainId } = useActiveWeb3React()
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<{
    positions: PositionType[]
    nextPageIndex: number
  }>({
    positions: [],
    nextPageIndex: 0
  })

  useEffect(() => {
    ;(async () => {
      if (!account || !chainId || chainId !== ChainId.MAINNET) {
        setResult({
          positions: [],
          nextPageIndex: 0
        })
        return
      }
      setLoading(true)
      fetchAccountPositions(account, pageIndex)
        .then(resp => {
          if (resp.status === 200) {
            setLoading(false)
            setResult({
              positions: resp.data['result']['data'],
              nextPageIndex: resp.data['result']['nextPageIndex']
            })
          }
        })
        .catch(err => {
          setLoading(false)
          setResult({
            positions: [],
            nextPageIndex: 0
          })
          console.error('ERC20AccountPosition~ error', err)
        })
    })()
  }, [account, chainId, pageIndex])

  return { loading, result }
}

// https://openapi.debank.com/v1/user/token_list?id=0x62214327CB0CB4041F84e6B7e6FeC6418af26F34&chain_id=eth&is_all=true&has_balance=true
export function useDebankERC20Asset(chainId: ChainId) {
  const { account } = useActiveWeb3React()
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<
    {
      isEther: boolean
      chainId: ChainId
      address: string
      decimals: number
      symbol: string
      name: string
      logoUrl: string
      amount: number
      raw_amount: number
      price: number | null
    }[]
  >([])

  useEffect(() => {
    ;(async () => {
      setResult([])
      if (!account || !chainId) {
        setResult([])
        return
      }
      setLoading(true)
      try {
        const res = ((await fetchAccountTokenList(account, chainId)) as unknown) as any[]
        const reData = res.map(item => {
          return {
            isEther: isAddress(item.id) ? false : true,
            chainId,
            address: item.id,
            decimals: item.decimals,
            symbol: item.symbol,
            name: item.name,
            logoUrl: item.logo_url || '',
            amount: item.amount,
            raw_amount: item.raw_amount,
            price: item.price
          }
        })
        setResult(reData)
      } catch (error) {
        setResult([])
        console.error(error)
      }
      setLoading(false)
    })()
  }, [account, chainId])

  return {
    loading,
    result
  }
}
