import { Box, Grid, styled, Typography } from '@mui/material'
import useBreakpoint from 'hooks/useBreakpoint'
import { ReactComponent as StatisticsIcon } from 'assets/componentsIcon/statistics_icon.svg'
import Card, { OutlinedCard } from 'components/Card'
// import Image from 'components/Image'
// import { ChainListMap } from 'constants/chain'
import { rgba } from 'polished'
import AreaChart from 'components/Chart'
import { useStatInfo } from 'hooks/useStatInfo'
import { toFormatGroup, toFormatMillion } from 'utils'
import CurrencyLogo from 'components/essential/CurrencyLogo'
import Spinner from 'components/Spinner'

const StyledTag = styled('span')({
  background: '#F3F3F3',
  borderRadius: 46,
  padding: '0 14px',
  fontSize: 13,
  lineHeight: '36px',
  height: 36,
  color: rgba(0, 0, 0, 0.6)
})

export default function Stat() {
  const { data: statInfo } = useStatInfo()
  const isDownMd = useBreakpoint('md')
  return (
    <Box
      sx={{
        padding: {
          xs: '0 15px'
        }
      }}
      width="100%"
      maxWidth={1192}
    >
      <Box display="flex" gap={19.9} alignItems="center" mb={isDownMd ? 24 : 36}>
        <StatisticsIcon />
        <Typography fontSize={isDownMd ? 24 : 32} fontWeight={700}>
          Statistics
        </Typography>
      </Box>

      <Box display="grid" gap={isDownMd ? 24 : 48}>
        <OutlinedCard>
          <Box padding={isDownMd ? '24px 12px' : 24} display="grid" gap="40px">
            <Typography fontSize={isDownMd ? 20 : 24} fontWeight={700}>
              Bridge Aggregator
            </Typography>
            <Grid container spacing={isDownMd ? 12 : 20} columns={10}>
              <Grid item lg={4} xs={10}>
                <Card color="rgba(0, 0, 0, 1)" height={isDownMd ? 100 : 160} padding={isDownMd ? 20 : '28px 24px'}>
                  <Box display="grid">
                    <Typography fontSize={isDownMd ? 12 : 16} color={'#fff'} sx={{ opacity: 0.6 }}>
                      Total Trade Volume
                    </Typography>
                    <Typography fontSize={isDownMd ? 28 : 32} color="#fff" fontWeight={700} mt={isDownMd ? 10 : 28}>
                      {statInfo && statInfo.totalVolume ? toFormatGroup(statInfo?.totalVolume) : '--'}
                      <span style={{ fontSize: 16, marginLeft: 5 }}>$</span>
                    </Typography>
                  </Box>
                </Card>
              </Grid>
              <Grid item lg={3} xs={10}>
                <Card color="#E6E5F2" height={isDownMd ? 100 : 160} padding={isDownMd ? 20 : '28px 24px'}>
                  <Box display="grid">
                    <Typography fontSize={isDownMd ? 12 : 16} color={'#161616'} sx={{ opacity: 0.6 }}>
                      24h Volume
                    </Typography>
                    <Typography fontSize={isDownMd ? 28 : 32} color="#161616" fontWeight={700} mt={isDownMd ? 10 : 28}>
                      {statInfo && statInfo.txVolume24h ? toFormatGroup(statInfo?.txVolume24h) : '--'}
                      <span style={{ fontSize: 16, marginLeft: 5 }}>$</span>
                    </Typography>
                  </Box>
                </Card>
              </Grid>
              <Grid item lg={3} xs={10}>
                <Card color="#E6E5F2" height={isDownMd ? 100 : 160} padding={isDownMd ? 20 : '28px 24px'}>
                  <Box display="grid">
                    <Typography fontSize={isDownMd ? 12 : 16} color={'#161616'} sx={{ opacity: 0.6 }}>
                      24h Txns
                    </Typography>
                    <Typography fontSize={isDownMd ? 28 : 32} color="#161616" fontWeight={700} mt={isDownMd ? 10 : 28}>
                      {statInfo && statInfo.txNumber24h ? toFormatGroup(statInfo?.txNumber24h, 0) : '--'}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </OutlinedCard>

        <Box>
          {statInfo?.dayVolume ? (
            <AreaChart id="transaction-volume" unit="USDT" height={280} areaSeriesData={statInfo?.dayVolume || []} />
          ) : (
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={280}>
              <Spinner size="40px" />
            </Box>
          )}
        </Box>

        <OutlinedCard style={{ marginBottom: 50 }}>
          <Box padding={isDownMd ? 12 : 24} display="grid" gap={isDownMd ? 20 : 40}>
            <Box>
              <Typography fontSize={24} fontWeight={700}>
                Total Value Locked
              </Typography>
              <Typography fontSize={16}>These assets are currently locked by the Token Bridge contracts</Typography>
            </Box>
            {!isDownMd && (
              <Box>
                <Box
                  display={'grid'}
                  gridTemplateColumns={'1fr 1fr 0.9fr 2fr'}
                  alignItems={'center'}
                  gap={10}
                  padding="0 24px"
                  mb={20}
                >
                  <Typography color={'rgba(22, 22, 22, 0.5)'} fontSize={12}>
                    Token
                  </Typography>
                  {/* <Typography color={'rgba(22, 22, 22, 0.5)'} fontSize={12}>
                    Chain
                  </Typography> */}
                  <Typography color={'rgba(22, 22, 22, 0.5)'} fontSize={12}>
                    Amount
                  </Typography>
                  <Typography color={'rgba(22, 22, 22, 0.5)'} fontSize={12}>
                    Total Value(USD)
                  </Typography>
                  <Typography color={'rgba(22, 22, 22, 0.5)'} fontSize={12}>
                    Supported Bridge
                  </Typography>
                </Box>
                {statInfo?.tokenVolume.map(item => (
                  <Box
                    key={item.token.symbol}
                    display={'grid'}
                    gap={10}
                    gridTemplateColumns={'1fr 1fr 0.9fr 2fr'}
                    alignItems={'center'}
                    border={'1px solid rgba(66, 63, 71, 0.1)'}
                    borderRadius={'16px'}
                    padding="0 24px"
                    height={80}
                    mb={10}
                  >
                    <Box display={'flex'} alignItems={'center'} gap={5}>
                      <CurrencyLogo size="32px" currency={item.token}></CurrencyLogo>
                      <Typography>{item.token.symbol}</Typography>
                    </Box>
                    {/* <Box display={'flex'} alignItems={'center'} gap={5}>
                      <Image height={32} width={32} src={ChainListMap[item.chainId].logo}></Image>
                      <Typography>{ChainListMap[item.chainId].symbol}</Typography>
                    </Box> */}
                    <Box display="flex" alignItems="flex-end">
                      <Typography fontSize="16px" color="rgba(0, 0, 0, 1)">
                        {item.amount}
                      </Typography>
                    </Box>
                    <Typography fontWeight={500} fontSize={16}>
                      {'$' + toFormatMillion(item.volume)}
                    </Typography>
                    <Box
                      display={'flex'}
                      gap={10}
                      sx={{
                        overflowX: 'auto',
                        '&::-webkit-scrollbar': {
                          display: 'none'
                        }
                      }}
                    >
                      {item.platforms.map(plat => (
                        <StyledTag key={plat}>{plat}</StyledTag>
                      ))}
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
            {isDownMd &&
              statInfo?.tokenVolume.map(item => (
                <Box
                  key={item.token.symbol}
                  border={'1px solid rgba(66, 63, 71, 0.1)'}
                  borderRadius={'20px'}
                  display={'grid'}
                  gap={10}
                  padding="20px"
                  mb={10}
                >
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography color={'rgba(22, 22, 22, 0.5)'} fontSize={16}>
                      Token
                    </Typography>
                    <Box display={'flex'} alignItems={'center'} gap={5}>
                      <CurrencyLogo size="20px" currency={item.token}></CurrencyLogo>
                      <Typography>{item.token.symbol}</Typography>
                    </Box>
                  </Box>
                  {/* <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography color={'rgba(22, 22, 22, 0.5)'} fontSize={16}>
                      Chain
                    </Typography>
                    <Box display={'flex'} alignItems={'center'} gap={5}>
                      <Image height={20} width={20} src={ChainListMap[item.chainId].logo}></Image>
                      <Typography>{ChainListMap[item.chainId].symbol}</Typography>
                    </Box>
                  </Box> */}
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography color={'rgba(22, 22, 22, 0.5)'} fontSize={16}>
                      Amount
                    </Typography>
                    <Typography fontSize="16px" color="rgba(0, 0, 0, 1)">
                      {item.amount}
                    </Typography>
                  </Box>

                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography color={'rgba(22, 22, 22, 0.5)'} fontSize={16}>
                      Total Value (USD)
                    </Typography>
                    <Typography fontWeight={500} fontSize={16}>
                      {'$' + toFormatMillion(item.volume)}
                    </Typography>
                  </Box>

                  <Box display={'flex'} flexWrap={'wrap'} gap={10}>
                    {item.platforms.map(plat => (
                      <StyledTag key={plat}>{plat}</StyledTag>
                    ))}
                  </Box>
                </Box>
              ))}
          </Box>
        </OutlinedCard>
      </Box>
    </Box>
  )
}
