import React, { ChangeEvent, InputHTMLAttributes } from 'react'
import { InputBase, styled, useTheme } from '@mui/material'
import { inputBaseClasses } from '@mui/material/InputBase'
import InputLabel from './InputLabel'

export interface InputProps {
  placeholder?: string
  value: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  label?: string
  disabled?: boolean
  focused?: boolean
  outlined?: boolean
  type?: string
  endAdornment?: React.ReactNode
  startAdornment?: React.ReactNode
  maxWidth?: string | number
  background?: string
  error?: boolean
  height?: string | number
}

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  [`&.${inputBaseClasses.root}`]: {
    fontSize: 16,
    fontFamily: 'Helvetica Neue',
    fontWeight: 400,
    backgroundColor: theme.palette.background.default,

    paddingLeft: 20,
    borderRadius: 14
  },
  [`&.${inputBaseClasses.focused}`]: { border: `1px solid ${theme.palette.primary.main} !important` },
  [`& .${inputBaseClasses.input}`]: {
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none'
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none'
    }
  },
  [`&.${inputBaseClasses.disabled}`]: {
    cursor: 'not-allowed'
  }
}))

export default function Input({
  focused,
  placeholder,
  onChange,
  value,
  disabled,
  type,
  outlined,
  endAdornment,
  startAdornment,
  maxWidth,
  label,
  background,
  error,
  height,
  ...rest
}: InputProps & Omit<InputHTMLAttributes<HTMLInputElement>, 'color' | 'outline' | 'size' | 'error' | 'height'>) {
  const theme = useTheme()

  return (
    <div style={{ width: '100%', maxWidth: maxWidth || 'unset' }}>
      {label && <InputLabel>{label}</InputLabel>}
      <StyledInputBase
        sx={{
          [`&.${inputBaseClasses.root}`]: {
            border: `1px solid ${error ? theme.palette.error.main : outlined ? 'rgba(0,0,0,.1)' : 'transparent'}`,
            background: background || theme.palette.background.default,
            height: height ?? 48
          }
        }}
        fullWidth={true}
        placeholder={placeholder}
        inputRef={input => input && focused && input.focus()}
        onChange={onChange}
        value={value}
        disabled={disabled}
        type={type}
        endAdornment={endAdornment && <span style={{ paddingRight: 20 }}>{endAdornment}</span>}
        startAdornment={startAdornment && <span style={{ paddingRight: 16 }}>{startAdornment}</span>}
        {...rest}
      />
    </div>
  )
}
