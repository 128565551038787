import { useEffect, useMemo, useState } from 'react'
import { BridgeSymbiosisDirection, SUPPORT_PLATFORM } from '../models/platform'
import { fetchPolyNetworkFee } from 'utils/fetch/swap'
import { fetchBiconomyNetworkFee, fetchBiconomyPoolInfo } from 'utils/fetch/biconomy'
import { POLY_MAPPING_CHAINID, STARGATE_MAPPING_CHAINID } from 'constants/chain'
import { fetchCbridgeEstimateAmtResult } from 'utils/fetch/cbridge'
import { fetchBridgeFee } from 'utils/fetch/symbiosis'
import { CurrencyAmount, Token, TokenAmount } from 'constants/token'
import { useBlockNumber } from 'state/application/hooks'
import { useContract } from './useContract'
import CBRIDGE_ABI from 'constants/abis/bridge/cbridge.json'
import STARGAGE_ABI from 'constants/abis/bridge/stargate_route.json'
import Symbiosis_portal_ABI from 'constants/abis/bridge/symbiosis_portal.json'
import Symbiosis_synthesis_ABI from 'constants/abis/bridge/symbiosis_synthesis.json'
import STARGAGE_FACTORY_ABI from 'constants/abis/bridge/stargate_factory.json'
import STARGAGE_POOL_ABI from 'constants/abis/bridge/stargate_pool.json'
import { useSingleCallResult } from 'state/multicall/hooks'
import { useActiveWeb3React } from 'hooks'
import JSBI from 'jsbi'
import { calculateAddMargin, getExternalId, getInternalId } from 'utils'

interface FeeProp {
  fee: number
  polyFluidity?: string
}

async function getPolyNetworkFee(
  fromChainId: number,
  toChainId: number,
  fromToken: string,
  toToken: string
): Promise<FeeProp | undefined> {
  const _from = POLY_MAPPING_CHAINID[fromChainId]
  const _to = POLY_MAPPING_CHAINID[toChainId]
  if (!_from || !_to) {
    return undefined
  }

  try {
    const res: any = await fetchPolyNetworkFee(
      _from,
      _to,
      '0000000000000000000000000000000000000000',
      fromToken.replace('0x', '')
    )
    // const feeInt = res.TokenAmountWithPrecision.split('.')[0]
    return {
      fee: res.TokenAmount,
      polyFluidity: Number(res.Balance).toString()
      // feeInt
    }
  } catch (error) {
    setTimeout(() => getPolyNetworkFee(fromChainId, toChainId, fromToken, toToken), 5000)
    return undefined
  }
}

async function getBiconomyNetworkFee(
  fromChainId: number,
  toChainId: number,
  fromToken: string,
  amount: string
): Promise<FeeProp | undefined> {
  try {
    const res: any = await fetchBiconomyNetworkFee(fromChainId, toChainId, fromToken, amount)
    // const feeInt = res.TokenAmountWithPrecision.split('.')[0]
    if (res.code !== 200) throw new Error('')
    return {
      fee: res.netTransferFee
      // maxAmount: res.Balance,
      // feeInt
    }
  } catch (error) {
    setTimeout(async () => await fetchBiconomyNetworkFee(fromChainId, toChainId, fromToken, amount), 5000)
    return undefined
  }
}

export function useStargateNetworkFee(
  platform: SUPPORT_PLATFORM | undefined,
  depositAddress: string | undefined,
  toChainId: number | undefined,
  account: string | null | undefined
) {
  const contract = useContract(depositAddress, STARGAGE_ABI)

  const fee = useSingleCallResult(
    platform === SUPPORT_PLATFORM.STARGATE && toChainId && account ? contract : null,
    'quoteLayerZeroFee',
    [toChainId ? STARGATE_MAPPING_CHAINID[toChainId] : 1, 1, account || '', '0x', [0, 0, account || '']]
  )

  return useMemo(() => {
    if (!fee.result) {
      return undefined
    }
    return CurrencyAmount.ether(calculateAddMargin(fee.result[0].toString()))
  }, [fee])
}

export function useSwapFeeResult(
  platform: SUPPORT_PLATFORM | undefined,
  fromChainId: number | undefined,
  toChainId: number | undefined,
  fromToken: string | undefined,
  toToken: string | undefined,
  amount: string | undefined
): {
  loading: boolean
  result: FeeProp | undefined
} {
  const [result, setResult] = useState<any>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!platform || !fromChainId || !toChainId || !fromToken || !toToken) {
      setResult(undefined)
      return
    }
    ;(async () => {
      setLoading(true)
      switch (platform) {
        case SUPPORT_PLATFORM.POLYNETWORK:
          try {
            const fee = await getPolyNetworkFee(fromChainId, toChainId, fromToken, toToken)
            setResult(fee)
          } catch (error) {}
          break
        case SUPPORT_PLATFORM.BICONOMY:
          setResult(undefined)
          if (!amount) {
            return
          }
          try {
            const fee = await getBiconomyNetworkFee(fromChainId, toChainId, fromToken, amount)
            setResult(fee)
          } catch (error) {}
          break
        default:
          setResult(undefined)
          break
      }
      setLoading(false)
    })()
  }, [fromChainId, fromToken, toChainId, toToken, platform, amount])

  return {
    loading,
    result
  }
}

export function useCbridgeSwapFeeInfoResult(
  platform: SUPPORT_PLATFORM | undefined,
  depositAddress: string | undefined,
  tokenAddress: string | undefined,
  fromChainId: number | undefined,
  toChainId: number | undefined,
  destToken: Token | undefined,
  usrAddr: string | undefined | null,
  slippageTolerance: string | undefined | number, // eg: 0.05%, 0.05% * 1M = 500
  inputAmountRaw: string | undefined
) {
  const [result, setResult] = useState<
    | {
        fees: TokenAmount
        maxSlippage: number
      }
    | undefined
  >()
  const [loading, setLoading] = useState(false)
  const blockNumber = useBlockNumber()
  const contract = useContract(depositAddress, CBRIDGE_ABI)

  const minSend = useSingleCallResult(platform === SUPPORT_PLATFORM.CBRIDGE ? contract : null, 'minSend', [
    tokenAddress
  ])

  useEffect(() => {
    if (
      !platform ||
      platform !== SUPPORT_PLATFORM.CBRIDGE ||
      !fromChainId ||
      !toChainId ||
      !destToken ||
      !slippageTolerance ||
      !usrAddr ||
      !inputAmountRaw
    ) {
      setResult(undefined)
      return
    }
    ;(async () => {
      setLoading(true)
      try {
        const res = (await fetchCbridgeEstimateAmtResult(
          fromChainId,
          toChainId,
          destToken.symbol || '',
          usrAddr,
          slippageTolerance,
          inputAmountRaw
        )) as any
        const ret = {
          fees: new TokenAmount(destToken, res.perc_fee).add(new TokenAmount(destToken, res.base_fee)),
          maxSlippage: res.max_slippage
        }
        setResult(ret)
      } catch (error) {
        setResult(undefined)
      }
      setLoading(false)
    })()
  }, [fromChainId, toChainId, platform, usrAddr, slippageTolerance, destToken, inputAmountRaw, blockNumber])

  return {
    loading: loading || minSend.loading,
    result: {
      ...result,
      minAmount: minSend.result?.toString()
    }
  }
}

export function useBiconomyMinMaxResult(
  platform: SUPPORT_PLATFORM | undefined,
  tokenAddress: string | undefined,
  fromChainId: number | undefined,
  toChainId: number | undefined
) {
  const [result, setResult] = useState<
    | {
        min: string
        max: string
      }
    | undefined
  >()
  const [loading, setLoading] = useState(false)
  const blockNumber = useBlockNumber()

  useEffect(() => {
    if (!platform || platform !== SUPPORT_PLATFORM.BICONOMY || !fromChainId || !toChainId || !tokenAddress) {
      setResult(undefined)
      return
    }
    ;(async () => {
      setLoading(true)
      try {
        const res = (await fetchBiconomyPoolInfo(tokenAddress, fromChainId, toChainId)) as any
        if (res?.code === 200) {
          const ret = {
            min: res.minDepositAmount,
            max: res.maxDepositAmount
          }
          setResult(ret)
        } else {
          setResult(undefined)
        }
      } catch (error) {
        setResult(undefined)
      }
      setLoading(false)
    })()
  }, [fromChainId, toChainId, platform, blockNumber, tokenAddress])

  return {
    loading: loading,
    result
  }
}

export function useStargateMinMaxResult(
  platform: SUPPORT_PLATFORM | undefined,
  depositAddress: string | undefined,
  srcPoolId: number | undefined,
  dstPoolId: number | undefined,
  fromChainId: number | undefined,
  toChainId: number | undefined,
  destToken: Token | undefined,
  slippage: string | undefined | number, // eg: 0.05%, 0.05% * 1M = 500
  inputAmountInt: string | undefined
): {
  minAmount: TokenAmount | undefined
  maxAmount: TokenAmount | undefined
  minReceiveInt: string | undefined
} {
  const { chainId } = useActiveWeb3React()
  const routerContract = useContract(
    chainId === fromChainId && platform === SUPPORT_PLATFORM.STARGATE ? depositAddress : undefined,
    STARGAGE_ABI
  )
  const factoryRes = useSingleCallResult(routerContract, 'factory')

  const factoryContract = useContract(factoryRes.result?.[0], STARGAGE_FACTORY_ABI)
  const poolRes = useSingleCallResult(srcPoolId ? factoryContract : null, 'getPool', [srcPoolId])

  const poolContract = useContract(poolRes.result?.[0], STARGAGE_POOL_ABI)
  const pathRes = useSingleCallResult(dstPoolId ? poolContract : null, 'getChainPath', [
    toChainId ? STARGATE_MAPPING_CHAINID[toChainId] : 1,
    dstPoolId
  ])

  // decimals is 6?
  const amountHander = useMemo(
    () =>
      destToken
        ? new Token(destToken.chainId, destToken.address, 6, destToken.symbol, destToken.name, destToken.logo)
        : undefined,
    [destToken]
  )

  const maxAmount = useMemo(() => {
    const s = pathRes.result?.[0]?.balance?.toString()
    return s && amountHander ? new TokenAmount(amountHander, s) : undefined
  }, [amountHander, pathRes.result])

  return {
    minAmount: amountHander ? new TokenAmount(amountHander, '0') : undefined,
    maxAmount,
    minReceiveInt:
      slippage && inputAmountInt
        ? JSBI.divide(
            JSBI.multiply(JSBI.BigInt(inputAmountInt), JSBI.BigInt(10000 - Number(slippage))),
            JSBI.BigInt(10000)
          ).toString()
        : undefined
  }
}

export function useSymbiosisNetworkFee(
  platform: SUPPORT_PLATFORM | undefined,
  bridgeSymbiosisDirection: BridgeSymbiosisDirection | undefined,
  portalAddress: string | undefined,
  synthesisAddress: string | undefined,
  symbiosisDestPortalAddress: string | undefined,
  symbiosisDestSynthesisAddress: string | undefined,
  fromToken: Token | undefined,
  destToken: Token | undefined,
  inputAmountRaw: string | undefined
) {
  const isMint = useMemo(() => BridgeSymbiosisDirection.MINT === bridgeSymbiosisDirection, [bridgeSymbiosisDirection])
  const oneContract = useContract(
    isMint ? portalAddress : synthesisAddress,
    isMint ? Symbiosis_portal_ABI : Symbiosis_synthesis_ABI
  )
  const twoContract = useContract(
    isMint ? symbiosisDestSynthesisAddress : symbiosisDestPortalAddress,
    isMint ? Symbiosis_synthesis_ABI : Symbiosis_portal_ABI
  )
  const { account, chainId } = useActiveWeb3React()
  const [result, setResult] = useState<TokenAmount>()

  const isNext = useMemo(
    () =>
      !!(
        account &&
        chainId &&
        fromToken &&
        chainId === fromToken.chainId &&
        bridgeSymbiosisDirection !== undefined &&
        destToken &&
        platform === SUPPORT_PLATFORM.SYMBIOSIS
      ),
    [account, chainId, fromToken, bridgeSymbiosisDirection, destToken, platform]
  )

  const { result: requestsCountRes } = useSingleCallResult(!isNext ? null : oneContract, 'requestCount')

  useEffect(() => {
    if (
      !isNext ||
      !fromToken ||
      !requestsCountRes ||
      !account ||
      !destToken ||
      !synthesisAddress ||
      !inputAmountRaw ||
      !symbiosisDestPortalAddress ||
      !symbiosisDestSynthesisAddress ||
      !oneContract ||
      !twoContract
    ) {
      setResult(undefined)
      return
    }
    ;(async () => {
      const internalId = getInternalId({
        contractAddress: oneContract.address,
        requestCount: Number(requestsCountRes[0].toString()),
        chainId: fromToken.chainId
      })

      const externalId = getExternalId({
        internalId,
        contractAddress: twoContract.address,
        revertableAddress: account,
        chainId: destToken.chainId
      })

      const calldata = isMint
        ? twoContract.interface.encodeFunctionData('mintSyntheticToken', [
            '1', // _stableBridgingFee,
            externalId, // externalID,
            fromToken.address, // _token,
            fromToken.chainId, // block.chainid,
            inputAmountRaw, // _amount,
            account // _chain2address
          ])
        : twoContract.interface.encodeFunctionData('unsynthesize', [
            '1', // _stableBridgingFee,
            externalId, // externalID,
            destToken.address, // _token,
            // fromToken.chainId, // block.chainid,
            inputAmountRaw, // _amount,
            account // _chain2address
          ])

      try {
        const fee = (await fetchBridgeFee({
          receiveSide: twoContract.address,
          calldata,
          chainIdFrom: fromToken.chainId,
          chainIdTo: destToken.chainId
        })) as any
        setResult(new TokenAmount(destToken, fee.price.toString()))
      } catch (error) {
        setResult(undefined)
        return
      }
    })()
  }, [
    isNext,
    fromToken,
    requestsCountRes,
    account,
    destToken,
    synthesisAddress,
    inputAmountRaw,
    bridgeSymbiosisDirection,
    symbiosisDestPortalAddress,
    isMint,
    symbiosisDestSynthesisAddress,
    oneContract,
    twoContract
  ])

  return result
}
