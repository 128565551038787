// import { useMemo } from 'react'
import Modal from 'components/Modal'
import { Typography, Box, useTheme, Grid } from '@mui/material'
import { RangoTokenLogo } from 'components/essential/CurrencyLogo'
import LogoText from 'components/LogoText'
import Divider from 'components/Divider'
import Button from 'components/Button/Button'
import { ReactComponent as SwapIcon } from './swapIcon.svg'
import useBreakpoint from 'hooks/useBreakpoint'
import WalletCard from './WalletCard'
import { RangoBsetRoutingReq, RangoToken, Swap } from 'utils/fetch/swap'
import { useBestRouting } from 'hooks/useFetchBaseData'
import { useCallback, useMemo } from 'react'
import { useActiveWeb3React } from 'hooks'
import { useAddRangoTransaction } from '../../../state/rangoTransactions/hooks'
import { Dots } from 'theme/components'

interface Props {
  fromCurrency: RangoToken
  toCurrency: RangoToken
  fromAmount: string
  toAmount: string
  fee: string
  priceImpact: string
  allowedSlippage: string
  onCancel: () => void
  swaps: Swap[]
  routeParams: RangoBsetRoutingReq
  resultId: string | undefined
}

function CurrencyAmount({ currency, amount }: { currency: RangoToken; amount: string }) {
  const theme = useTheme()

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      padding="16px 24px"
      bgcolor={theme.palette.background.paper}
      borderRadius="16px"
    >
      <LogoText logo={<RangoTokenLogo token={currency} />} text={currency.symbol} />
      <Typography fontSize={16} fontWeight={500}>
        {amount}
      </Typography>
    </Box>
  )
}

export default function ConfirmSwapModal(props: Props) {
  const isDownMd = useBreakpoint('md')
  const { account } = useActiveWeb3React()
  const addRangoTransaction = useAddRangoTransaction()

  const {
    fromCurrency,
    fromAmount,
    toCurrency,
    toAmount,
    // fee,
    // priceImpact,
    // allowedSlippage,
    onCancel,
    swaps,
    routeParams,
    resultId
  } = props

  const currentParams = useMemo(() => {
    const _chain: string[] = []
    const _selectedWallets: { [key in string]: string } = {}
    for (const item of swaps) {
      if (!_chain.includes(item.from.blockchain)) _chain.push(item.from.blockchain)
      if (!_chain.includes(item.to.blockchain)) _chain.push(item.to.blockchain)
    }
    for (const item of _chain) {
      if (account) _selectedWallets[item] = account
    }
    return Object.assign(routeParams, {
      checkPrerequisites: true,
      selectedWallets: _selectedWallets,
      previousRequestId: resultId
    })
  }, [account, resultId, routeParams, swaps])

  const { result: bestRouting, loading } = useBestRouting(currentParams)

  const onConfirm = useCallback(() => {
    if (bestRouting) {
      addRangoTransaction(bestRouting)
      onCancel()
    }
  }, [addRangoTransaction, bestRouting, onCancel])

  // const txData = useMemo(() => {
  //   return {
  //     ['Liquidity Provider Fee']: fee + ' ETH',
  //     ['Price Impact']: priceImpact,
  //     ['Allowed Slippage']: allowedSlippage,
  //     ['Minimum Received']: minReveived + ` ${toCurrency.symbol}`
  //   }
  // }, [fee, priceImpact, allowedSlippage, minReveived])

  return (
    <Modal maxWidth="520px" closeIcon>
      <Box padding={isDownMd ? '32px 20px 27px' : '32px 40px 36px'} width="100%">
        <Typography fontSize={24} fontWeight={500} textAlign="center" mb={28}>
          Confirm Swap
        </Typography>
        <Box position="relative" display="grid" gap={16}>
          <CurrencyAmount currency={fromCurrency} amount={fromAmount} />
          <SwapIcon style={{ position: 'absolute', left: 'calc(50% - 16px)', top: 'calc(50% - 16px)' }} />
          <CurrencyAmount currency={toCurrency} amount={toAmount} />
        </Box>

        <Box display="flex" justifyContent="space-between" mt={28} mb={24}>
          <Typography sx={{ opacity: 0.5 }}>Price per {fromCurrency.symbol}</Typography>
          <Typography fontSize={16} fontWeight={500}>
            {fromCurrency.usdPrice}
          </Typography>
        </Box>
        <Divider />

        <Box mb={44}>
          {swaps.map(({ fee, fromAsset }, index) => (
            <>
              <Typography fontWeight={500} mt={24} mb={16}>
                {index}. Your {fromAsset.blockchain} wallet
              </Typography>
              <Grid container spacing={16}>
                {fee.map((feeData, idx) => (
                  <Grid item xs={6} md={3} key={`from-wallets-${idx}`}>
                    <WalletCard {...feeData} />
                  </Grid>
                ))}
              </Grid>
            </>
          ))}
        </Box>

        <Button onClick={onConfirm} primary disabled={loading}>
          {loading ? (
            <>
              Loading
              <Dots />
            </>
          ) : (
            'Confirm'
          )}
        </Button>
        <Typography fontSize={12} fontWeight={500} mt={16} sx={{ opacity: 0.5 }}>
          Output is estimated. You will receive at least {toAmount} {toCurrency.symbol} or the transaction will revert.
        </Typography>
      </Box>
    </Modal>
  )
}
