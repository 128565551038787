import { Box } from '@mui/material'
import Image from 'components/Image'

interface Props {
  size: string | number
  src: string
  alt?: string
  style?: React.CSSProperties
}

export default function BridgeLogo(props: Props) {
  const { size, src, alt, style } = props

  return (
    <Box
      sx={{
        ...style,
        width: size,
        height: size,
        borderRadius: '50%',
        background: '#E1D7F5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Image
        style={{
          maxWidth: '100%'
        }}
        src={src}
        alt={alt}
      />
    </Box>
  )
}
