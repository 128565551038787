import MULTICALL_ABI from './abi.json'
import { ChainId } from '../chain'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  [ChainId.OPTIMISTIC]: '0x9e73d56dd1942743ffdf055449b052a806b854be',
  [ChainId.CRO]: '0xC43E77E8641d41028785779Df0F3D021bD54a1d6',
  [ChainId.TELOS]: '0xdDCbf776dF3dE60163066A5ddDF2277cB445E0F3',
  [ChainId.BSC]: '0xa9193376D09C7f31283C54e56D013fCF370Cd9D9',
  [ChainId.OKEX]: '0xF4d73326C13a4Fc5FD7A064217e12780e9Bd62c3',
  [ChainId.XDAI]: '0x67dA5f2FfaDDfF067AB9d5F025F8810634d84287',
  [ChainId.HECO]: '0xdDCbf776dF3dE60163066A5ddDF2277cB445E0F3',
  [ChainId.MATIC]: '0x02817C1e3543c2d908a590F5dB6bc97f933dB4BD',
  [ChainId.FANTOM]: '0x22D4cF72C45F8198CfbF4B568dBdB5A85e8DC0B5',
  [ChainId.BOBA]: '0xC43E77E8641d41028785779Df0F3D021bD54a1d6',
  [ChainId.KCC]: '0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F',
  [ChainId.SHIDEN]: '0xEba098A16d6092B66608A14A3f53A984186266e7',
  [ChainId.MOONRIVER]: '0x270f2F35bED92B7A59eA5F08F6B3fd34c8D9D9b5',
  [ChainId.IOTEX]: '0xe6801928061cdbe32ac5ad0634427e140efd05f9',
  [ChainId.FUSION]: '0x461d52769884ca6235B685EF2040F47d30C94EB5',
  [ChainId.ARBITRUM]: '0x80C7DD17B01855a6D2347444a0FCC36136a314de',
  [ChainId.AVALANCHE]: '0xdDCbf776dF3dE60163066A5ddDF2277cB445E0F3',
  [ChainId.CELO]: '0x9aac9048fC8139667D6a2597B902865bfdc225d3',
  [ChainId.HARMONY]: '0xdDCbf776dF3dE60163066A5ddDF2277cB445E0F3'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
