import { useState, useCallback, useEffect, useMemo } from 'react'
import { Box, Typography, styled, CircularProgress, useTheme } from '@mui/material'
import InputLabel from 'components/Input/InputLabel'
import SelectButton from 'components/Button/SelectButton'
import LogoText from 'components/LogoText/index'
import ChainNetworkSwap from 'components/Select/ChainSwap/ChainNetworkSwap'
import { RoundOutlineButton } from 'components/Button/OutlineButton'
import InputNumerical from 'components/Input/InputNumerical'
import Input from 'components/Input/index'
import ActionButton from 'components/Button/ActionButton'
import { tryParseAmount, useWalletModalToggle } from 'state/application/hooks'
import Button, { RoundButton } from 'components/Button/Button'
import SelectCurrencyModal from 'components/Input/CurrencyInputPanel/SelectCurrencyModal'
import useModal from 'hooks/useModal'
import Information from './Information'
import { ReactComponent as CheckIcon } from 'assets/componentsIcon/check_icon.svg'
import TransactionSubmittedModal from 'components/Modal/TransactionModals/TransactiontionSubmittedModal'
// import WithdrawConfirmationModal from 'components/Modal/TransactionModals/WithdrawConfirmationModal'
import { useFetchTokenList } from 'hooks/useFetchBaseData'
import { Currency, ETHER, Token, TokenAmount } from 'constants/token'
import { ChainId, ChainList, ChainListMap } from 'constants/chain'
import { useBridgeSearchResult } from 'hooks/useCrossBridge'
import {
  useBiconomyMinMaxResult,
  useCbridgeSwapFeeInfoResult,
  useStargateMinMaxResult,
  useStargateNetworkFee,
  useSwapFeeResult,
  useSymbiosisNetworkFee
} from 'hooks/useFee'
import { Chain } from 'models/chain'
import { CrossChainProp, SUPPORT_PLATFORM } from 'models/platform'
import { useDepositeOnceCallback } from 'hooks/useDepositeOnceCallback'
import { useActiveWeb3React } from 'hooks'
import { useCurrencyBalance } from 'state/wallet/hooks'
import isZero from 'utils/isZero'
import { getChainNativeCurrency } from 'utils/chainInfo'
import { triggerSwitchChain } from 'utils/triggerSwitchChain'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { Dots } from 'theme/components'
import TransactionPendingModal from 'components/Modal/TransactionModals/TransactionPendingModal'
import DepositConfirmationModal from 'components/Modal/TransactionModals/DepositConfirmationModal'
import MessageBox from 'components/Modal/TransactionModals/MessageBox'
import CurrencyLogo from 'components/essential/CurrencyLogo'
import { commitCrossRecord } from 'utils/fetch/swap'
import { useDestTokenSupply } from 'hooks/useFluiditySupply'
import SettingsTab from 'components/essential/Settings'
import { useUserSlippageTolerance } from 'state/user/hooks'
import useBreakpoint from 'hooks/useBreakpoint'
import JSBI from 'jsbi'
import SlippageAlert, { NoticeAlert } from './SlippageAlert'
import BigNumber from 'bignumber.js'

export interface CurrencyProps {
  symbol: string
  logo: string
}

export interface BridgeProps {
  name: string
  logo: string
  info: string
  dailyQuota: number
  fees: number
}

enum BRIDGE_ERROR {
  SELECT_CURRENCY = 'Select Token',
  SELECT_FROM_CHAIN = 'Select from chain',
  SELECT_TO_CHAIN = 'Select to chain',
  SELECT_BRIDGE = 'Select Bridge',
  ENTER_AMOUNT = 'Enter Amount'
}

// const AppBodyWrapper = styled('div')(({ theme }) => ({
// display: 'grid',
// flexWrap: 'wrap',
// gridTemplateColumns: 'minmax(400px, 788px) 380px',
// gap: '24px'
// [theme.breakpoints.down('md')]: {
//   display: 'block'
// }
// }))

const RoundOutlineTagButton = styled('div')({
  padding: '0 15px',
  width: 'auto',
  height: '28px',
  fontSize: '12px',
  borderRadius: '10px',
  backgroundColor: 'rgba(0,0,0,0.1)',
  lineHeight: '26px'
})

export default function BridgeAggregator() {
  // dummy data
  // const minAmount = '3.00'

  const theme = useTheme()
  const isDownMd = useBreakpoint('md')

  const { result: tokenList } = useFetchTokenList()

  const toggleWalletModal = useWalletModalToggle()
  const { showModal, hideModal } = useModal()

  const [currency, setCurrency] = useState<Token | undefined>()
  const [currentBridge, setCurrentBridge] = useState<CrossChainProp>()
  const [amount, setAmount] = useState('')
  const { account, chainId, library } = useActiveWeb3React()
  const [fromChain, setFromChain] = useState<Chain | null>(null)
  const [toChain, setToChain] = useState<Chain | null>(null)
  // const [deposited, setDeposited] = useState(false)
  // const [depositing, setDepositing] = useState(false)
  const [approvingToken, setApprovingToken] = useState<
    | {
        chainId: ChainId | undefined
        token: Currency | undefined
        account: string | null | undefined
      }
    | undefined
  >()
  const userSlippage = useUserSlippageTolerance()

  useEffect(() => {
    setCurrentBridge(undefined)
  }, [fromChain, toChain, currency])

  const { loading: bridgeResultLoading, result: bridgeSearchResult } = useBridgeSearchResult(
    currency?.symbol ?? '',
    fromChain?.id ?? 0,
    toChain?.id ?? 0
  )

  const walletIsCurrentChain = useMemo(() => chainId === fromChain?.id, [chainId, fromChain?.id])

  const isETHER = useMemo(() => isZero(currentBridge?.srcToken.address ?? ''), [currentBridge?.srcToken.address])
  const fromBalance = useCurrencyBalance(
    walletIsCurrentChain && account ? account : undefined,
    isETHER ? ETHER : currentBridge?.srcToken
  )
  const ethBalance = useCurrencyBalance(account || undefined, ETHER)
  const { result: anyTokenSupply } = useDestTokenSupply(
    currentBridge?.platform,
    currentBridge?.destToken,
    currentBridge?.moreData.destUnderlying,
    currentBridge?.destToken.chainId
  )

  const inputAmount = useMemo(() => tryParseAmount(amount, currentBridge?.srcToken), [amount, currentBridge?.srcToken])

  const { result: feeInfo } = useSwapFeeResult(
    currentBridge?.platform,
    fromChain?.id ?? undefined,
    toChain?.id ?? undefined,
    currentBridge?.srcToken.address ?? undefined,
    currentBridge?.destToken.address ?? undefined,
    inputAmount?.raw.toString()
  )

  const depositeOnceCallback = useDepositeOnceCallback(currentBridge?.platform, currentBridge?.depositeContract)

  const onSelectBridge = useCallback((bridge: CrossChainProp) => {
    setCurrentBridge(bridge)
  }, [])

  const switchCurrentChain = useCallback(() => {
    if (!library || !account || !fromChain?.id) return
    triggerSwitchChain(library, fromChain.id, account)
  }, [account, fromChain?.id, library])

  const onMax = useCallback(() => {
    setAmount(fromBalance?.toSignificant() ?? '0')
  }, [fromBalance])

  const polyBridgeFeeAmount = useMemo(
    () => tryParseAmount(feeInfo?.fee.toString(), getChainNativeCurrency(currentBridge?.srcToken.chainId || 1)),
    [currentBridge?.srcToken, feeInfo?.fee]
  )

  const biconomyBridgeFeeAmount = useMemo(() => tryParseAmount(feeInfo?.fee.toString(), currentBridge?.srcToken), [
    currentBridge?.srcToken,
    feeInfo?.fee
  ])

  const { loading: cbridgeFeeInfoLoading, result: cbridgeFeeInfo } = useCbridgeSwapFeeInfoResult(
    currentBridge?.platform,
    currentBridge?.depositeContract,
    currentBridge?.srcToken.address,
    fromChain?.id ?? undefined,
    toChain?.id ?? undefined,
    currentBridge?.destToken,
    account,
    userSlippage[0] * 100,
    inputAmount?.raw.toString()
  )
  const stargateFeeAmount = useStargateNetworkFee(
    currentBridge?.platform,
    currentBridge?.depositeContract,
    toChain?.id ?? undefined,
    account
  )

  const symbiosisFeeAmount = useSymbiosisNetworkFee(
    currentBridge?.platform,
    currentBridge?.moreData?.bridgeSymbiosisDirection,
    currentBridge?.moreData.symbiosisSrcPortalAddress,
    currentBridge?.moreData.symbiosisSrcSynthesisAddress,
    currentBridge?.moreData.symbiosisDestPortalAddress,
    currentBridge?.moreData.symbiosisDestSynthesisAddress,
    currentBridge?.srcToken,
    currentBridge?.destToken,
    inputAmount?.raw.toString()
  )

  const stargateMinMaxAmount = useStargateMinMaxResult(
    currentBridge?.platform,
    currentBridge?.depositeContract,
    currentBridge?.moreData.srcPoolId,
    currentBridge?.moreData.dstPoolId,
    fromChain?.id ?? undefined,
    toChain?.id ?? undefined,
    currentBridge?.destToken,
    userSlippage[0],
    inputAmount?.raw.toString()
  )

  const { result: biconomyAmountLimit } = useBiconomyMinMaxResult(
    currentBridge?.platform,
    currentBridge?.srcToken.address,
    fromChain?.id ?? undefined,
    toChain?.id ?? undefined
  )
  const biconomyAmountLimitAmount = useMemo(() => {
    if (!biconomyAmountLimit || !currentBridge?.srcToken) {
      return {
        minAmount: undefined,
        maxAmount: undefined
      }
    }
    return {
      minAmount: tryParseAmount(biconomyAmountLimit.min, currentBridge.srcToken),
      maxAmount: tryParseAmount(biconomyAmountLimit.max, currentBridge.srcToken)
    }
  }, [biconomyAmountLimit, currentBridge?.srcToken])

  const feesInfo = useMemo(() => {
    const defaultRet = {
      currentFeeAmount: undefined,
      currentFeeSymbol: ''
    }
    switch (currentBridge?.platform) {
      case SUPPORT_PLATFORM.POLYNETWORK:
        return polyBridgeFeeAmount
          ? {
              currentFeeAmount: polyBridgeFeeAmount,
              currentFeeSymbol: getChainNativeCurrency(currentBridge.srcToken.chainId || 1)?.symbol
            }
          : defaultRet
      case SUPPORT_PLATFORM.BICONOMY:
        return biconomyBridgeFeeAmount
          ? {
              currentFeeAmount: biconomyBridgeFeeAmount,
              currentFeeSymbol: currentBridge?.srcToken.symbol
            }
          : defaultRet
      case SUPPORT_PLATFORM.MULTICHAIN:
      case SUPPORT_PLATFORM.ANYSWAP:
        const _fee = currentBridge.moreData
        if (_fee.feeRate !== undefined && _fee.minFee !== undefined && _fee.maxFee !== undefined) {
          let _currentFee = Number(amount) * _fee.feeRate
          if (_currentFee > _fee.maxFee) _currentFee = _fee.maxFee
          if (_currentFee < _fee.minFee) _currentFee = _fee.minFee
          return {
            currentFeeAmount: tryParseAmount(_currentFee.toString(), currentBridge?.srcToken),
            currentFeeSymbol: currentBridge?.srcToken.symbol
          }
        }
        return defaultRet
      case SUPPORT_PLATFORM.CBRIDGE:
        if (cbridgeFeeInfo && cbridgeFeeInfo.fees)
          return {
            currentFeeAmount: cbridgeFeeInfo.fees,
            currentFeeSymbol: cbridgeFeeInfo.fees.token.symbol
          }
        return defaultRet
      case SUPPORT_PLATFORM.SYMBIOSIS:
        if (symbiosisFeeAmount)
          return {
            currentFeeAmount: symbiosisFeeAmount,
            currentFeeSymbol: symbiosisFeeAmount.token.symbol
          }
        return defaultRet
      case SUPPORT_PLATFORM.STARGATE:
        return stargateFeeAmount
          ? {
              currentFeeAmount: stargateFeeAmount,
              currentFeeSymbol: getChainNativeCurrency(currentBridge.srcToken.chainId || 1)?.symbol
            }
          : defaultRet
      default:
        return defaultRet
    }
  }, [
    amount,
    cbridgeFeeInfo,
    currentBridge,
    symbiosisFeeAmount,
    biconomyBridgeFeeAmount,
    polyBridgeFeeAmount,
    stargateFeeAmount
  ])

  const onDeposit = useCallback(() => {
    if (!depositeOnceCallback || !currentBridge || !toChain || !account || !inputAmount || !fromChain) return
    if (currentBridge.platform === SUPPORT_PLATFORM.POLYNETWORK) {
      if (!feeInfo || !polyBridgeFeeAmount) {
        return
      }
    }
    if (currentBridge.platform === SUPPORT_PLATFORM.ANYSWAP) {
      if (!currentBridge.moreData.underlying) {
        return
      }
    }
    if (currentBridge.platform === SUPPORT_PLATFORM.CBRIDGE) {
      if (!cbridgeFeeInfo?.maxSlippage) {
        return
      }
    }

    showModal(<TransactionPendingModal />)
    depositeOnceCallback(
      currentBridge.srcToken.address,
      toChain.id,
      account,
      inputAmount,
      isETHER,
      fromChain.id,
      currentBridge.srcToken.symbol || '',
      {
        feeInt:
          currentBridge.platform === SUPPORT_PLATFORM.POLYNETWORK
            ? polyBridgeFeeAmount?.raw.toString()
            : currentBridge.platform === SUPPORT_PLATFORM.STARGATE
            ? stargateFeeAmount?.raw.toString()
            : currentBridge.platform === SUPPORT_PLATFORM.SYMBIOSIS
            ? symbiosisFeeAmount?.raw.toString()
            : undefined,
        direction: currentBridge.moreData.direction,
        pairID: currentBridge.moreData?.pairID,
        underlying: currentBridge.moreData.underlying,
        maxSlippage: cbridgeFeeInfo?.maxSlippage,
        srcPoolId: currentBridge.moreData.srcPoolId,
        dstPoolId: currentBridge.moreData.dstPoolId,
        minReceive: stargateMinMaxAmount.minReceiveInt,
        bridgeSymbiosisDirection: currentBridge.moreData.bridgeSymbiosisDirection,
        symbiosisSrcPortalAddress: currentBridge.moreData.symbiosisSrcPortalAddress,
        symbiosisSrcBridgeAddress: currentBridge.moreData.symbiosisSrcBridgeAddress,
        symbiosisSrcSynthesisAddress: currentBridge.moreData.symbiosisSrcSynthesisAddress,
        symbiosisDestPortalAddress: currentBridge.moreData.symbiosisDestPortalAddress,
        symbiosisDestBridgeAddress: currentBridge.moreData.symbiosisDestBridgeAddress,
        symbiosisDestSynthesisAddress: currentBridge.moreData.symbiosisDestSynthesisAddress
      }
    )
      .then(hash => {
        //send record
        commitCrossRecord({
          bridge: currentBridge.platform,
          fromChain: currentBridge.srcToken.chainId,
          toChain: currentBridge.destToken.chainId,
          fromAccount: account,
          toAccount: account,
          fromTXHash: typeof hash === 'string' ? hash : hash.hash,
          pairID: currentBridge.moreData?.pairID || '',
          srcToken: currentBridge.srcToken.address,
          destToken: currentBridge.destToken.address,
          amount: inputAmount.toSignificant(),
          amountInt: inputAmount.raw.toString(),
          symbol: currentBridge.srcToken.symbol || '',
          fee: feesInfo.currentFeeAmount?.toSignificant() || '0',
          feeInt: feesInfo.currentFeeAmount?.raw.toString() || '0',
          feeSymbol: feesInfo.currentFeeSymbol || '',
          depositAddress: typeof hash === 'object' ? hash.depositAddress || '' : '',
          cbridgeTransferId: typeof hash === 'object' ? hash.transferId || '' : ''
        })

        hideModal()
        showModal(<TransactionSubmittedModal />)
      })
      .catch(err => {
        hideModal()
        showModal(
          <MessageBox type="error">{err.data && err.data.message ? err.data.message : err?.message}</MessageBox>
        )
        console.error(err)
      })
  }, [
    depositeOnceCallback,
    symbiosisFeeAmount,
    currentBridge,
    toChain,
    account,
    inputAmount,
    fromChain,
    showModal,
    isETHER,
    polyBridgeFeeAmount,
    stargateFeeAmount?.raw,
    cbridgeFeeInfo?.maxSlippage,
    stargateMinMaxAmount.minReceiveInt,
    feeInfo,
    feesInfo.currentFeeAmount,
    feesInfo.currentFeeSymbol,
    hideModal
  ])

  const showFeesStr = useMemo(() => {
    switch (currentBridge?.platform) {
      case SUPPORT_PLATFORM.POLYNETWORK:
      case SUPPORT_PLATFORM.STARGATE:
        return feesInfo.currentFeeAmount
          ? feesInfo.currentFeeAmount.toSignificant() + ' ' + feesInfo.currentFeeSymbol
          : '--'
      case SUPPORT_PLATFORM.BICONOMY:
      case SUPPORT_PLATFORM.SYMBIOSIS:
        return feesInfo.currentFeeAmount
          ? feesInfo.currentFeeAmount.toSignificant() + ' ' + feesInfo.currentFeeSymbol
          : '--'
      case SUPPORT_PLATFORM.MULTICHAIN:
      case SUPPORT_PLATFORM.ANYSWAP:
      case SUPPORT_PLATFORM.CBRIDGE:
        return feesInfo.currentFeeAmount
          ? feesInfo.currentFeeAmount.toSignificant() + ' ' + feesInfo.currentFeeSymbol
          : '0 ' + feesInfo.currentFeeSymbol
      default:
        return '--'
    }
  }, [currentBridge?.platform, feesInfo.currentFeeAmount, feesInfo.currentFeeSymbol])

  const willReceiveAmountStr = useMemo(() => {
    switch (currentBridge?.platform) {
      case SUPPORT_PLATFORM.POLYNETWORK:
        return inputAmount?.toSignificant() || '--'
      case SUPPORT_PLATFORM.BICONOMY:
        return biconomyBridgeFeeAmount && inputAmount && inputAmount.greaterThan(biconomyBridgeFeeAmount)
          ? inputAmount.subtract(biconomyBridgeFeeAmount).toSignificant()
          : '--'
      case SUPPORT_PLATFORM.SYMBIOSIS:
        if (inputAmount) {
          if (feesInfo.currentFeeAmount) {
            const _ca = tryParseAmount(feesInfo.currentFeeAmount.toSignificant(), inputAmount.currency)
            if (!_ca) return '--'
            if (_ca.greaterThan(inputAmount)) {
              return '0'
            }
            return inputAmount.subtract(_ca).toSignificant()
          }
        }
        return '--'
      case SUPPORT_PLATFORM.MULTICHAIN:
      case SUPPORT_PLATFORM.ANYSWAP:
        if (inputAmount) {
          if (feesInfo.currentFeeAmount) {
            if (feesInfo.currentFeeAmount.greaterThan(inputAmount)) {
              return '0'
            }
            return inputAmount.subtract(feesInfo.currentFeeAmount).toSignificant()
          }
          return inputAmount.toSignificant()
        }
        return '--'
      case SUPPORT_PLATFORM.CBRIDGE:
        if (inputAmount) {
          if (cbridgeFeeInfo && feesInfo.currentFeeAmount && cbridgeFeeInfo.maxSlippage) {
            const _curFee = Number(feesInfo.currentFeeAmount.toSignificant())
            const _curinput = Number(inputAmount.toSignificant())
            if (_curFee > _curinput) {
              return '0'
            }
            return 'minimum ' + (((1000000 - cbridgeFeeInfo.maxSlippage) / 1000000) * _curinput).toFixed(4).toString()
          }
          return inputAmount.toSignificant()
        } else {
          return '--'
        }
      case SUPPORT_PLATFORM.STARGATE:
        return (
          inputAmount
            ?.multiply(JSBI.BigInt(999))
            .divide(JSBI.BigInt(1000))
            .toSignificant(6) || '--'
        )
      default:
        return '--'
    }
  }, [cbridgeFeeInfo, currentBridge?.platform, biconomyBridgeFeeAmount, feesInfo.currentFeeAmount, inputAmount])

  const onDepositConfirmModal = useCallback(() => {
    hideModal()
    showModal(
      <DepositConfirmationModal
        destinationAddress={account || ''}
        title="Confirm Cross"
        onConfirm={onDeposit}
        fees={showFeesStr}
        platform={currentBridge?.platform}
        toChain={toChain}
        fromChain={fromChain}
        willReceiveAmountStr={<>{willReceiveAmountStr + currentBridge?.destToken.symbol}</>}
      ></DepositConfirmationModal>
    )
  }, [
    account,
    currentBridge?.destToken.symbol,
    currentBridge?.platform,
    fromChain,
    hideModal,
    onDeposit,
    showFeesStr,
    showModal,
    toChain,
    willReceiveAmountStr
  ])

  const [approvalState, approvalCallback] = useApproveCallback(
    isETHER ? fromBalance : tryParseAmount(amount, fromBalance?.currency),
    currentBridge?.depositeContract
  )

  const approvalCallbackFunc = useCallback(() => {
    setApprovingToken({ chainId: chainId, token: fromBalance?.currency, account })
    approvalCallback()
  }, [account, approvalCallback, chainId, fromBalance?.currency])

  const prevApprovedToken = useMemo(() => {
    return (
      approvingToken &&
      approvalState === ApprovalState.APPROVED &&
      fromBalance?.currency === approvingToken.token &&
      approvingToken.chainId === chainId &&
      approvingToken.account === account
    )
  }, [account, approvalState, approvingToken, chainId, fromBalance?.currency])

  const maxAmount = useMemo(() => {
    switch (currentBridge?.platform) {
      case SUPPORT_PLATFORM.BICONOMY:
        if (biconomyAmountLimitAmount.maxAmount !== undefined) {
          return biconomyAmountLimitAmount.maxAmount
        }
        return undefined
      case SUPPORT_PLATFORM.STARGATE:
        return stargateMinMaxAmount.maxAmount
      case SUPPORT_PLATFORM.SYMBIOSIS:
        return currentBridge ? tryParseAmount('5000000', currentBridge.srcToken) : undefined

      case SUPPORT_PLATFORM.POLYNETWORK:
        return currentBridge && feeInfo?.polyFluidity
          ? tryParseAmount(feeInfo.polyFluidity, currentBridge.srcToken) ||
              new TokenAmount(currentBridge.srcToken, JSBI.BigInt(0))
          : undefined

      default:
        return currentBridge ? new TokenAmount(currentBridge.srcToken, currentBridge.maxAmount) : undefined
    }
  }, [biconomyAmountLimitAmount.maxAmount, currentBridge, feeInfo?.polyFluidity, stargateMinMaxAmount.maxAmount])

  const minAmount = useMemo(() => {
    switch (currentBridge?.platform) {
      case SUPPORT_PLATFORM.CBRIDGE:
        if (cbridgeFeeInfo.minAmount !== undefined) {
          return new TokenAmount(currentBridge.srcToken, cbridgeFeeInfo.minAmount)
        }
        return undefined
      case SUPPORT_PLATFORM.BICONOMY:
        if (biconomyAmountLimitAmount.minAmount !== undefined) {
          return biconomyAmountLimitAmount.minAmount
        }
        return undefined

      case SUPPORT_PLATFORM.MULTICHAIN:
        if (isETHER) {
          return currentBridge
            ? new TokenAmount(
                currentBridge.srcToken,
                JSBI.multiply(JSBI.BigInt(currentBridge.minAmount), JSBI.BigInt(2))
              )
            : undefined
        }
        return currentBridge ? new TokenAmount(currentBridge.srcToken, currentBridge.minAmount) : undefined
      case SUPPORT_PLATFORM.STARGATE:
        return stargateMinMaxAmount.minAmount
      case SUPPORT_PLATFORM.SYMBIOSIS:
        return currentBridge ? tryParseAmount('20', currentBridge.srcToken) : undefined
      default:
        return currentBridge ? new TokenAmount(currentBridge.srcToken, currentBridge.minAmount) : undefined
    }
  }, [
    biconomyAmountLimitAmount.minAmount,
    cbridgeFeeInfo.minAmount,
    currentBridge,
    isETHER,
    stargateMinMaxAmount.minAmount
  ])

  const getActions = useCallback(() => {
    if (!account) {
      return (
        <Button onClick={toggleWalletModal} primary>
          Connect Wallet
        </Button>
      )
    }

    if (!currency || !fromChain || !toChain || !currentBridge) {
      return (
        <Button disabled>
          {!currency
            ? BRIDGE_ERROR.SELECT_CURRENCY
            : !fromChain
            ? BRIDGE_ERROR.SELECT_FROM_CHAIN
            : !toChain
            ? BRIDGE_ERROR.SELECT_TO_CHAIN
            : BRIDGE_ERROR.SELECT_BRIDGE}
        </Button>
      )
    }

    if (!walletIsCurrentChain) {
      return (
        <Button primary onClick={switchCurrentChain}>
          Switch to {ChainListMap[fromChain.id].name}
        </Button>
      )
    }

    if (!fromBalance) {
      return (
        <Button disabled>
          Loading balance
          <Dots />
        </Button>
      )
    }

    if (!inputAmount) return <Button disabled>{BRIDGE_ERROR.ENTER_AMOUNT}</Button>

    if (currentBridge.platform === SUPPORT_PLATFORM.POLYNETWORK) {
      if (!feeInfo?.polyFluidity || new BigNumber(inputAmount.toSignificant()).gt(feeInfo.polyFluidity)) {
        return <Button disabled>Insufficient liquidity</Button>
      }
    }

    if (!maxAmount || !minAmount) {
      return (
        <Button disabled>
          Loading
          <Dots />
        </Button>
      )
    }

    if (maxAmount.greaterThan('0') && inputAmount.greaterThan(maxAmount)) {
      return <Button disabled>Maximum amount is {maxAmount.toSignificant(10)}</Button>
    }
    if (currentBridge.platform === SUPPORT_PLATFORM.CBRIDGE && !inputAmount.greaterThan(minAmount)) {
      return <Button disabled>Minimum amount more than the {minAmount.toSignificant(10)}</Button>
    }
    if (inputAmount.lessThan(minAmount)) {
      return <Button disabled>Minimum amount is {minAmount.toSignificant(10)}</Button>
    }

    if (!fromBalance || fromBalance?.lessThan(inputAmount)) {
      return <Button disabled>Balance insufficient</Button>
    }
    if (SUPPORT_PLATFORM.SYMBIOSIS === currentBridge.platform && !symbiosisFeeAmount) {
      return (
        <Button disabled>
          Loading fee info
          <Dots />
        </Button>
      )
    }

    if ([SUPPORT_PLATFORM.STARGATE, SUPPORT_PLATFORM.POLYNETWORK].includes(currentBridge.platform)) {
      if (!feesInfo.currentFeeAmount) {
        return (
          <Button disabled>
            Loading fee info
            <Dots />
          </Button>
        )
      }
      if (ethBalance && ethBalance?.lessThan(feesInfo.currentFeeAmount)) {
        return <Button disabled>fees insufficient</Button>
      }
    }
    if (currentBridge.platform === SUPPORT_PLATFORM.ANYSWAP) {
      if (!anyTokenSupply || anyTokenSupply.lessThan(inputAmount)) {
        return <Button disabled>Fluidity supply insufficient</Button>
      }
    }
    if (currentBridge.platform === SUPPORT_PLATFORM.CBRIDGE) {
      if (!cbridgeFeeInfo || !cbridgeFeeInfo?.fees || cbridgeFeeInfoLoading)
        return (
          <Button disabled>
            Loading fee info
            <Dots />
          </Button>
        )
      const _cminInput = cbridgeFeeInfo.fees.toSignificant()
      if (Number(_cminInput) > Number(inputAmount.toSignificant())) {
        return <Button disabled>Minimum amount is {_cminInput}</Button>
      }
    }

    if (approvalState !== ApprovalState.APPROVED) {
      if (approvalState === ApprovalState.PENDING) {
        return (
          <Button disabled>
            Allow the {currentBridge.platform} protocol to use your {fromBalance?.currency.symbol}
            <Dots />
          </Button>
        )
      } else if (approvalState === ApprovalState.NOT_APPROVED) {
        return (
          <Button onClick={approvalCallbackFunc} primary>
            Allow the {currentBridge.platform} protocol to use your {fromBalance?.currency.symbol}
          </Button>
        )
      } else {
        return (
          <Button disabled>
            Loading
            <Dots />
          </Button>
        )
      }
    }

    return (
      <ActionButton
        onAction={onDepositConfirmModal}
        actionIcon={<Box mr={12}></Box>}
        actionText={`Cross Chain`}
        // pending={depositing}
        pendingText={'Depositing'}
        // success={deposited}
        disableAction={false}
      />
    )

    // return (
    //   <Box display="flex" justifyContent="space-between" gap={20} width="100%">
    //     <ActionButton
    //       onAction={onDeposit}
    //       actionIcon={<Box mr={12}>①</Box>}
    //       actionText={`Deposit in ${fromChain?.symbol} Chain`}
    //       pending={depositing}
    //       pendingText={'Depositing'}
    //       success={deposited}
    //       disableAction={false}
    //     />
    //     <ActionButton
    //       onAction={onWithdraw}
    //       actionIcon={<Box mr={12}>②</Box>}
    //       actionText={`Withdrawl from ${toChain?.symbol} Chain`}
    //       pending={withdrawing}
    //       pendingText={'Withdrawing'}
    //       disableAction={!deposited || withdrawed}
    //     />
    //   </Box>
    // )
  }, [
    account,
    currency,
    fromChain,
    toChain,
    currentBridge,
    walletIsCurrentChain,
    fromBalance,
    inputAmount,
    maxAmount,
    minAmount,
    symbiosisFeeAmount,
    approvalState,
    onDepositConfirmModal,
    toggleWalletModal,
    switchCurrentChain,
    feeInfo?.polyFluidity,
    feesInfo.currentFeeAmount,
    ethBalance,
    anyTokenSupply,
    cbridgeFeeInfo,
    cbridgeFeeInfoLoading,
    approvalCallbackFunc
  ])

  const showMinAmountStr = useMemo(() => {
    return minAmount
      ? 'MIN: ' + minAmount.toSignificant(6, { groupSeparator: ',' }) + ' ' + minAmount.currency.symbol
      : ''
  }, [minAmount])
  const showMaxAmountStr = useMemo(() => {
    switch (currentBridge?.platform) {
      case SUPPORT_PLATFORM.CBRIDGE:
        return 'MAX: Maximum liquidity'
      default:
        return maxAmount
          ? 'MAX: ' + maxAmount.toSignificant(6, { groupSeparator: ',' }) + ' ' + maxAmount.currency.symbol
          : ''
    }
  }, [currentBridge?.platform, maxAmount])

  const minReceiveAmount = useMemo(() => {
    switch (currentBridge?.platform) {
      case SUPPORT_PLATFORM.STARGATE:
      case SUPPORT_PLATFORM.CBRIDGE:
        const res = inputAmount
          ?.multiply(JSBI.BigInt(10000 - userSlippage[0]))
          .divide(JSBI.BigInt(10000))
          .toSignificant(6)

        return res ? `(min receive ${res})` : null

      default:
        return null
    }
  }, [currentBridge?.platform, inputAmount, userSlippage])

  const showAnyTokenSupply = useMemo(() => {
    switch (currentBridge?.platform) {
      case SUPPORT_PLATFORM.ANYSWAP:
        if (!anyTokenSupply) return <></>
        return (
          <RoundOutlineTagButton>
            {toChain?.name} Pool: {anyTokenSupply.toSignificant(6, { groupSeparator: ',' })}{' '}
            {currentBridge?.destToken.symbol}
          </RoundOutlineTagButton>
        )

      default:
        return <></>
    }
  }, [anyTokenSupply, currentBridge?.destToken.symbol, currentBridge?.platform, toChain?.name])

  const onSwitch = useCallback(() => {
    if (!fromChain || !toChain) {
      return
    }

    setFromChain(toChain)
    setToChain(fromChain)
  }, [toChain, fromChain])

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 1192,
        padding: {
          xs: '0 16px'
        },
        display: 'flex',
        gap: isDownMd ? 20 : 24,
        flexDirection: isDownMd ? 'column' : 'row'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: isDownMd ? '32px 20px 40px' : '20px 40px 52px',
          gap: 28,
          width: isDownMd ? '100%' : 788,
          borderRadius: '20px',
          background: theme.bgColor.bg6
        }}
      >
        <Typography variant="h5">Cross Chain Bridge</Typography>
        <Box width="100%">
          <InputLabel>Asset</InputLabel>
          <SelectButton
            onClick={() => {
              tokenList.length &&
                showModal(
                  <SelectCurrencyModal
                    onSelectCurrency={currency => setCurrency(currency as Token)}
                    tokenList={tokenList}
                  />
                )
            }}
          >
            {currency ? (
              <LogoText logo={<CurrencyLogo currency={currency} />} text={currency?.symbol ?? ''} />
            ) : (
              <Typography fontSize="16px" color={'rgba(22, 22, 22, 0.5)'}>
                Select Asset
              </Typography>
            )}
          </SelectButton>
        </Box>

        <ChainNetworkSwap
          chainList={ChainList}
          fromChain={fromChain}
          toChain={toChain}
          onSelectFrom={setFromChain}
          onSelectTo={setToChain}
          onSwitch={onSwitch}
        />

        <Box>
          <Box display="flex" justifyContent="space-between">
            <InputLabel>Select Bridge</InputLabel>
            {(currentBridge?.platform === SUPPORT_PLATFORM.CBRIDGE ||
              currentBridge?.platform === SUPPORT_PLATFORM.STARGATE) && <SettingsTab onlySlippage />}
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              overflowX: 'auto',
              flexWrap: isDownMd ? 'inherit' : 'wrap',
              '&::-webkit-scrollbar': {
                display: 'none'
              }
            }}
          >
            {bridgeResultLoading ? (
              <Box display="flex" width="100%" height="60px" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : bridgeSearchResult.length ? (
              bridgeSearchResult.map((item, idx) => (
                <Box key={idx} sx={{ position: 'relative' }}>
                  {currentBridge && item.platform === currentBridge.platform ? (
                    <RoundButton width="152px" height="60px" fontSize="16px" primary>
                      {item.platform}
                    </RoundButton>
                  ) : (
                    <RoundOutlineButton
                      onClick={() => onSelectBridge(item)}
                      width="152px"
                      height="60px"
                      borderRadius="57px"
                      fontSize="16px"
                      fontWeight="500"
                    >
                      {item.platform}
                    </RoundOutlineButton>
                  )}
                </Box>
              ))
            ) : fromChain && toChain && currency ? (
              <Box height="40px" display="flex" width="100%" alignItems="center" justifyContent="center">
                <Typography variant="body1" color="rgba(22, 22, 22, 0.5)">
                  No data yet
                </Typography>
              </Box>
            ) : (
              <Box height="40px" display="flex" width="100%" alignItems="center" justifyContent="center">
                <Typography variant="body1" color="rgba(22, 22, 22, 0.5)">
                  Please select asset and chain
                </Typography>
              </Box>
            )}
          </Box>
          {currentBridge && (
            <Box sx={{ mt: 12, flexWrap: 'wrap' }} display="flex" gap="10px">
              <RoundOutlineTagButton>Fees: {showFeesStr}</RoundOutlineTagButton>
              <RoundOutlineTagButton>
                {minAmount && maxAmount ? showMinAmountStr + ' ' + showMaxAmountStr : 'MIN: --  MAX: --'}
              </RoundOutlineTagButton>
              {showAnyTokenSupply}
            </Box>
          )}
          <SlippageAlert platform={currentBridge?.platform} />
          <NoticeAlert platform={currentBridge?.platform} />
        </Box>

        <InputNumerical
          label="Amount"
          placeholder="Enter amount"
          value={amount}
          onChange={e => {
            if (e.target.value.length < 50) setAmount(e.target.value)
          }}
          onMax={onMax}
          balance={fromBalance ? fromBalance?.toSignificant() : '--'}
          unit={currentBridge?.srcToken.symbol ?? ''}
        />
        {currentBridge && (
          <Box display="flex" mt="-20px" alignItems="center" gap={10} flexWrap="wrap">
            {/* {parseInt(amount, 10) < parseInt(minAmount, 10) && (
              <Typography color={theme.textColor.text4.palette.error.main}>Minimum amount is {minAmount}</Typography>
            )} */}

            <Box display="flex" alignItems="center" flexWrap="wrap">
              <Typography mr={5} sx={{ opacity: 0.6 }}>
                You will expect to receive {willReceiveAmountStr} {minReceiveAmount} {currentBridge?.destToken.symbol}
                <CurrencyLogo size="12px" currency={currentBridge?.destToken} />
              </Typography>
            </Box>
            <RoundButton
              width="auto"
              height="21px"
              fontSize="10px"
              fontWeight={400}
              style={{ padding: '0 10px' }}
              backgroundColor="rgba(22, 22, 22, 0.1)"
            >
              {toChain?.name}
            </RoundButton>
          </Box>
        )}

        {account && currentBridge && amount && <Input label="Destination" value={account || ''} disabled />}
        {prevApprovedToken && (
          <Box
            width="100%"
            display="flex"
            padding="14px 24px"
            borderRadius="20px"
            marginTop="28px"
            gap={12}
            sx={{ background: 'rgba(58, 194, 97, 0.1)' }}
          >
            <CheckIcon />
            <Box>
              <Typography fontWeight={500} fontSize={12}>
                Now you can swap {approvingToken?.token?.symbol}
              </Typography>
              <Typography mt={4} fontSize={12}>
                You swap address will be your receiving address. Please switch the network to check your balance after
                completition
              </Typography>
            </Box>
          </Box>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>{getActions()}</Box>
      </Box>

      <Information
        bridge={currentBridge}
        fees={showFeesStr}
        showMinAmountStr={showMinAmountStr}
        showMaxAmountStr={showMaxAmountStr}
      />
    </Box>
  )
}
