import { styled } from '@mui/material'
import Image from '../Image'
import ErrorImg from 'assets/images/error.png'

const Wrapper = styled('div')({})

export default function LogoText({
  logo,
  text,
  fontWeight,
  fontSize,
  gapSize,
  size,
  color
}: {
  logo: string | JSX.Element
  text?: string | React.ReactNode
  fontWeight?: number
  fontSize?: number
  gapSize?: string | number
  size?: string
  color?: string
}) {
  return (
    <Wrapper
      sx={{
        display: 'flex',
        alignItems: 'center',
        fontWeight: fontWeight ?? 400,
        fontSize: fontSize ?? 16,
        '& > img, > svg': {
          marginRight: gapSize ?? '12px',
          height: size ? size : '20px',
          width: size ? size : '20px'
        },
        '& > span': {
          color: theme => color ?? theme.palette.primary.main
        }
      }}
    >
      {typeof logo === 'string' ? <Image src={logo as string} altSrc={ErrorImg} /> : logo}
      <span>{text}</span>
    </Wrapper>
  )
}
